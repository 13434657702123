import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { PriceDetails } from "../../models/price-details";
import { ObservableService } from "../../../core/services/observable.service";
import { Observable } from "rxjs";
import { AnalyticsService } from "src/app/core/services/analytics.service";

@Component({
  selector: "app-price-calculation",
  templateUrl: "./price-calculation.component.html",
  styleUrls: ["./price-calculation.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PriceCalculationComponent implements OnInit, OnChanges {
  @Input() public priceDetails: {
    toPayNow: PriceDetails;
    nextPayment: PriceDetails;
    regularMonthly: PriceDetails;
  };

  public currentlang$: Observable<string>;
  priceDetailsArray: { id: number; title: string; price: PriceDetails }[] = [
    { id: 1, title: "BOOK.FIRST_PAYMENT", price: null },
    { id: 2, title: "BOOK.NEXT_MONTH", price: null },
    { id: 3, title: "BOOK.REGULAR_MONTH", price: null },
  ];

  constructor(
    private observableService: ObservableService,
    public analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.currentlang$ = this.observableService.currentLanguage$;
  }

  ngOnChanges({ priceDetails }: SimpleChanges): void {
    if (
      priceDetails &&
      priceDetails.currentValue &&
      priceDetails.currentValue !== priceDetails.previousValue
    ) {
      this.priceDetailsArray[0].price = priceDetails.currentValue.toPayNow;
      this.priceDetailsArray[1].price = priceDetails.currentValue.nextPayment;
      this.priceDetailsArray[2].price =
        priceDetails.currentValue.regularMonthly;

      this.analytics.setPrices({
        this_payment: priceDetails.currentValue.toPayNow.total,
        next_payment: priceDetails.currentValue.nextPayment.total,
        regular_price: priceDetails.currentValue.regularMonthly.total,
        insurance_price: priceDetails.currentValue.regularMonthly.insurance,
      });
    }
  }
}
