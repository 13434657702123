import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigService} from './config.service';
import {RestService} from './rest.service';
import {Observable} from 'rxjs';
import {CardDetails} from '../../user/models/card-details';

@Injectable()
export class AccountCardService extends RestService {
  basePath = 'account-card';
  apiPath = '';
  version = '';

  constructor(
    http: HttpClient,
    configService: ConfigService,
  ) {
    super(http, configService);
  }

  getAccountCardDetails(userId: string): Observable<CardDetails> {
    return this.http.get(`default-card/${userId}`);
  }

  addAccountCard(userId: string, language: string): Observable<any> {
    return this.http.post<any>({ language }, `add/${userId}`);
  }

  activateAccountCard(userId: string, cardId: string): Observable<any> {
    return this.http.post<any>(undefined, `activate/${userId}/${cardId}`);
  }
}
