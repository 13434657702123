import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StorageUnit} from '../../../core/models/storage-unit';

@Component({
  selector: 'app-unit-slider',
  templateUrl: './unit-slider.html',
  styleUrls: [ './unit-slider.scss' ]
})
export class UnitSliderComponent {
  @Input() public units: StorageUnit[];

  @Output() public redirectToBook = new EventEmitter<StorageUnit>();

  public selectedIndex: number = 0;

  slidePreviousUnit() {
    this.selectedIndex = this.selectedIndex === 0 ? (this.units.length - 1) : --this.selectedIndex;
  }

  slideNextUnit() {
    this.selectedIndex = this.selectedIndex === (this.units.length - 1) ? 0 : ++this.selectedIndex;

  }
}
