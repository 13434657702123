<div *ngIf="units">
  <div class="slideshow-container">
    <img width="40" height="41" alt="prev-button" class="previous-button"
         (click)="slidePreviousUnit()" src="assets/img/home/slide-back.png">
    <div *ngFor="let unit of units; let i=index"
         [ngClass]="selectedIndex === i ? 'slideshow-container_item-active' : 'slideshow-container_item'">
      <app-unit [unit]="unit" (redirectToBook)="redirectToBook.emit($event)"></app-unit>
    </div>
    <img width="41" height="41" alt="next-button" class="next-button"
         (click)="slideNextUnit()" src="assets/img/home/slide-forward.png">
  </div>
</div>
