<mat-expansion-panel *ngFor="let details of priceDetailsArray" class="price-panel">
  <mat-expansion-panel-header class="price-panel_header">
    <mat-panel-title class="price-panel_title-wrap">
      <span class="flex-grow-1">{{ details.title | translate}} {{details.id === 2 ? (details.price?.startsOn | date: 'd MMMM' : '' : (currentlang$ | async)) : ''}}</span>
      <span>{{details.price?.total | number : '1.0-0' | noComma}} kr </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="total-wrapper d-flex flex-column">
    <div class="price-wrapper">
      <p class="title">{{ 'BOOK.RENT' | translate }}</p>
      <p class="title" *ngIf="details.price?.startsOn"> {{details.price?.startsOn | date: 'yyyy/MM/dd' }} - {{details.price?.endsOn | date: 'yyyy/MM/dd'}}</p>
      <p class="price">{{ details.price?.rent | number : '1.0-0' | noComma}} {{ details.id === 3 ? ('kr/' + ('SEARCH.MONTH' | translate)) : 'kr'}}</p>
    </div>
    <div class="price-wrapper">
      <p class="title">{{ 'BOOK.DISCOUNT' | translate }}</p>
      <p class="price">{{ details.price?.discount ? (details.price?.discount  | number : '1.0-0' | noComma) : 0}} {{ details.id === 3 ? ('kr/' + ('SEARCH.MONTH' | translate)) : 'kr'}}</p>
    </div>
    <div class="price-wrapper">
      <p class="title">{{ 'BOOK.PRICE_INSURANCE' | translate }}</p>
      <p class="price">{{ details.price?.insurance | number : '1.0-0' | noComma}} {{ details.id === 3 ? ('kr/' + ('SEARCH.MONTH' | translate)) : 'kr'}}</p>
    </div>
    <div class="price-wrapper">
      <p class="title">{{ 'BOOK.TAX' | translate }}</p>
      <p class="price">{{ details.price?.tax | number : '1.0-0' | noComma}} {{ details.id === 3 ? ('kr/' + ('SEARCH.MONTH' | translate)) : 'kr'}}</p>
    </div>
    <div class="line-break"></div>
    <div class="price-wrapper">
      <p class="title total">{{ 'BOOK.TOTAL' | translate }}</p>
      <p class="price total">{{details.price?.total | number : '1.0-0' | noComma}} {{ details.id === 3 ? ('kr/' + ('SEARCH.MONTH' | translate)) : 'kr'}}</p>
    </div>
  </div>

</mat-expansion-panel>
