import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() subtitle: string;
  @Input() subtext: string;
  constructor() {
  }

  public ngOnInit(): void {
  }
}
