import {
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import get from 'lodash/get';

import {AuthenticationService, CheckOrderStatusOptions, LoginOptions} from '../../../core/services/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {concatMap, filter, startWith, tap} from 'rxjs/operators';
import {interval, Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() loading = false;
  @Input() signOptions = false;
  @Input() authenticationSpinner = false;
  @Input() modalOpened = false;
  @Input() registerCompany = false;
  @Input() unitName: string;
  @Input() authStarted: boolean;
  @Input() verificationFormClear = false;
  @Input() personalNumberValidationValid = true;
  @Input() sesamAccountLogin = false;
  @Input() loggedUser: any;
  @Output() closeModal = new EventEmitter();
  @Output() successVerification = new EventEmitter<LoginOptions>();
  @Output() checkBankIdOnFocus = new EventEmitter<{ orderReference: string }>();
  @Output() authenticationSpinnerEmitter = new EventEmitter<boolean>();
  @Output() openRegistrationForm = new EventEmitter();
  @Output() signBusinessUser = new EventEmitter<{ email: string, password: string }>();

  password = '';
  qrCodeData = '';
  desktopView = true;
  bankIdCode = '';
  confirmation = false;
  email = '';
  phoneNumber = '';

  orderReference: string;

  loginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });
  public showAdditionalInfoMessage = false;
  private hasFocus = new Subject<boolean>();
  public fieldTextType: boolean;
  private timeInterval: Subscription;
  private checkOrderResult: CheckOrderStatusOptions;
  loginFailed = false;
  loginOptions: LoginOptions = null;

  constructor(private authService: AuthenticationService, private toastrService: ToastrService) {
  }

  ngOnInit() {
    document.addEventListener('visibilitychange', () => {
      if (this.authStarted) {
        this.hasFocus.next(document.visibilityState === 'visible');
      }
    });

    this.hasFocus.pipe(filter(value => !!value)).subscribe(() => {
      this.checkBankIdOnFocus.emit({orderReference: this.orderReference});
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.confirmation = false;
    const sesamAccountChange = get(changes, 'sesamAccountLogin');
    // tslint:disable-next-line:max-line-length
    if (get(changes, 'authenticationSpinner') && get(changes, 'authenticationSpinner').currentValue !== get(changes, 'authenticationSpinner').previousValue) {
      this.authenticationSpinner = get(changes, 'authenticationSpinner').currentValue;
    }
    if (get(changes, 'authStarted') && get(changes, 'authStarted').currentValue !== get(changes, 'authStarted').previousValue) {
      this.authStarted = get(changes, 'authStarted').currentValue;
    }

    const loggedUser = get(changes, 'loggedUser');
    if (loggedUser && loggedUser.currentValue !== loggedUser.previousValue) {
      this.email = loggedUser.currentValue.email;
      this.loginForm.get('email').setValue(loggedUser.currentValue.email);
      this.loginForm.get('email').disable();
      this.loginForm.get('email').updateValueAndValidity();
    }
    const modalOpenedChange = get(changes, 'modalOpened');
    const verificationFormClearChange = get(changes, 'verificationFormClear');

    if (sesamAccountChange && sesamAccountChange.currentValue !== sesamAccountChange.previousValue) {
      this.sesamAccountLogin = sesamAccountChange.currentValue;
    }

    if (modalOpenedChange) {
      if (modalOpenedChange.currentValue && modalOpenedChange.firstChange === false) {
        const windowsWidth = window.innerWidth;
        this.showAdditionalInfoMessage = false;
        if (!this.signOptions && this.modalOpened === true) {
          if (windowsWidth > 720) {
            this.initBankIDAnimatedQRAuthentication();
          } else {
            if (!this.registerCompany) {
              this.desktopView = false;
              this.verifyUserSmallDisplays();
            }
          }
        } else {
          this.bankIdCode = '';
          this.personalNumberValidationValid = true;
        }
      } else {
        if (this.timeInterval) {
          this.timeInterval.unsubscribe();
        }
        this.authenticationSpinner = false;
      }
    }

    if (verificationFormClearChange) {
      if (verificationFormClearChange.currentValue && verificationFormClearChange.firstChange === false) {
        this.email = '';
        this.password = '';
        this.loginForm.reset();
      }
    }
  }

  ngOnDestroy() {
    if (this.timeInterval) {
      this.timeInterval.unsubscribe();
    }
  }


  public initBankIDAnimatedQRAuthentication() {
    this.authService.initiateAuthWithAnimatedQrCode(true, this.unitName).subscribe(response => {
      this.startCheckOrderPooling(response);
    }, (error) => {
      this.toastrService.error(error, 'Error');
    });
  }

  private startCheckOrderPooling(options: CheckOrderStatusOptions) {
    this.checkOrderResult = options;
    this.loginFailed = false;
    this.timeInterval = interval(1000).pipe(startWith(0), concatMap(() => this.authService.checkOrderStatus(this.checkOrderResult)),
      tap((res) => (this.loginOptions = res.user)))
      .subscribe((result) => {
        this.checkOrderResult = result;
        this.qrCodeData = result.qrCode;
        if (result.status === 'failed') {
          this.loginFailed = true;
          this.timeInterval.unsubscribe();
          return;
        }

        if (result.status === 'complete') {
          this.loginFailed = true;
          this.timeInterval.unsubscribe();
          this.successVerification.emit(result.user);
          return;
        }
      }, (error) => {
        this.timeInterval.unsubscribe();
        this.toastrService.error(error, 'Error');
      });
  }


  public loginSesamAccount() {
    this.email = this.loginForm.value.email || this.loggedUser.email;
    this.password = this.loginForm.value.password;

    this.signBusinessUser.emit({email: this.email, password: this.password});
  }

  verifyUserSmallDisplays() {
    this.authStarted = true;
    const verifyRequestObject = {
      email: this.email,
      phoneNumber: this.phoneNumber,
      unit: this.unitName
    };
    // tslint:disable-next-line:max-line-length
    this.authService.autoVerify(verifyRequestObject.email, verifyRequestObject.phoneNumber, verifyRequestObject.unit/*, verifyRequestObject.personalNumber*/).subscribe(res => {
      this.orderReference = res.orderReference;
      const link: any = 'bankid:///?autostarttoken=' + res.autostartToken + '&redirect=';
      window.location = link;
    });
  }

  public closeLoginModal() {
    this.closeModal.emit();
  }
}
