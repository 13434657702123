import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

const AUTH_HEADER = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public authService: AuthenticationService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const newRequest = this.authService.isLoggedIn
      ? request.clone({ setHeaders: { [AUTH_HEADER]: `${this.authService.sessionToken}` } })
      : request.clone();
    return next.handle(newRequest).pipe(tap(
      (err: any) => {
        if (err instanceof HttpResponse) {
          if (err.status === 401) {
            this.router.navigate(['user']);
          }
        }
      }
    ));
  }
}
