import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {User} from '../../models/user';
import {UpdateUser} from '../../models/update-user';

@Component({
  selector: 'app-personal-information-change',
  templateUrl: './personal-information-change.component.html',
  styleUrls: ['./personal-information-change.component.scss']
})
export class PersonalInformationChangeComponent implements OnInit, OnChanges {
  @Input() personalInformation: User;
  @Output() changePersonalInformationFormConfirmed = new EventEmitter<UpdateUser>();
  changePersonalInformationForm: FormGroup;
  actionExecuting = false;

  ngOnInit() {
    this.changePersonalInformationForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl(''),
      email: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      addressLine1: new FormControl(''),
      city: new FormControl(''),
      postalCode: new FormControl('')
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.personalInformation && changes.personalInformation.currentValue) {
      const value = changes.personalInformation.currentValue;
      this.populateForm(value);

      if (!this.personalInformation.isBusinessUser) {
        this.changePersonalInformationForm.get('lastName').setValidators([Validators.required]);
      }

      this.changePersonalInformationForm.get('lastName').updateValueAndValidity();
    }
  }

  populateForm(personalInfo: any): void {
    const currentPersonalInfo: UpdateUser = {
      firstName: personalInfo.firstName,
      lastName: personalInfo.lastName,
      addressLine1: personalInfo.street,
      city: personalInfo.city,
      email: personalInfo.email,
      phoneNumber: personalInfo.phoneNumber,
      postalCode: personalInfo.zipCode,
    };
    this.changePersonalInformationForm.patchValue(currentPersonalInfo);
  }

  onSubmit(): void {
    this.changePersonalInformationFormConfirmed.emit(this.changePersonalInformationForm.value);
  }

  resetForm(): void {
    this.changePersonalInformationForm.reset();
  }

  disableButton(): void {
    this.actionExecuting = true;
  }

  enableButton(): void {
    this.actionExecuting = false;
  }
}
