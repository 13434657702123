import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnChanges {
  @Input() modalOpened: boolean;
  @Input() recoverPassword: boolean;

  @Output() passwordChangeFormConfirmed = new EventEmitter<{oldPassword?: string; password: string; repeatPassword: string}>();

  public passwordError: string;
  public confirmPasswordError: string;

  public changePasswordForm = new FormGroup({
    oldPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    repeatPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  }, {
    validators: [
      this.checkPassword
    ]
  });

  constructor() {
  }

  public ngOnChanges({modalOpened}: SimpleChanges) {
    if (modalOpened.currentValue && modalOpened.currentValue !== modalOpened.previousValue) {
      this.changePasswordForm.reset();
    }
  }

  checkPassword(group: FormGroup) {
    const password = group.get('password').value;
    const confirmPassword = group.get('repeatPassword').value;

    if (password === '' || confirmPassword === '') {
      return null;
    }

    return password === confirmPassword
      ? null
      : {notSamePassword: true};
  }

  public changePassword(): void {
    this.passwordChangeFormConfirmed.emit({
      oldPassword: this.recoverPassword ? null : this.changePasswordForm.get('oldPassword').value,
      password: this.changePasswordForm.get('password').value,
      repeatPassword: this.changePasswordForm.get('repeatPassword').value
    });
  }

  public shouldBeDisabled(): boolean {
    return this.recoverPassword ?
      (this.changePasswordForm.get('password').invalid || this.changePasswordForm.get('repeatPassword').invalid ||
      this.changePasswordForm.hasError('notSamePassword')) :
      this.changePasswordForm.invalid;
  }
}
