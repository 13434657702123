import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../core/services/authentication.service';
import {Router} from '@angular/router';
import {animate, style, transition, trigger} from '@angular/animations';
import {ObservableService} from '../core/services/observable.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity: 0}),
        animate(500, style({opacity: 0.5}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity: 0}))
      ])
    ]),
    trigger('flyIn', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('1s 500ms ease-in')
      ]),
      transition(':leave', [
        animate('1s ease-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('mainElement', null) mainElement: ElementRef;
  sidebarOpened = false;
  scrollObservable: Subscription;
  setStickyClass: boolean;

  constructor(private authService: AuthenticationService, private router: Router, private observableService: ObservableService) {
    this.scrollObservable = this.observableService.$scrollTop.subscribe(() => {
      this.mainElement.nativeElement.scrollTop = 0;
    });
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      return this.router.navigate(['user/home']);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    this.setStickyClass = e.target && e.target.scrollTop > 10;
  }

  ngOnDestroy() {
    this.scrollObservable.unsubscribe();
  }

  toggleSidebar(event) {
    this.sidebarOpened = event;
  }

  onActivate(event) {
    this.mainElement.nativeElement.scrollTop = 0;
  }
}
