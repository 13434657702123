import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild('passwordChangeModal', null) passwordChangeModal: NgxSmartModalComponent;
  passwordChangeModalOpened = false;

  private guid: string;

  constructor(private router: Router, private authService: AuthenticationService, private toastrService: ToastrService,
              private translateService: TranslateService, private route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.passwordChangeModalOpened = true;
    this.passwordChangeModal.open();
  }

  public resetPassword(passwordModel: { oldPassword?: string; password: string; repeatPassword: string }) {
    this.guid = this.route.snapshot.paramMap.get('guid');
    this.authService.createPassword(this.guid, passwordModel.password).subscribe(res => {
      if (res) {
        this.toastrService.info(this.translateService.instant('COMMON.PASSWORD_CHANGED'));
        this.closeAndRedirect();
      }
    }, error => {
      if (error.error[0] === 'RESET_PASSWORD_PERIOD_EXPIRED') {
        this.toastrService.error(this.translateService.instant('COMMON.RESET_PASSWORD_PERIOD_EXPIRED'), '', {timeOut: 10000});
      } else {
        this.toastrService.error(this.translateService.instant('COMMON.SOMETHING_WENT_WRONG'), '', {timeOut: 10000});
      }
      this.closeAndRedirect();
    });
  }

  public closeAndRedirect(): void {
    this.passwordChangeModal.close();
    this.passwordChangeModalOpened = false;
    this.router.navigate(['']);
  }
}
