<div class="contact-wrap">

  <div class="form">

    <p class="form-title">
      {{ 'CONTACT.HELP' | translate }}
    </p>


    <div class="form-wrap d-flex flex-column">

      <input type="text" [(ngModel)]="customerEmail" [placeholder]="'CONTACT.EMAIL_PLACEHOLDER' | translate" />

      <textarea [(ngModel)]="customerMessage" [placeholder]="'CONTACT.MESSAGE_PLACEHOLDER' | translate"></textarea>

      <button [disabled]="!customerEmail || !customerMessage" (click)="contactSupport()" class="contact-button">{{ 'COMMON.SEND' | translate }}</button>

    </div>

    <div class="contact-image">
      <img src="assets/img/contact/contact_image.png">
    </div>


  </div>

</div>

<ngx-smart-modal #contactSupportModal identifier="contactSupportModal" [customClass]="'basic-modal'" [dismissable]="false">
  <img class="close-modal" src="assets/img/home/close_grey.svg" (click)="contactSupportModal.close()">
  <p class="title">{{ 'CONTACT.MESSAGE_SENT' | translate }}</p>
  <img class="success-image" src="assets/img/home/step_two.png">
  <p class="other-info">{{ 'CONTACT.DESCRIPTION' | translate }}</p>
</ngx-smart-modal>
