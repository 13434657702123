import {Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, Input, HostListener} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../core/services/authentication.service';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {StorageUnitService} from '../../core/services/storage-unit.service';
import {ObservableService} from '../../core/services/observable.service';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('loginModal', null) loginModal: NgxSmartModalComponent;
  @Input() isExternal = true;
  @Input() setStickyClass = false;
  @Output() toggleSidebarEmitter = new EventEmitter<boolean>();
  errorMessage = '';
  currentView = null;
  selectedPage: number;
  locations = [];
  currentBuilding: string;
  subscription: Subscription;
  loading = false;
  modalOpened = false;
  sidebarOpened = false;
  bookingProcess = false;
  loginFormClear = false;
  rentStorageText: string;

  constructor(private authService: AuthenticationService,
              private router: Router,
              private storageUnitService: StorageUnitService,
              private observableService: ObservableService) {

    this.rentStorageText = (window.innerWidth < 991) ? 'HEADER.TEXT_HEADER2' : 'HEADER.TEXT_HEADER1';

    this.subscription = this.observableService.currentLocation.subscribe(l => {
      this.getCurrentLocation(l);
    });

    this.router.events.subscribe((val: any) => {
      this.bookingProcess = val.url && !!val.url.includes('unit-info');
      switch (val.url) {
        case '/user/home':
        case '/home':
          this.selectedPage = 1;
          break;
        case '/user/storage-calculator':
        case '/storage-calculator':
          this.selectedPage = 2;
          break;
        case '/user/book':
        case '/book':
          this.selectedPage = 3;
          break;
        case '/user/hyra-forrad':
        case '/hyra-forrad':
          this.selectedPage = 4;
          break;
        case '/user/contact':
        case '/contact':
          this.selectedPage = 5;
          break;
        case '/user/account':
          this.selectedPage = 6;
          break;
        case '/user/invoices':
          this.selectedPage = 7;
          break;
        default:
          this.selectedPage = null;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event): void {
    this.rentStorageText = (event.target && event.target.innerWidth < 991) ? 'HEADER.TEXT_HEADER2' : 'HEADER.TEXT_HEADER1';
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
    this.toggleSidebarEmitter.emit(this.sidebarOpened);
  }

  ngOnInit() {
    if (this.isExternal) {
      this.loginModal.open();
    }

    this.observableService.currentLocationName$.pipe(tap((name) => {
      this.currentBuilding = name;
    })).subscribe();

    this.observableService.locationBuildings$.subscribe(locations => {
      this.locations = locations;
      const buildingId = sessionStorage.getItem('buildingId');
      if (this.locations && this.locations.length > 0) {
        let index = this.locations.findIndex(loc => loc.buildingId === buildingId);
        index = index !== -1 ? index : 0;
        // tslint:disable-next-line:max-line-length
        this.observableService.setCurrentLocation(this.locations[index].locationId, this.locations[index].buildingId, this.locations[index].locationName, null);
        this.currentBuilding = this.locations[index].locationName;
      }
    });

    this.bookingProcess = !!this.router.url.includes('unit-info');
    switch (this.router.url) {
      case '/user/home':
      case '/home':
        this.selectedPage = 1;
        break;
      case '/user/storage-calculator':
      case '/storage-calculator':
        this.selectedPage = 2;
        break;
      case '/user/book':
      case '/book':
        this.selectedPage = 3;
        break;
      case '/user/hyra-forrad':
      case '/hyra-forrad':
        this.selectedPage = 4;
        break;
      case '/user/contact':
      case '/contact':
        this.selectedPage = 5;
        break;
      case '/user/account':
        this.selectedPage = 6;
        break;
      case '/user/invoices':
        this.selectedPage = 7;
        break;
      default:
        this.selectedPage = null;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getCurrentLocation(location) {
    const index = this.locations.findIndex(loc => loc.buildingId === location.buildingId);
    this.currentBuilding = this.locations[index].locationName;
  }

  selectBuilding(location) {
    if (this.currentBuilding !== location.locationName) {
      this.currentBuilding = location.locationName;
      this.observableService.setCurrentLocation(location.locationId, location.buildingId, location.locationName, location.address);
    }
    // tslint:disable-next-line:max-line-length
    this.router.navigate([(this.isLoggedIn ? 'user/' : '') + 'hyra-forrad', location.locationName]).then(() => this.observableService.scrollToAnchor());
  }

  goToProfile() {
    return this.router.navigate(['user/account']);
  }

  goToInvoices() {
    return this.router.navigate(['user/invoices']);
  }

  logout() {
    this.authService.logout();

    return this.router.navigate(['hyra-forrad']);
  }

  closeLoginModal() {
    this.loginModal.close();
    this.modalOpened = false;
    this.loginFormClear = true;
    this.isExternal = false;
    this.reloadPage();
  }

  private reloadPage() {
    if (this.router.url === '/login') {
      this.router.navigate(['']);
    } else {
      return this.router.navigateByUrl(this.router.url, {skipLocationChange: true});
    }
  }
}
