import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigService} from './config.service';
import {RestService} from './rest.service';
import {Observable} from 'rxjs';

@Injectable()
export class CreditCheckService extends RestService {
  basePath = 'credit-check';
  apiPath = '';
  version = '';

  constructor(http: HttpClient,
    configService: ConfigService) {
    super(http, configService);
  }

  organizationNumberCheck(organizationNumber): Observable<any> {
    const body = {
      organizationNumber,
    };
    return this.http.post(body, 'basic');
  }

  creditCheck(organizationNumber): Observable<any> {
    const body = {
      organizationNumber,
    };
    return this.http.post(body, 'credit');
  }
}
