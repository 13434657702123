<div class="book-wrap">


  <div *ngIf="loaded; else loading" class="account-unit row">
    <p class="full-title col-md-12">{{ 'INVOICE.HOME' | translate }}</p>
    <div class="invoices-info">
      <div class="invoices" *ngIf="invoices && invoices.length > 0; else noInvoices">
        <div class="invoice-month" *ngFor="let monthlyInvoices of invoices; let i = index">
          <div class="invoice-month-header" (click)="setCurrentIndex(i)">
            <p class="invoice-month-title">{{ ('INVOICE.' + monthlyInvoices.name)  | translate }}</p>
            <img [src]="currentIndex === i ? 'assets/img/invoice/up_arrow.svg' : 'assets/img/invoice/down_arrow.svg'">
          </div>
          <div class="invoice" *ngIf="currentIndex === i">
            <div class="invoice-header d-flex flex-row justify-content-between">
              <p class="header-title">
                {{ 'INVOICE.UNIT_NUMBER' | translate }}
              </p>
              <p class="header-title">
                {{ 'INVOICE.UNIT_NAME' | translate }}
              </p>
              <p class="header-title">
                {{ 'INVOICE.CONTRACT_ID' | translate }}
              </p>
              <p class="header-title">
                {{ 'INVOICE.LOCATION' | translate }}
              </p>
              <p class="header-title">
                {{ 'INVOICE.DATE' | translate }}
              </p>
              <p class="header-title">
                {{ 'INVOICE.AMOUNT' | translate }}
              </p>
              <p class="header-title">
                {{ 'INVOICE.STATUS' | translate }}
              </p>
              <p class="header-title">
                {{ 'INVOICE.INVOICE_URL' | translate }}
              </p>
            </div>
            <div class="invoice-content d-flex flex-row justify-content-between" *ngFor="let invoice of monthlyInvoices.invoices">
              <p class="content-title">
                {{ invoice.unitNumber }}
              </p>
              <p *ngIf="invoice.unitName; else emptyContent" class="content-title" [matTooltip]="invoice.unitName" [matTooltipDisabled]="invoice.unitName.length <= 11" matTooltipPosition="above">
                {{ invoice.unitName.length > 11 ? (invoice.unitName | slice:0:11) + '...' : invoice.unitName }}
              </p>
              <p class="content-title">
                {{ invoice.contractId }}
              </p>
              <p class="content-title">
                {{ invoice.locationName}} - {{ invoice.buildingName }}
              </p>
              <p class="content-title">
                {{ invoice.invoiceDate | date: 'dd MMMM yyyy' : '' : (currentlang$ | async)}}
              </p>
              <p class="content-title">
                {{ invoice.invoiceAmount | number : '1.0-0' | noComma}} kr
              </p>
              <div class="content-title">
                <p [ngClass]="invoice.invoiceStatus" class="content-title auto">
                  {{ ('INVOICE.' + invoice.invoiceStatus) | translate }}
                </p>
              </div>
              <a *ngIf="invoice.invoiceUrl; else emptyContent" class="content-title link" [href]="invoice.invoiceUrl" target="_blank">
                <img src="assets/img/invoice/pdf_icon.svg">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>



<ng-template #noInvoices>
  <div class="wrapper-no-invoices d-flex flex-column justify-content-center align-items-center w-100">
    <p class="no-invoices-title">{{ 'INVOICE.NO_INVOICES' | translate }}</p>
    <img class="no-invoices-picture" src="assets/img/home/steps_illustration.png">
  </div>
</ng-template>

<ng-template #emptyContent>
  <p class="content-title"></p>
</ng-template>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
