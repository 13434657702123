<div class="book-wrap">

  <div *ngIf="loaded; else loading">
    <div class="button-wrap" [class.cancel-only]="currentStep !== 2">
      <button *ngIf="currentStep === 2" class="cancel-button back"
              (click)="backToFirstStep();">{{ 'COMMON.PREVIOUS' | translate }}
      </button>

      <button *ngIf="currentStep === 1 || currentStep === 2 || !success" class="cancel-button"
              (click)="cancel(); this.analytics.event('checkout_booking-cancelled', {checkout_step: currentStep})">{{ 'COMMON.CANCEL' | translate }}
      </button> <!--GA4-->
      <button *ngIf="currentStep === 3 && success" class="cancel-button finish"
              (click)="nextPage()">{{ 'COMMON.FINISH' | translate }}
      </button>
    </div>

    <div class="steps d-flex justify-content-around align-items-center">
      <div class="step" [class.active]="currentStep === 1">
        <img [src]="(currentStep === 1) ? 'assets/img/book/first_step_active.png' : 'assets/img/book/first_step.png'">
        <p class="title" [class.active]="currentStep === 1">1. {{ 'BOOK.FIRST_STEP' | translate }}</p>
      </div>
      <div class="step" [class.active]="currentStep === 2">
        <img [src]="(currentStep === 2) ? 'assets/img/book/second_step_active.png' : 'assets/img/book/second_step.png'">
        <p class="title" [class.active]="currentStep === 2">
          2. {{ (registerCompany && paymentMethod === PaymentMethod.Invoice) ? ('BOOK.SECOND_STEP.INVOICE' | translate) : ('BOOK.SECOND_STEP.CARD' | translate) }}</p>
      </div>
      <div class="step" [class.active]="currentStep === 3">
        <img [src]="(currentStep === 3) ? 'assets/img/book/third_step_active.png' : 'assets/img/book/third_step.png'">
        <p class="title" [class.active]="currentStep === 3">3. {{ 'BOOK.THIRD_STEP' | translate }}</p>
      </div>
    </div>

    <div *ngIf="unit && currentStep === 1" class="step-1-wrapper">
      <div class="box d-flex flex-column"
           [ngClass]="{'more': unit.additionalDescription || unit.discount}">
        <div class="box-image d-flex flex-column">
          <div class="box-image-wrapper">
            <img [src]="unit.imageUrl || 'assets/img/book/image_1.png'">
            <app-badge *ngIf="unit.discount"
                       [title]="unit.discount.discountType === 'MIX' ? unit.discount.firstMonthPrice + ' kr'
                   : (unit.discount.discountType === 'PERCENTAGE' ? unit.discount.value + '%' : unit.discount.amount + ' kr')"

                       [text]="unit.discount.totalMonths === 1 ? ('BOOK.DISCOUNT_ONE_MONTH' | translate)
                       : unit.discount.discountType === 'MIX' ? ('BOOK.DISCOUNT_ONE_MONTH_MIXED' | translate)
                      : 'BOOK.DISCOUNT_RANGE_TWO_MONTHS' | translate : {nrOfMonths: unit.discount.totalMonths}"
                       [subtitle]="unit.discount.discountType === 'MIX' ? unit.discount.secondMonthPercentage + ' %' : null"
                       [subtext]="unit.discount.discountType === 'MIX' ? ('BOOK.FOLLOWING_MONTH' | translate) : null"
            ></app-badge>
          </div>
          <div class="box-title">
            <div class="box-info">
              <p
                class="main">{{ unit.unitTypeName === 'Locker' ? unit.size + ' &#13221; Box' : unit.size + ' &#13217;' }}</p>
              <p *ngIf="unit.discount && unit.discount.discountType !== 'MIX'"
                 class="small">{{ unit.discount.price | number : '1.0-0' | noComma }}
                kr/{{ 'SEARCH.MONTH' | translate }}</p>
              <p *ngIf="unit.discount && unit.discount.discountType !== 'MIX'" class="small">
                <s>{{ unit.netAmount | number : '1.0-0' | noComma }} kr/{{ 'SEARCH.MONTH' | translate }}</s></p>
              <p *ngIf="!unit.discount || unit.discount.discountType === 'MIX'"
                 class="small">{{ unit.netAmount | number : '1.0-0' | noComma }} kr/{{ 'SEARCH.MONTH' | translate }}</p>
            </div>
          </div>

          <div *ngIf="unit.additionalDescription || unit.discount" class="more-text">
            <ul class="more-text_list">
              <li *ngFor="let description of unit.additionalDescription" class="more-text_list-item">{{ description }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <form [formGroup]="form">
        <div id="unitList" class="rent-form">
          <div class="form-field">
            <mat-label class="rent-form_input-label"
                       [style.color]="form.controls.date.invalid && form.controls.date.touched ? '#ff0033' : '#118C93'">{{ 'BOOK.RENT_FROM' | translate }}
            </mat-label>
            <mat-form-field class="rent-form_input" appearance="outline" [class.error]="true">
              <input class="event-input" readonly matInput [matDatepicker]="startPicker" [min]="minDate" [max]="maxDate"
                     [formControl]="form.controls.date"
                     [(ngModel)]="dateFrom">
              <mat-datepicker-toggle matSuffix [for]="startPicker">
                <img style="width: 24px; position: absolute; top: 0; right: 1px;" src="assets/img/book/calendar.png"
                     matDatepickerToggleIcon>
              </mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <label *ngIf="!user" for="B2B-checkbox" class="container form-checkbox">
            <p class="checkbox-label">
              {{ 'BOOK.RENT_AS_COMPANY' | translate }}
            </p>
            <input
              type="checkbox"
              id="B2B-checkbox"
              [(ngModel)]="registerCompany"
              [formControl]="form.controls.registerCompany"
              (change)="onUserTypeCheckboxChange($event.target.checked)"
            >
            <span class="checkmark checkmark-terms"></span>
          </label>
          <div *ngIf="registerCompany" class="container payment-options">
            <p class="checkbox-label"
               [style.color]="form.controls.paymentMethod.invalid && form.controls.paymentMethod.touched ? '#ff0033' : '#118C93'">{{ 'BOOK.PAYMENT_METHOD' | translate }}</p>
            <label class="form-check form-check-inline" (click)="onPaymentMethodChange('card')">
              <input
                class="form-check-input"
                type="radio"
                name="paymentMethod"
                id="paymentMethodCard"
                formControlName="paymentMethod"
                [(ngModel)]="paymentMethod"
                [value]="PaymentMethod[PaymentMethod.Card]"
              />
              <span class="checkmark checkmark-terms"></span>
              <label class="form-check-label" for="paymentMethodCard">
                <p>{{ 'BOOK.CARD' | translate }}</p>
              </label>
            </label>
            <label class="form-check form-check-inline" (click)="onPaymentMethodChange('invoice')">
              <input
                class="form-check-input"
                type="radio"
                name="paymentMethod"
                id="paymentMethodInvoice"
                formControlName="paymentMethod"
                [(ngModel)]="paymentMethod"
                [value]="PaymentMethod[PaymentMethod.Invoice]"
              />
              <span class="checkmark checkmark-terms"></span>
              <label class="form-check-label" for="paymentMethodInvoice">
                <p>{{ 'BOOK.INVOICE' | translate }}</p>
              </label>
            </label>
          </div>
          <div class="w-100 select-wrapper" style="margin-top: 10px;">
            <div class="d-flex">
              <mat-label class="rent-form_input-label"
                         [style.color]="form.controls.insurance.invalid && form.controls.insurance.touched ? '#ff0033' : '#118C93'">{{ 'BOOK.INSURANCES' | translate }}
              </mat-label>
              <mat-label class="more-info" (click)="insuranceInfoModal.open();">{{ 'BOOK.MORE' | translate }}
              </mat-label>
            </div>
            <mat-form-field class="rent-form_input" appearance="outline" [class.error]="true">
              <mat-select [(ngModel)]="currentInsuranceId" [formControl]="form.controls.insurance">
                <mat-option *ngFor="let insurance of unit.insurances" [value]="insurance.id">
                  <div class="d-flex flex-row align-items-center">
                    <p class="pt10">{{ insurance.planName }}
                      - {{ registerCompany ? insurance.premium.business.netAmount : insurance.premium.domestic.netAmount }}
                      kr/{{ 'SEARCH.MONTH' | translate }}</p>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="w-100">
            <label class="container form-checkbox">
              <p class="checkbox-label"
                 [style.color]="form.controls.terms.invalid && form.controls.terms.touched ? '#ff0033' : '#118C93'">{{ 'BOOK.READ' | translate }}
                <a [href]="agreementUrl" target="_blank">
                  <span class="read-label-decoration"
                        [style.color]="form.controls.terms.invalid && form.controls.terms.touched ? '#ff0033' : '#118C93'">{{ 'BOOK.TERMS' | translate }}</span>
                </a>
              </p>
              <input required type="checkbox" [(ngModel)]="termsConditionsChecked" [formControl]="form.controls.terms">
              <span class="checkmark checkmark-terms"></span>
            </label>
            <label class="container form-checkbox">
              <p class="checkbox-label"
                 [style.color]="form.controls.integrityPolicy.invalid && form.controls.integrityPolicy.touched ? '#ff0033' : '#118C93'">{{ 'BOOK.READ_INTEGRITY' | translate }}
                <a [href]="integrityPolicyUrl" target="_blank">
                  <span class="read-label-decoration"
                        [style.color]="form.controls.integrityPolicy.invalid && form.controls.integrityPolicy.touched ? '#ff0033' : '#118C93'">{{ 'BOOK.TERMS' | translate }}</span>
                </a>
              </p>
              <input required type="checkbox" [(ngModel)]="integrityPolicyChecked"
                     [formControl]="form.controls.integrityPolicy">
              <span class="checkmark checkmark-terms"></span>
            </label>
            <label class="container form-checkbox">
              <p class="checkbox-label"
                 [style.color]="form.controls.withdrawalPolicy.invalid && form.controls.withdrawalPolicy.touched ? '#ff0033' : '#118C93'">{{ 'BOOK.WITHDRAWAL' | translate }}</p>
              <input required type="checkbox" [(ngModel)]="withdrawalPolicyChecked"
                     [formControl]="form.controls.withdrawalPolicy">
              <span class="checkmark checkmark-terms"></span>
            </label>
          </div>

          <app-price-calculation [priceDetails]="priceDetails$ | async"></app-price-calculation>

          <div *ngIf="!registerCompany; else companyVerificationButton" class="button-wrapper-sign">
            <button class="sign-button"
                    (click)="validateForm(false)">{{ 'BOOK.NEXT' | translate }}
            </button>
          </div>
          <ng-template #companyVerificationButton>
            <div class="button-wrapper-next">
              <button class="next-button" (click)="verifyCompany()">
                {{ 'BOOK.NEXT' | translate }}
              </button>
            </div>
          </ng-template>
        </div>
      </form>
    </div>
    <div
      *ngIf="((paymentFormLink && paymentMethod === PaymentMethod.Card) || paymentMethod === PaymentMethod.Invoice) && currentStep === 2"
      class="step-2-wrapper">
      <div *ngIf="paymentFinalizing; else payment" class="loading-view">
        <app-loading></app-loading>
      </div>
      <ng-template #payment>
        <div *ngIf="!registerCompany || (registerCompany && paymentMethod === PaymentMethod.Card); else invoicePayment">
          <div *ngIf="paymentLoading" class="loading-view">
            <app-loading></app-loading>
          </div>
          <div [hidden]="paymentLoading" class="payment-step">
            <div class="book-unit-info">
              <app-price-calculation class="col-md-12" [priceDetails]="priceDetails$ | async"></app-price-calculation>
            </div>
            <div class="payment-cards d-flex justify-content-around align-items-center">
              <img src="assets/img/book/visa.png">
              <img src="assets/img/book/master.png">
            </div>
            <div class="iframe-wrapper">
              <iframe #iframe (load)="loadDone()" class="iframe"
                      [src]="paymentFormLink | safe"></iframe>
            </div>
          </div>
        </div>
        <ng-template #invoicePayment>
          <div>
            <app-loading></app-loading>
          </div>
        </ng-template>
      </ng-template>
    </div>
    <div *ngIf="currentStep === 3 && success" class="step-3-wrapper">
      <p class="final-title">{{ 'COMMON.CONGRATULATIONS' | translate }}</p>
      <p class="final-text">{{ 'BOOK.SUCCESS_RENT' | translate }}</p>
      <p class="final-text">{{ 'BOOK.EMAIL' | translate : {email: email} }}</p>
      <p class="final-text">{{ 'BOOK.STORAGE_ID' | translate }} {{ unitNumber }}</p>
      <input class="rent-form-input" type="text" [placeholder]="'BOOK.STORAGE_PLACEHOLDER' | translate"
             [(ngModel)]="unitName">
      <button class="save-unit-button add-name" (click)="addBookingName()">{{ 'COMMON.SAVE' | translate }}</button>
      <p class="final-title">{{ 'BOOK.DOWNLOAD_APP' | translate }}</p>
      <p class="final-text">{{ 'BOOK.DOWNLOAD_APP_TEXT' | translate }}</p>
      <div class="download-icons-wrapper">
        <a href="https://apps.apple.com/se/app/sesam-self-storage/id1540932192" target="_blank">
          <img src="assets/img/book/appstore.png">
        </a>
        <a href="https://play.google.com/store/apps/details?id=se.sesamselfstorage.sesam" target="_blank">
          <img src="assets/img/book/playstore.png">
        </a>
      </div>
    </div>

    <div *ngIf="currentStep === 3 && !success" class="step-3-wrapper">
      <p class="final-title">{{ 'COMMON.ERROR' | translate }}</p>
      <p class="final-text">{{ 'BOOK.PAYMENT_FAIL' | translate }}</p>
      <button class="save-unit-button" (click)="backToFirstStep();">{{ 'BOOK.TRY_AGAIN' | translate }}</button>
    </div>

    <div *ngIf="currentStep === 4" class="step-3-wrapper">
      <p class="final-title">{{ 'COMMON.ERROR' | translate }}</p>
      <p
        class="final-text">{{ (creditValidCompany === false ? 'BOOK.CREDIT_CHECK_FAILED_MESSAGE' : 'BOOK.OCCUPIED') | translate }}</p>
      <button class="save-unit-button" (click)="cancel()">{{ 'COMMON.BACK' | translate }}</button>
    </div>

  </div>
</div>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

<ngx-smart-modal #verificationModal identifier="verificationModal" [customClass]="'basic-modal'" [dismissable]="false">
  <img class="close-modal" src="assets/img/home/close_grey.svg"
       (click)="verificationModal.close(); modalOpened = false; verificationFormClear = true; bankIdSubscription?.unsubscribe();">

  <app-verification
    [modalOpened]="modalOpened"
    [signOptions]="signOptions"
    [authenticationSpinner]="authenticationSpinner"
    [verificationFormClear]="verificationFormClear"
    [registerCompany]="registerCompany"
    [unitName]="unit?.unitNumber"
    [loggedUser]="user"
    [sesamAccountLogin]="sesamAccount"
    [authStarted]="authStarted"
    (successVerification)="signUnitWithAnimatedQRBankId($event); verificationFormClear = true;"
    (openRegistrationForm)="openRegistrationForm()"
    (signBusinessUser)="registerCompanyAndSign($event)"
    (checkBankIdOnFocus)="checkBankIdOnfocus($event); verificationFormClear = true;"
    (authenticationSpinnerEmitter)="authenticationSpinner = true;"
  >
  </app-verification>
</ngx-smart-modal>

<ngx-smart-modal #companyVerificationModal identifier="companyVerificationModal" [customClass]="'basic-modal'"
                 [dismissable]="false">
  <img class="close-modal" src="assets/img/home/close_grey.svg"
       (click)="companyVerificationModal.close(); modalOpened=false;">
  <div *ngIf="companyVerifying">
    <img style="width: 150px; margin-top: -110px; margin-bottom: 10px;" src="assets/img/home/sesam-logo.svg"/>
    <p class="title" style="margin-top: 90px; font-size: 14px;">{{ 'BOOK.COMPANY_VERIFICATION' | translate }}</p>
    <app-loading></app-loading>
  </div>

  <div *ngIf="!companyVerifying">
    <div class="company-verification">
      <app-verification [sesamAccountLogin]="true"
                        [signOptions]="signOptions"
                        (openRegistrationForm)="registerCompany = true; shouldCompanyBeRegistered = true; confirmationModal.open()"
                        (closeModal)="companyVerificationModal.close(); verificationFormClear = true;"></app-verification>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal #creditCheckModal identifier="creditCheckModal" [customClass]="'basic-modal'" [dismissable]="false">
  <img class="close-modal" src="assets/img/home/close_grey.svg" (click)="creditCheckModal.close(); modalOpened=false;">
  <div *ngIf="creditChecking; else creditChecked">
    <img style="width: 150px; margin-top: -110px; margin-bottom: 10px;" src="assets/img/home/sesam-logo.svg"/>
    <p class="title" style="margin-top: -10px; font-size: 16px;">{{ 'BOOK.CREDIT_CHECK' | translate }}</p>
    <p class="title" style="margin-top: 90px; font-size: 14px;">{{ 'BOOK.COMPANY_VERIFICATION' | translate }}</p>
    <app-loading></app-loading>
  </div>

  <ng-template #creditChecked>
    <div class="credit-check">
      <img width="auto" height="auto" style="margin-top: -160px; margin-bottom: 30px; margin-left: 143px;"
           src="assets/img/home/sesam-logo.svg"/>
      <p class="credit-not-valid"
         *ngIf="creditValidCompany === false">{{ 'BOOK.CREDIT_CHECK_FAILED_MESSAGE' | translate }}</p>
    </div>
  </ng-template>
</ngx-smart-modal>

<ngx-smart-modal #confirmationModal identifier="confirmationModal" [customClass]="'basic-modal'" [dismissable]="false">
  <img class="close-modal" src="assets/img/home/close_grey.svg"
       (click)="confirmationModal.close(); modalOpened=false; loginOptions = null; bankIdSubscription?.unsubscribe();">
  <div class="login-wrapper">
    <form class="login-form" [formGroup]="confirmationForm" #f="ngForm">
      <img style="margin-top: -10px; margin-bottom: 40px;" src="assets/img/home/sesam-logo.svg">
      <div class="sign-options-wrapper">
        <p class="code-title">{{ 'CONFIRMATION.WELCOME' | translate }}
          <br/>{{ 'CONFIRMATION.PROVIDE_INFORMATIONS' | translate }}</p>

        <div class="input-field">
          <p class="confirmation-title">{{ 'CONFIRMATION.EMAIL' | translate }}</p>
          <input type="text"
                 [class]="(confirmationForm.controls.email.invalid && confirmationForm.controls.email.touched) || confirmationForm.hasError('notSameEmail') || confirmationForm.controls.email.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
                 name="email" formControlName="email" [placeholder]="'COMMON.EMAIL_PLACEHOLDER' | translate">
        </div>

        <div class="input-field">
          <p class="confirmation-title">{{ 'CONFIRMATION.VERIFY_EMAIL' | translate }}</p>
          <input type="text"
                 [class]="(confirmationForm.controls.emailConfirm.invalid && confirmationForm.controls.emailConfirm.touched) || confirmationForm.hasError('notSameEmail') || confirmationForm.controls.email.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
                 name="emailConfirm" formControlName="emailConfirm"
                 [placeholder]="'COMMON.EMAIL_PLACEHOLDER' | translate">
          <p *ngIf="confirmationForm.hasError('notSameEmail')"
             class="confirmation-error">{{ 'CONFIRMATION.NOT_SAME_EMAIL' | translate }}</p>
          <p *ngIf="confirmationForm.controls.email.hasError('server')"
             class="confirmation-error">{{ 'CONFIRMATION.EMAIL_USED' | translate }}</p>
          <p *ngIf="confirmationForm.controls.email.hasError('userExist')"
             class="confirmation-error">{{ 'CONFIRMATION.SSN_EXIST' | translate }}</p>
        </div>

        <div *ngIf="registerCompany && shouldCompanyBeRegistered" class="input-field">
          <p class="confirmation-title">{{ 'COMMON.PASSWORD' | translate }}:</p>
          <div class="d-flex">
            <input
              [type]="fieldTextType1 ? 'text' : 'password'"
              [class]="(confirmationForm.controls.password.invalid && confirmationForm.controls.password.touched) || confirmationForm.controls.password.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
              name="password"
              formControlName="password"
              [placeholder]="'COMMON.PASSWORD' | translate"
            >
            <div (click)="fieldTextType1 = !fieldTextType1" class="input-group-append show-password-icon">
              <span class="input-group-text">
                <i class="fa fa-eye-slash"></i>
              </span>
            </div>
          </div>
          <p
            *ngIf="confirmationForm.controls.password.invalid && confirmationForm.controls.password.touched && !confirmationForm.controls.password.hasError('server')"
            class="confirmation-error">{{ 'COMMON.PASSWORD_IS_NOT_IN_VALID_FORMAT' | translate }}</p>
          <p
            *ngIf="confirmationForm.controls.email.hasError('server') && confirmationForm.controls.password.hasError('server')"
            class="confirmation-error">{{ 'COMMON.USER_WRONG_PASSWORD' | translate }}</p>
        </div>
        <div *ngIf="registerCompany && shouldCompanyBeRegistered" class="input-field">
          <p class="confirmation-title">{{ 'COMMON.PASSWORD_VERIFY' | translate }}:</p>
          <div class="d-flex">
            <input
              [type]="fieldTextType2 ? 'text' : 'password'"
              [class]="(confirmationForm.controls.passwordVerify.invalid && confirmationForm.controls.passwordVerify.touched) || confirmationForm.controls.passwordVerify.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
              name="passwordVerify"
              formControlName="passwordVerify"
              [placeholder]="'COMMON.PASSWORD_VERIFY' | translate"
            >
            <div (click)="fieldTextType2 = !fieldTextType2" class="input-group-append show-password-icon">
              <span class="input-group-text">
                <i class="fa fa-eye-slash"></i>
              </span>
            </div>
          </div>
          <p *ngIf="confirmationForm.hasError('notSamePassword')"
             class="confirmation-error">{{ 'COMMON.SAME_PASSWORD' | translate }}</p>
          <p
            *ngIf="confirmationForm.controls.passwordVerify.invalid && confirmationForm.controls.passwordVerify.touched && !confirmationForm.controls.passwordVerify.hasError('server')"
            class="confirmation-error">{{ 'COMMON.PASSWORD_IS_NOT_IN_VALID_FORMAT' | translate }}</p>
          <p
            *ngIf="confirmationForm.controls.email.hasError('server') && confirmationForm.controls.password.hasError('server')"
            class="confirmation-error">{{ 'COMMON.USER_WRONG_PASSWORD' | translate }}</p>
        </div>
        <div class="line-break-registration"></div>

        <div *ngIf="registerCompany && shouldCompanyBeRegistered" class="input-field d-flex">
          <div class="name-field">
            <p class="confirmation-title">{{ 'COMMON.FIRST_NAME' | translate }}</p>
            <input
              type="text"
              [class]="(confirmationForm.controls.firstName.invalid && confirmationForm.controls.firstName.touched) ? 'error-confirmation-name-input' : 'confirmation-input name-field'"
              name="firstName"
              formControlName="firstName"
              [placeholder]="'COMMON.FIRST_NAME' | translate"
            >
          </div>

          <div class="last-name-field">
            <p class="confirmation-title ml10">{{ 'COMMON.LAST_NAME' | translate }}</p>
            <input
              type="text"
              [class]="(confirmationForm.controls.lastName.invalid && confirmationForm.controls.lastName.touched) ? 'error-confirmation-name-input' : 'confirmation-input last-name-field'"
              name="lastName"
              formControlName="lastName"
              [placeholder]="'COMMON.LAST_NAME' | translate"
            >
          </div>
        </div>

        <div class="input-field">
          <p class="confirmation-title">{{ 'CONFIRMATION.PHONE_NUMBER' | translate }}</p>
          <ngx-intl-tel-input
            [cssClass]="(confirmationForm.controls.phoneNumber.invalid && confirmationForm.controls.phoneNumber.touched) || confirmationForm.hasError('notSamePhoneNumber') || confirmationForm.controls.phoneNumber.hasError('server') || confirmationForm.hasError('notValidLength') ? 'error-phone-number' : 'phone-number'"
            [onlyCountries]="onlyCountries"
            [enableAutoCountrySelect]="true"
            [selectFirstCountry]="true"
            [selectedCountryISO]="CountryISO.Sweden"
            [customPlaceholder]="'701 23 45 67'"
            [maxLength]="15"
            [phoneValidation]="false"
            [numberFormat]="PhoneNumberFormat.International"
            [separateDialCode]="true"
            name="phoneNumber"
            formControlName="phoneNumber">
          </ngx-intl-tel-input>
        </div>

        <div class="input-field">
          <p class="confirmation-title">{{ 'CONFIRMATION.VERIFY_PHONE_NUMBER' | translate }}</p>
          <ngx-intl-tel-input
            [cssClass]="(confirmationForm.controls.phoneNumberConfirm.invalid && confirmationForm.controls.phoneNumberConfirm.touched) || confirmationForm.hasError('notSamePhoneNumber') || confirmationForm.controls.phoneNumber.hasError('server') || confirmationForm.hasError('notValidLength') ? 'error-phone-number' : 'phone-number'"
            [onlyCountries]="onlyCountries"
            [enableAutoCountrySelect]="true"
            [selectFirstCountry]="true"
            [selectedCountryISO]="CountryISO.Sweden"
            [customPlaceholder]="'701 23 45 67'"
            [maxLength]="15"
            [phoneValidation]="false"
            [numberFormat]="PhoneNumberFormat.International"
            [separateDialCode]="true"
            name="phoneNumberConfirm"
            formControlName="phoneNumberConfirm">
          </ngx-intl-tel-input>
          <p *ngIf="confirmationForm.hasError('notSamePhoneNumber')"
             class="confirmation-error">{{ 'CONFIRMATION.NOT_SAME_PHONE_NUMBER' | translate }}</p>
          <p *ngIf="confirmationForm.controls.phoneNumber.hasError('server')"
             class="confirmation-error">{{ 'CONFIRMATION.PHONE_NUMBER_USED' | translate }}</p>
        </div>

        <div class="line-break-registration"></div>

        <div *ngIf="registerCompany && shouldCompanyBeRegistered" class="input-field">
          <p class="confirmation-title">{{ 'CONFIRMATION.ORGANIZATION_NUMBER' | translate }}</p>
          <input type="text"
                 [class]="(confirmationForm.controls.organizationNumber.invalid && confirmationForm.controls.organizationNumber.touched) || confirmationForm.controls.organizationNumber.hasError('server')  ? 'error-confirmation-input' : 'confirmation-input'"
                 name="organizationNumber"
                 formControlName="organizationNumber"
                 placeholder="{{ 'CONFIRMATION.ORGANIZATION_NUMBER' | translate }}">
          <p
            *ngIf="confirmationForm.controls.organizationNumber.hasError('server') || confirmationForm.controls.organizationNumber.invalid"
            class="confirmation-error">{{ 'CONFIRMATION.ORGANIZATION_NUMBER_NOT_VALID' | translate }}</p>
        </div>

        <button (click)="(registerCompany && shouldCompanyBeRegistered) ? registerCompanyAndSign(null) : confirm()"
                class="sign-button" style="margin-top: 20px;"
                [disabled]='!confirmationForm.valid || actionExecuting'>{{ 'CONFIRMATION.CONFIRM' | translate }}
        </button>
      </div>
    </form>
  </div>
</ngx-smart-modal>

<ngx-smart-modal #insuranceInfoModal identifier="insuranceInfoModal" [customClass]="'basic-modal'"
                 [dismissable]="false">
  <img class="close-modal" src="assets/img/home/close_grey.svg" (click)="insuranceInfoModal.close()">
  <p class="title">{{ 'BOOK.INSURANCE_INFO' | translate }}</p>
  <p class="text"> {{ 'BOOK.INSURANCE_TEXT' | translate }}</p>
</ngx-smart-modal>
