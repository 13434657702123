import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './services/authentication.service';
import { ConfigService } from './services/config.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import {StorageUnitService} from './services/storage-unit.service';
import {IpService} from './services/ip.service';
import {BookingService} from './services/booking.service';
import {ObservableService} from './services/observable.service';
import {LanguageService} from './services/language.service';
import { CreditCheckService } from './services/credit-check.service';
import { AccountCardService } from './services/account-card.service';
import {ReactiveComponent} from './directives/reactive-component';
import { ExternalLinkService } from './services/external-link.service';

@NgModule({
  imports: [
    HttpClientModule
  ],
  declarations: [ReactiveComponent],
  providers: [
    // http services
    AuthenticationService,
    ConfigService,
    StorageUnitService,
    IpService,
    BookingService,
    ObservableService,
    LanguageService,
    CreditCheckService,
    AccountCardService,
    ExternalLinkService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ]
})
export class CoreModule { constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
  throwIfAlreadyLoaded(parentModule, 'CoreModule');
}
}
