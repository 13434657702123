export class LocationConfig {
    title: string;
    description: string;
    imagePath: string;
    cardLocation: {
        name: string,
        url: string
    };
}

export const HelsingborgConfig: LocationConfig = {
    title: 'HEADER.TEXT_HEADER1',
    description: 'HEADER.TEXT',
    imagePath: './assets/img/home/hyra-forrad-i-helsingborg.jpg',
    cardLocation: {
        name: 'Mörsaregatan 23 <br> 254 66 Helsingborg',
        url: 'https://www.google.com/maps/place/Sesam+Self+Storage+Helsingborg/@56.0812398,12.7192977,17z/data=!3m1!4b1!4m6!3m5!1s0x46523353b0164f31:0x677dbb42c979f2fb!8m2!3d56.0812398!4d12.7218726!16s%2Fg%2F11rv64g0cf?entry=ttu'
    }

};

export const LundConfig: LocationConfig = {
    title: 'HEADER.TEXT_HEADER_LUND',
    description: 'HEADER.TEXT_LUND',
    imagePath: './assets/img/home/hyra-forrad-i-lund-min.jpg',
    cardLocation: {
        name: 'Landerigränden 1 <br> 223 55 Lund',
        url: 'https://goo.gl/maps/4UAKiH9xxdcT1Jyq8'
    }
};

export enum Locations {
    LUND = 'Lund',
    HELSINGBORG = 'Helsingborg'
}
