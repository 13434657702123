<div class="extracted-units-wrap">
  <div *ngIf="loaded; else loading" class="extracted-units-wrap_boxes flex-column">
    <h2 *ngIf="showAllUnits && unitsBySizeGroups"
        class="extracted-units-wrap_booking-title">{{ 'SEARCH.HEADING' | translate }}</h2>
    <p *ngIf="showAllUnits && unitsBySizeGroups" class="extracted-units-wrap_booking-subtitle">
      {{ 'SEARCH.SUBTITLE1' | translate }}
      <a href="tel:042-448 80 00" style="color:#05868e">042-448 80 00</a>,
      {{ 'SEARCH.SUBTITLE2' | translate }}
    </p>
    <div *ngIf="unitsBySizeGroups; else noUnits" class="box-wrap row">
      <div class="extracted-units-wrap_wrap-all" *ngFor="let singleTypedUnits of unitsBySizeGroups">
        <div *ngIf="singleTypedUnits.typedUnits.length > 0 && showAllUnits" class="extracted-units-wrap_type-wrap">
          {{
          singleTypedUnits.type === 'small' ? ('COMMON.SMALL' | translate) :
            singleTypedUnits.type === 'medium' ? ('COMMON.MEDIUM' | translate) :
              singleTypedUnits.type === 'large' ? ('COMMON.LARGE' | translate)
              : 'COMMON.EXTERNAL' | translate
          }}
        </div>
        <div *ngIf="resizeEvent && !showAllUnits">
          <app-unit-slider [units]="allUnits" (redirectToBook)="redirectToBook($event)"></app-unit-slider>
        </div>
        <div *ngIf="!resizeEvent || showAllUnits" [ngClass]="showAllUnits ? 'box-wrapper-flex' : 'wp-box-wrapper-flex'">
            <app-unit *ngFor="let unit of showAllUnits ? singleTypedUnits.typedUnits : allUnits"
                      [unit]="unit"
                      [showAllUnits]="showAllUnits"
                      (redirectToBook)="redirectToBook($event)"
                      (collapseAllOtherUnits)="collapseAllOtherUnits()"
            ></app-unit>
        </div>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <app-loading [shouldDisableScreen]="true"></app-loading>
  </ng-template>

  <ng-template #noUnits>
    <div class="wrapper-no-units d-flex flex-column justify-content-center align-items-center w-100">
      <p class="no-units-title">{{ 'BOOK.NO_UNITS' | translate }}</p>
      <img class="no-units-picture" src="assets/img/home/steps_illustration.png">
    </div>
  </ng-template>
</div>
