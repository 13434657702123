<div class="search-wrap">
  <app-header [config]="currentLocationConfig"></app-header>

  <div id="unitList">
    <app-user-extracted-units
      [showAllUnits]="true"
      (isLoaded)="isLoaded = $event"
      (locationCity)="locationCity = $event"
      (locationAddress)="locationAddress = $event"
    ></app-user-extracted-units>
  </div>
<div *ngIf="isLoaded" class="boxes-wrapper row">
  <div class="row location-info">
    <div class="marketing-calculator col-lg-4 col-md-6 col-sm-12">
      <div class="marketing-wrap">
        <div class="marketing-video-wrap">
          <img loading="lazy" alt="footer-section-image" width="223" src="assets/img/home/footer-image-1.webp">
        </div>
        <div class="marketing-title">
          {{ 'SEARCH.HOW_IT_WORKS' | translate }}
        </div>
        <div class="button-wrapper d-flex align-self-center">
          <a class="apartment-calculator-button" href="https://sesamselfstorage.se/magasinering/">{{ 'SEARCH.QUESTIONS' | translate }}</a>
        </div>
      </div>
    </div>
    <div class="marketing-calculator col-lg-4 col-md-6 col-sm-12">
      <div class="marketing-wrap download-wrap">
        <div class="marketing-video-wrap">
          <img loading="lazy" alt="app-lock" width="170" src="assets/img/search/app-lock.png">
        </div>
        <div class="marketing-title">
          {{ 'SEARCH.DOWNLOAD' | translate }}
        </div>
        <div class="button-wrapper d-flex align-self-center">
          <a class="apartment-calculator-button" href="https://sesamselfstorage.se/ladda-ner-appen/">{{ 'SEARCH.DOWNLOAD' | translate }}</a>
        </div>
      </div>
    </div>
    <div class="marketing-calculator col-lg-4 col-md-6 col-sm-12">
      <div class="marketing-wrap">
        <div class="marketing-video-wrap">
          <img loading="lazy" alt="footer-image-2" width="717" height="500" src="assets/img/home/footer-image-2.webp">
        </div>
        <div class="marketing-title">
          {{ 'SEARCH.CALCULATE_BASED' | translate }}
        </div>
        <div>
          <a [href]="currentLocationConfig?.cardLocation.url"
             class="marketing-subtitle" target="_blank" [innerHTML]="currentLocationConfig?.cardLocation.name">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
