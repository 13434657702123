import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  loaded = false;
  constructor() {}

  ngOnInit() {
  }

  loadedIframe() {
    setTimeout(() => {
      this.loaded = true;
    }, 1000);
  }
}
