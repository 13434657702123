import {
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {concatMap, filter, startWith, tap} from 'rxjs/operators';
import get from 'lodash/get';
import {CountryISO, PhoneNumberFormat} from 'ngx-intl-tel-input';
import {AuthenticationService, CheckOrderStatusOptions, LoginOptions} from '../../core/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ReactiveComponent} from '../../core/directives/reactive-component';
import {BehaviorSubject, interval, Subject, Subscription} from 'rxjs';
import {SessionService} from '../../core/services/session.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends ReactiveComponent implements OnInit, OnChanges, OnDestroy {
  @Input() errorMessage = '';
  @Input() loading = false;
  @Input() modalOpened = false;
  @Input() loginFormClear = false;
  @Input() passwordRecoveryView = false;
  @Output() closeModal = new EventEmitter();

  password = '';
  qrCodeData = '';
  desktopView = true;
  signInOptionsView = false;
  windowsWidth: number;
  startPhase = true;
  email = '';
  phoneNumber = '';
  registerCompany = false;
  organizationNumber = '';
  confirmation = false;
  sesamAccountLogin = false;
  loginOptions: LoginOptions = null;
  actionExecuting = false;
  public personalNumberValidationValid = true;
  public shouldCompanyBeRegistered = false;
  public waitingToAuthBankId: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  city: string;
  companyName: string;
  CountryISO = CountryISO;
  onlyCountries = [
    CountryISO.Sweden,
    CountryISO.Norway,
    CountryISO.Denmark,
    CountryISO.Finland,
  ];
  PhoneNumberFormat = PhoneNumberFormat;

  form = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
    ]),
    emailConfirm: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
    ]),
    phoneNumber: new FormControl('', [
      Validators.required
    ]),
    phoneNumberConfirm: new FormControl('', [
      Validators.required
    ]),
    registerCompany: new FormControl('', []),
    organizationNumber: new FormControl('', [
      Validators.pattern(/^([0-9]{6}-[0-9]{4})$|^([0-9]{10})$/)
    ]),
    firstName: new FormControl('', [
      Validators.required
    ]),
    lastName: new FormControl('', [
      Validators.required
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,12}$/),
    ]),
    passwordVerify: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,12}$/),
    ]),
  }, {
    validators: [
      this.checkEmail,
      this.checkPhoneNumber,
      this.checkPassword
    ],
  });
  loginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });
  private hasFocus = new Subject<boolean>();
  private authStarted = false;
  private orderReference: any;
  public fieldTextType: boolean;
  public fieldTextType1: boolean;
  public fieldTextType2: boolean;
  private timeInterval: Subscription;
  private checkOrderResult: CheckOrderStatusOptions;
  loginFailed = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    // private ch: ChangeDetectorRef,
    private translateService: TranslateService,
    private toastrService: ToastrService,
  ) {
    super();
  }


  ngOnChanges(changes: SimpleChanges) {

    const modalOpenedChange = get(changes, 'modalOpened');
    const loginFormClearChange = get(changes, 'loginFormClear');

    if (modalOpenedChange) {
      if (modalOpenedChange.currentValue && modalOpenedChange.firstChange === false) {
        this.errorMessage = '';
        this.windowsWidth = window.innerWidth;
        this.desktopView = true;
        this.startPhase = true;
        this.signInOptionsView = false;
        this.qrCodeData = '';
        // this.personalNumber = '';
        this.personalNumberValidationValid = true;
        this.passwordRecoveryView = false;
        this.confirmation = false;
      } else {
        if (this.timeInterval) {
          this.timeInterval.unsubscribe();
        }
        this.waitingToAuthBankId.next(false);
        this.unsubscribe$.next();
      }
    }

    if (loginFormClearChange) {
      if (loginFormClearChange.currentValue && loginFormClearChange.firstChange === false) {
        this.clearConfirmationFrom();
        this.clearLoginForm();
        this.signInOptionsView = false;
        this.sesamAccountLogin = false;
      }
    }
  }

  ngOnInit() {
    this.form.get('registerCompany').valueChanges.subscribe(isRegisterCompany => {
      if (!isRegisterCompany) {
        this.organizationNumber = '';
        this.companyName = undefined;
      }
    });

    document.addEventListener('visibilitychange', () => {
      if (this.authStarted) {
        this.hasFocus.next(document.visibilityState === 'visible');
      }
    });

    this.hasFocus.pipe(filter(value => !!value)).subscribe(() => {
      this.checkAuthOnFocus();
    });
  }

  ngOnDestroy() {
    if (this.timeInterval) {
      this.timeInterval.unsubscribe();
    }
  }

  checkEmail(group: FormGroup) {
    const email = group.get('email').value;
    const emailConfirm = group.get('emailConfirm').value;

    if (email === '' || emailConfirm === '') {
      return null;
    }

    return email === emailConfirm
      ? null
      : {notSameEmail: true};
  }

  checkPassword(group: FormGroup) {
    const password = group.get('password').value;
    const passwordVerify = group.get('passwordVerify').value;

    if (password === '' || passwordVerify === '') {
      return null;
    }

    return password === passwordVerify
      ? null
      : {notSamePassword: true};
  }

  checkPhoneNumber(group: FormGroup) {
    const phoneNumber = group.get('phoneNumber').value;
    const phoneNumberConfirm = group.get('phoneNumberConfirm').value;

    const phoneNumberE164Format = get(phoneNumber, 'e164Number');
    const phoneNumberConfirmE164Format = get(phoneNumberConfirm, 'e164Number');

    if ((phoneNumber === '' && phoneNumberConfirm === '') || !phoneNumber && !phoneNumberConfirm) {
      return {notValid: true};
    }

    if (phoneNumberE164Format === phoneNumberConfirmE164Format) {
      return phoneNumberE164Format.length > 10 && phoneNumberE164Format.length < 14
        ? null
        : {notValidLength: true};
    } else {
      return {
        notSamePhoneNumber: true,
      };
    }
  }

  public loginWithQR(): void {
    this.removeBusinessValidators();
    this.startPhase = false;
    this.confirmation = false;
    this.shouldCompanyBeRegistered = false;
    this.startPhase = false;
    this.signInOptionsView = false;
    this.registerCompany = false;
    if (window.innerWidth > 720) {
      this.initBankIDAnimatedQRAuthentication();
    } else {
      this.desktopView = false;
      this.authenticateUserSmallDisplays();
    }
  }

  private removeBusinessValidators(): void {
    this.form.get('firstName').clearValidators();
    this.form.get('firstName').updateValueAndValidity();

    this.form.get('lastName').clearValidators();
    this.form.get('lastName').updateValueAndValidity();

    this.form.get('firstName').clearValidators();
    this.form.get('firstName').updateValueAndValidity();

    this.form.get('password').clearValidators();
    this.form.get('password').updateValueAndValidity();

    this.form.get('passwordVerify').clearValidators();
    this.form.get('passwordVerify').updateValueAndValidity();
  }

  private setBusinessValidators(): void {
    this.form.get('firstName').setValidators([Validators.required]);
    this.form.get('firstName').updateValueAndValidity();

    this.form.get('lastName').setValidators([Validators.required]);
    this.form.get('lastName').updateValueAndValidity();

    this.form.get('firstName').setValidators([Validators.required]);
    this.form.get('firstName').updateValueAndValidity();

    this.form.get('password').setValidators([Validators.required]);
    this.form.get('password').updateValueAndValidity();

    this.form.get('passwordVerify').setValidators([Validators.required]);
    this.form.get('passwordVerify').updateValueAndValidity();
  }

  signInSesamAccount() {
    this.startPhase = false;
    this.signInOptionsView = true;
    this.registerCompany = true;
    this.sesamAccountLogin = true;
  }

  startPhaseView() {
    this.startPhase = true;
    this.sesamAccountLogin = false;
    this.registerCompany = false;
    this.signInOptionsView = false;
  }

  loginSesamAccount() {
    this.actionExecuting = true;
    this.email = this.loginForm.value.email;
    this.password = this.loginForm.value.password;

    this.authService.createBusinessCustomerOrLogin({
      email: this.email,
      password: this.password,
      isNewUser: false
    }).subscribe(user => {
        this.actionExecuting = false;
        this.authService.tokenSideAffects(user.token);
        localStorage.setItem('_expiredTime', (Date.now() + SessionService.sessionExpiration).toString());
        this.router.navigate(['/user/account']);
      }, error => {
        this.actionExecuting = false;
        this.handleErrorMessage(error.error[0]);
      }
    );
  }

  public openRegistrationDialog(): void {
    this.setBusinessValidators();
    this.confirmation = true;
    this.shouldCompanyBeRegistered = true;
    this.startPhase = false;
    this.registerCompany = true;
    this.form.controls['registerCompany'].disable();
  }

  public closeRegistrationDialog(): void {
    this.confirmation = false;
    this.shouldCompanyBeRegistered = false;
    this.startPhase = true;
    this.sesamAccountLogin = true;
    this.registerCompany = false;
    this.sesamAccountLogin = false;

    this.form.controls['registerCompany'].enable();
  }

  public initBankIDAnimatedQRAuthentication() {
    this.authService.initiateAuthWithAnimatedQrCode().subscribe(response => {
      this.startCheckOrderPooling(response);
    }, (error) => {
      this.toastrService.error(error, 'Error');
    });
  }

  private startCheckOrderPooling(options: CheckOrderStatusOptions) {
    this.checkOrderResult = options;
    this.loginFailed = false;
    this.timeInterval = interval(1000).pipe(startWith(0), concatMap(() => this.authService.checkOrderStatus(this.checkOrderResult)),
      tap((res) => (this.loginOptions = res.user)))
      .subscribe((result) => {
        this.checkOrderResult = result;
        this.qrCodeData = result.qrCode;
        if (result.status === 'failed') {
          this.loginFailed = true;
          this.timeInterval.unsubscribe();
          return;
        }

        if (result.status === 'complete') {
          this.loginFailed = true;
          this.timeInterval.unsubscribe();
          if (result.user.isNewUser) {
            this.confirmation = true;
            this.shouldCompanyBeRegistered = false;
          } else {
            this.closeModal.emit();
            this.finishLogin();
          }
          return;
        }
      }, (error) => {
        this.timeInterval.unsubscribe();
        this.toastrService.error(error, 'Error');
      });
  }

  finishLogin() {
    this.loginOptions.city = this.city;
    this.loginOptions.companyName = this.companyName;
    this.loginOptions.organizationNumber = this.organizationNumber;

    if (this.sesamAccountLogin) {
      this.loginOptions.firstName = null;
      this.loginOptions.lastName = null;
      this.loginOptions.email = this.email;
      this.loginOptions.isBusinessUser = true;
      this.loginOptions.password = this.password;
      this.loginOptions.personalNumber = this.organizationNumber;
    }

    this.login();
  }

  public closeLoginModal() {
    this.closeModal.emit();
  }

  private login(): void {
    this.actionExecuting = true;
    this.authService.login(this.loginOptions).subscribe(() => {
      this.actionExecuting = false;
      this.closeModal.emit();
      this.clearConfirmationFrom();
      this.clearLoginForm();
      this.authStarted = false;
      localStorage.setItem('_expiredTime', (Date.now() + SessionService.sessionExpiration).toString());
      // Regular router.navigate does not navigate to user/account page on first time for some reason
      // This hack force navigation :D
      this.router.navigate([''])
        .then(() => this.router.navigate(['/user']))
        .then(() => this.router.navigate(['/user/account']));
    }, (errorResponse) => {
      this.actionExecuting = false;
      if (this.confirmation) {
        const errorMessage = errorResponse.error[0] || errorResponse.error.ErrorMessage;
        this.handleErrorMessage(errorMessage);
      }
    });
  }

  async confirmAuthentication() {
    this.loginOptions.email = this.form.value.email;
    this.loginOptions.phoneNumber = this.form.value.phoneNumber.e164Number;

    if (this.form.valid) {
      this.finishLogin();
    }
  }

  public registerBusinessUser() {
    this.loginOptions = {
      isNewUser: true,
      personalNumber: this.form.value.organizationNumber,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      email: this.form.value.email,
      phoneNumber: this.form.value.phoneNumber,
      password: this.form.value.password,
      isBusinessUser: true,
      organizationNumber: this.form.value.organizationNumber,
      city: '',
      companyName: ''
    };
    this.loginOptions.email = this.form.value.email;
    this.loginOptions.phoneNumber = this.form.value.phoneNumber.e164Number;
    this.organizationNumber = this.form.value.organizationNumber;

    if (this.form.valid) {
      this.actionExecuting = true;
      this.authService.createBusinessCustomerOrLogin(this.loginOptions)
        .subscribe(res => {
            this.actionExecuting = false;
            if (res) {
              this.authService.tokenSideAffects(res.token);
              localStorage.setItem('_expiredTime', (Date.now() + SessionService.sessionExpiration).toString());
              this.router.navigate(['/user/account']);
            }
          }, error => {
            this.actionExecuting = false;
            this.handleErrorMessage(error.error[0]);
          }
        );
    }
  }

  checkAuthOnFocus() {
    this.authService.bankId({
      orderReference: this.orderReference,
      polling: false,
    }).subscribe(loginOptions => {
        this.loginOptions = loginOptions;
        this.authStarted = false;
        this.waitingToAuthBankId.next(false);
        if (loginOptions.isNewUser) {
          this.confirmation = true;
        } else {
          this.finishLogin();
        }
      }, (error: HttpErrorResponse) => {
        if (error.status === 500) {
          this.toastrService.error(this.translateService.instant(error.error.ErrorMessage === 'USER_FAILED_AUTH' ? 'ACCOUNT.USER_FAILED_AUTH' : 'COMMON.SOMETHING_WENT_WRONG'));
          this.authStarted = false;
          this.waitingToAuthBankId.next(false);
          this.closeModal.emit();
        }
      }
    );
  }

  authenticateUserSmallDisplays() {
    this.authStarted = true;
    this.waitingToAuthBankId.next(true);

    this.authService.autoAuth().subscribe(authData => {
      this.orderReference = authData.orderReference;
      const link: any = 'bankid:///?autostarttoken=' + authData.autostartToken + '&redirect=';
      window.location = link;
    }, () => {
      this.waitingToAuthBankId.next(false);
      this.authStarted = false;
      this.toastrService.error(this.translateService.instant('COMMON.SOMETHING_WENT_WRONG'));
    });
  }

  public setPasswordRecoveryPage(): void {
    this.passwordRecoveryView = true;
    this.loginForm.get('email').reset();
  }

  public passwordRecover(): void {
    this.loading = true;
    this.authService.passwordRecover(this.loginForm.value.email).subscribe(res => {
      if (res) {
        this.loading = false;
        this.closePasswordRecoverModal();
      }
    }, error => {
      this.loading = false;
      this.handleErrorMessage(error.error[0]);
    });
  }

  private clearConfirmationFrom() {
    this.form.reset();
    this.confirmation = false;
  }

  private clearLoginForm() {
    this.loginForm.reset();
  }

  private handleErrorMessage(errorMessage: string): void {
    switch (errorMessage) {
      case 'EMAIL_USED_IN_SYSTEM':
        this.form.get('email').setErrors({
          server: {message: errorMessage},
        });
        this.raiseError('COMMON.EMAIL_USED_IN_SYSTEM');
        break;
      case 'PERSONAL_NUMBER_USED_IN_SYSTEM':
        this.raiseError('COMMON.PERSONAL_NUMBER_USED_IN_SYSTEM');
        break;
      case 'USER_HAS_ACTIVE_PROCESS':
      case 'RESET_EMAIL_SENT':
        this.closePasswordRecoverModal();
        break;
      case 'NOT_SUPPORTED_FOR_PRIVATE_USER':
        this.raiseError('COMMON.NOT_SUPPORTED_FOR_PRIVATE_USER');
        break;
      case 'INVALID_USERNAME_OR_PASSWORD':
        this.raiseError('COMMON.USER_OR_PASSWORD_WRONG');
        break;
      case 'USER_DOESNT_EXIST_IN_THE_SYSTEM':
        this.raiseError('COMMON.USER_OR_PASSWORD_WRONG');
        break;
      case 'ORG_NUMBER_USED_IN_SYSTEM':
        this.raiseError('COMMON.ORG_NUMBER_USED_IN_SYSTEM');
        break;
      case 'PHONE_NUMBER_USED_IN_SYSTEM':
        this.form.get('phoneNumber').setErrors({
          server: {message: errorMessage},
        });
        this.raiseError('COMMON.PHONE_NUMBER_USED_IN_SYSTEM');
        break;
      case 'PASSWORD_IS_NOT_IN_VALID_FORMAT':
        this.form.get('password').setErrors({
          server: {message: errorMessage},
        });
        this.form.get('passwordVerify').setErrors({
          server: {message: errorMessage},
        });
        this.raiseError('COMMON.PASSWORD_IS_NOT_IN_VALID_FORMAT');
        break;

        default:
        this.raiseError('COMMON.SOMETHING_WENT_WRONG');
        break;
    }
  }

  private raiseError(messageKey: string) {
    this.toastrService.error(this.translateService.instant(messageKey), '', {timeOut: 10000});
  }

  private closePasswordRecoverModal(): void {
    this.passwordRecoveryView = false;
    this.closeModal.emit();
    this.clearConfirmationFrom();
    this.clearLoginForm();
    this.toastrService.info(this.translateService.instant('COMMON.RESET_EMAIL_SENT'), '', {timeOut: 10000});
  }
}
