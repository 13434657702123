import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Router } from '@angular/router';
import { Locations } from 'src/app/core/models/locations.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() locations;
  @Input() currentBuilding;
  @Input() selectedPage;
  @Input() bookingProcess;
  @Output() toggleSidebar = new EventEmitter<boolean>();
  @Output() selectBuilding = new EventEmitter<any>();
  @Output() login = new EventEmitter();
  @Output() signOut = new EventEmitter();
  @Input() opened = false;
  eLocations = Locations;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    const path = this.router.url.toLowerCase();

    if (path.includes('lund')) {
      this.selectedPage = Locations.LUND;
    } else if (path.includes('helsingborg')) {
      this.selectedPage = Locations.HELSINGBORG;
    }
  }

  get loggedIn() {
    return this.authenticationService.isLoggedIn;
  }

  navigateTo(route: string) {
    const url = 'http://sesamselfstorage.se/';
    let extension = '';
    let reroutePage = true;
    switch (route) {
      case 'home':
        extension = 'home';
        break;
      case 'contact':
        extension = 'kontakt';
        break;
      case 'about':
        extension = 'om-oss';
        break;
      case 'how-it-works':
        extension = 'magasinering';
        break;
      case 'tips':
        extension = 'tips-och-rad';
        break;
      case 'companies':
        extension = 'foretag';
        break;
      case 'download':
        extension = 'har-laddar-du-ner-var-smarta-app-sesam-self-storage';
        break;
      case 'invoices': {
        this.selectedPage = 7;
        this.router.navigate(['user/invoices']);
        this.toggleSidebar.emit(false);
        reroutePage = false;
        break;
      }
      default: {
        this.selectedPage = 6;
        this.router.navigate(['user/account']);
        this.toggleSidebar.emit(false);
        reroutePage = false;
      }
    }
    if (reroutePage) {
      window.location.href = url.concat(extension);
    }
  }

  emitSelectBuilding(location: string): void {
    const locationToEmit = this.locations.find(
      (l) => l.locationName === location
    );
    this.selectedPage = location;
    this.selectBuilding.emit(locationToEmit);
  }
}
