import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

import {AuthenticationService} from '../../../core/services/authentication.service';
import {Observable} from 'rxjs';
import {ObservableService} from '../../../core/services/observable.service';

@Component({
  selector: 'app-user-invoice',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  loaded = false;
  sliceCharacters: number;
  sliceCharactersName: number;
  currentIndex = 0;
  invoices: any;
  public currentlang$: Observable<string>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.getWidth();
  }

  constructor(private authenticationService: AuthenticationService, private router: Router,
              private activatedRoute: ActivatedRoute, private translateService: TranslateService,
              private toastrService: ToastrService, private observableService: ObservableService) {}

  ngOnInit() {
    this.currentlang$ = this.observableService.currentLanguage$;
    this.getWidth();
    this.getMyInfo();
  }

  getWidth() {
    if (window.innerWidth > 1300) {
      this.sliceCharacters = 30;
      this.sliceCharactersName = 30;
    } else if (window.innerWidth <= 1300 && window.innerWidth > 1200) {
      this.sliceCharacters = 25;
      this.sliceCharactersName = 25;
    } else if (window.innerWidth <= 1200 && window.innerWidth > 1100) {
      this.sliceCharacters = 25;
      this.sliceCharactersName = 25;
    } else if (window.innerWidth <= 1100 && window.innerWidth > 866) {
      this.sliceCharacters = 18;
      this.sliceCharactersName = 20;
    } else if (window.innerWidth <= 866 && window.innerWidth > 766) {
      this.sliceCharacters = 18;
      this.sliceCharactersName = 16;
    } else if (window.innerWidth <= 860 && window.innerWidth >= 600) {
      this.sliceCharacters = 20;
      this.sliceCharactersName = 12;
    } else if (window.innerWidth <= 629 && window.innerWidth > 400) {
      this.sliceCharacters = 25;
      this.sliceCharactersName = 25;
    } else {
      this.sliceCharacters = 40;
      this.sliceCharactersName = 40;
    }
  }

  getMyInfo() {
    this.loaded = false;
      this.authenticationService.getMyInvoices().subscribe(invoicesResponse => {
        this.invoices = getUniqueMonths(invoicesResponse.invoices).sort((a, b) => {
          return a.position - b.position;
        });
        this.invoices.forEach(i => {
          invoicesResponse.invoices.forEach(ir => {
            if (new Date(ir.invoiceDate).getMonth() === i.position) {
              i.invoices.push(ir);
            }
          });
        });
        this.invoices.forEach(i => {
          i.invoices = i.invoices.sort((a, b) => {
            return new Date(b.invoiceDate).getTime() - new Date(a.invoiceDate).getTime();
          });
        });
        this.loaded = true;
      }, err => {
        // tslint:disable-next-line:max-line-length
          this.toastrService.error(this.translateService.instant('COMMON.ERROR_MESSAGE'), this.translateService.instant('COMMON.ERROR'), {timeOut: 10000});
          setTimeout(() => {
            return this.router.navigate(['/hyra-forrad'], {relativeTo: this.activatedRoute});
          }, 3000);
      });
  }

  setCurrentIndex(index: number) {
    if (this.currentIndex !== index) {
      this.currentIndex = index;
    } else {
      this.currentIndex = null;
    }
  }
}

function getUniqueMonths(exps) {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const uniqueMonths = new Set();
  exps.forEach((invoice) => {
    const month = new Date(invoice.invoiceDate).getMonth();
    uniqueMonths.add(monthNames[month]);
  });
  const months = Array.from(uniqueMonths);
  const distinctMonths = [];
  months.forEach(m => {
    distinctMonths.push({
      name: m,
      position: monthNames.indexOf(m.toString()),
      invoices: []
    });
  });
  return distinctMonths;
}
