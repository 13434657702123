import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AdminLayoutRoutes } from './user-router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {MainComponent} from '../main/main.component';
import {UserComponent} from './user.component';
import {MainModule} from '../main/main.module';
import {ProfileComponent} from './pages/profile/profile.component';
import {RequestsComponent} from './pages/requests/requests.component';
import {
  ErrorStateMatcher,
  MatDatepickerModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatOptionModule,
  MatSelectModule, MatTooltipModule, ShowOnDirtyErrorStateMatcher
} from '@angular/material';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {AccountComponent} from './pages/account/account.component';
import {SearchComponent} from './pages/search/search.component';
import {ContactComponent} from './pages/contact/contact.component';
import {CalculatorComponent} from './pages/calculator/calculator.component';
import {RentComponent} from './pages/rent/rent.component';
import {VerificationComponent} from './pages/verification/verification.component';
import {CompanyVerificationComponent} from './pages/company-verification/company-verification.component';
import {InvoicePaymentComponent} from './pages/invoice-payment/invoice-payment.component';
import {QRCodeModule} from 'angularx-qrcode';
import {TranslateModule} from '@ngx-translate/core';
import {NgxMaskModule} from 'ngx-mask';
import {InvoicesComponent} from './pages/invoices/invoices.component';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import {ExtractedUnitsComponent} from './pages/extracted-units/extracted-units.component';
import {MatDividerModule} from '@angular/material/divider';
import {SharedModule} from '../shared/shared.module';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {PasswordChangeComponent} from './pages/password-change/password-change.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {PriceCalculationComponent} from './pages/price-calculation/price-calculation.component';
import {UnitSliderComponent} from './pages/unit-slider/unit-slider';
import {UnitComponent} from './pages/unit/unit';
import { PersonalInformationChangeComponent } from './pages/personal-information-change/personal-information-change.component';

@NgModule({
    imports: [
        CommonModule,
        MainModule,
        RouterModule.forChild(AdminLayoutRoutes),
        FormsModule,
        HttpClientModule,
        NgbModule,
        MatSelectModule,
        MatOptionModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        NgxDaterangepickerMd.forRoot(),
        NgxSmartModalModule.forRoot(),
        NgxIntlTelInputModule,
        MatIconModule,
        QRCodeModule,
        TranslateModule,
        NgxMaskModule.forRoot(),
        ReactiveFormsModule,
        MatTooltipModule,
        MatDividerModule,
        SharedModule,
        MatExpansionModule
    ],
  declarations: [
    UserComponent,
    DashboardComponent,
    ProfileComponent,
    RequestsComponent,
    MainComponent,
    AccountComponent,
    InvoicesComponent,
    SearchComponent,
    ExtractedUnitsComponent,
    UnitSliderComponent,
    UnitComponent,
    PriceCalculationComponent,
    ContactComponent,
    RentComponent,
    CalculatorComponent,
    VerificationComponent,
    CompanyVerificationComponent,
    InvoicePaymentComponent,
    PasswordChangeComponent,
    ResetPasswordComponent,
    PersonalInformationChangeComponent
  ],
  exports: [
    UserComponent,
    DashboardComponent,
    ProfileComponent,
    RequestsComponent,
    MainComponent,
    AccountComponent,
    InvoicesComponent,
    SearchComponent,
    ExtractedUnitsComponent,
    UnitSliderComponent,
    UnitComponent,
    PriceCalculationComponent,
    ContactComponent,
    RentComponent,
    CalculatorComponent,
    VerificationComponent,
    ResetPasswordComponent
  ],
  providers: [
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}]
})
export class UserModule {}
