import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {NgxSmartModalComponent} from 'ngx-smart-modal';

@Component({
  selector: 'app-user-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('contactSupportModal', null) contactSupportModal: NgxSmartModalComponent;
  customerEmail: string;
  customerMessage: string;
  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {}

  contactSupport() {
    this.authenticationService.contactSupport(this.customerEmail, this.customerMessage).subscribe(() => {
      this.contactSupportModal.open();
      this.customerEmail = null;
      this.customerMessage = null;
    }, () => {
      this.contactSupportModal.open();
      this.customerEmail = null;
      this.customerMessage = null;
    });
  }
}
