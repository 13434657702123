import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from './core/services/authentication.service';

@Injectable()
export class UserGuard implements CanActivate {

  constructor(private authService: AuthenticationService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    } else {
      const currentUrl = state.url.slice(6);
      if (currentUrl === 'account') {
        this.router.navigate(['hyra-forrad']);
        return false;
      } else {
        this.router.navigate([currentUrl]);
        return true;
      }
    }
  }
}
