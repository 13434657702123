import { HostListener, OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "./core/services/language.service";
import { AuthenticationService } from "./core/services/authentication.service";
import { SessionService } from "./core/services/session.service";
import { TagManagerService } from "./core/services/tag-manager.service";
import { ExternalLinkService } from "./core/services/external-link.service";
import { AnalyticsService } from "./core/services/analytics.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  constructor(
    private languageService: LanguageService,
    private authService: AuthenticationService,
    private analytics: AnalyticsService,
    private router: Router,
    private toastrService: ToastrService,
    private tagManagerService: TagManagerService,
    private externalLinkService: ExternalLinkService,
    private translateService: TranslateService
  ) {
    languageService.setDefaultLanguage();
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.analytics.sendPageViewEvent(item.url);
      }
    });

    this.injectOneTrustScript();
  }

  ngOnInit() {
    this.externalLinkService.getExternalLinks();
  }

  @HostListener("click") onClick() {
    if (this.authService.isLoggedIn) {
      const expired = parseInt(localStorage.getItem("_expiredTime"), 10);
      if (expired < Date.now()) {
        this.authService.logout();
        this.toastrService.info(
          this.translateService.instant("COMMON.LOGGED_OUT")
        );
        return this.router.navigate(["hyra-forrad"]);
      } else {
        localStorage.setItem(
          "_expiredTime",
          (Date.now() + SessionService.sessionExpiration).toString()
        );
      }
    }
  }

  private injectOneTrustScript(): void {
    if (!window.location.href.includes("extracted-units")) {
      const oneTrustScript1 = document.createElement("script");
      oneTrustScript1.src =
        "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
      oneTrustScript1.setAttribute("data-document-language", "true");
      oneTrustScript1.setAttribute("type", "text/javascript");
      oneTrustScript1.setAttribute("charset", "UTF-8");
      oneTrustScript1.setAttribute(
        "data-domain-script",
        "0ab602ec-173a-4ae3-bde6-c2c8bff953bc"
      );

      const head = document.head;
      head.appendChild(oneTrustScript1);
    }
  }
}
