<div class="login-wrapper">
  <form *ngIf="!signOptions" class="login-form">
    <img src="assets/img/bank_id_logo.webp">
    <p *ngIf="!loginFailed" class="scan-title">{{ 'COMMON.BANK_ID_VERIFICATION' | translate }}</p>
    <p *ngIf="desktopView && !loginFailed" class="scan-text">{{ 'HEADER.SCAN_CODE' | translate }}</p>
    <p *ngIf="desktopView && loginFailed" class="scan-text">{{ 'HEADER.SCAN_FAILED' | translate }}</p>
    <div id="emir" *ngIf="desktopView && qrCodeData; else mobileView" class="qr-code-wrapper">
      <qrcode [qrdata]="qrCodeData" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
    <div *ngIf="desktopView && loginFailed">
      <button (click)="initBankIDAnimatedQRAuthentication()" style="background-color:#235971; margin: 5px; color: white"
              class="ot-bnr-save-handler">{{ 'BOOK.TRY_AGAIN' | translate }}
      </button>
      <button (click)="closeLoginModal()" style="background-color:white; margin: 5px; color: #235971"
              class="ot-bnr-save-handler">{{ 'COMMON.CANCEL' | translate }}
      </button>
    </div>
  </form>
  <form *ngIf="signOptions && !authenticationSpinner" class="login-form" [formGroup]="loginForm" #f="ngForm">
    <img *ngIf="sesamAccountLogin" src="assets/img/home/sesam-logo.svg" alt="sesam logo">
    <img *ngIf="!sesamAccountLogin" src="assets/img/bank_id_logo.webp" alt="bank id logo">
    <div class="sign-options-wrapper">
      <p
        class="options-title">{{ sesamAccountLogin ? ('HEADER.BUSINESS_CUSTOMER' | translate) : 'HEADER.BANKID_OTHER_DEVICES' | translate }}</p>
      <div *ngIf="!sesamAccountLogin; else accountLogin">
      </div>

      <ng-template #accountLogin>
        <div class="input-field">
          <p class="confirmation-title" style="margin-top: 20px;">{{ 'CONFIRMATION.EMAIL' | translate }}</p>
          <input
            type="text"
            [class]="(loginForm.controls.email.invalid && loginForm.controls.email.touched) || loginForm.hasError('notSameEmail') || loginForm.controls.email.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
            [class.input-field-disabled]="!!loggedUser"
            name="email"
            [disabled]="!!loggedUser"
            formControlName="email"
            [placeholder]="'COMMON.EMAIL_PLACEHOLDER' | translate"
          >
        </div>

        <div class="input-field">
          <p class="confirmation-title">{{ 'COMMON.PASSWORD' | translate }}:</p>
          <div class="d-flex">
            <input
              [type]="fieldTextType ? 'text' : 'password'"
              [class]="(loginForm.controls.password.invalid && loginForm.controls.password.touched) || loginForm.controls.password.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
              name="password"
              formControlName="password"
              [placeholder]="'COMMON.PASSWORD' | translate"
            >
            <div (click)="fieldTextType = !fieldTextType" class="input-group-append show-password-icon">
                <span class="input-group-text">
                  <i class="fa fa-eye-slash"></i>
                </span>
            </div>
          </div>
          <p
            *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched && !loginForm.controls.password.hasError('server')"
            class="confirmation-error">{{ 'BOOK.INVALID_CHARACTER_NUMBER' | translate }} <strong>8</strong>.</p>
          <p *ngIf="loginForm.controls.email.hasError('server') && loginForm.controls.password.hasError('server')"
             class="confirmation-error">{{ 'COMMON.USER_WRONG_PASSWORD' | translate }}</p>
        </div>

        <button (click)="loginSesamAccount()" [disabled]='!loginForm.valid' class="sign-button">
          {{ 'BOOK.SIGN' | translate }}
        </button>
        <div *ngIf="!loggedUser" (click)="openRegistrationForm.emit()" class="forgot-password align-self-center">
          <span>
            {{ 'COMMON.NEW_ACCOUNT_LOGIN' | translate }}
          </span>
          <span class="font-bold">
            {{ 'COMMON.NEW_ACCOUNT' | translate }}
          </span>
        </div>
      </ng-template>
    </div>
  </form>
</div>

<div *ngIf="authenticationSpinner">
  <app-loading [text]="'HEADER.BANK_ID_INFO'"></app-loading>
</div>

<ng-template #mobileView>
  <p *ngIf="!desktopView && !loginFailed" class="scan-text mobile">{{ 'HEADER.SCAN_CODE_MOBILE' | translate }}</p>
</ng-template>


