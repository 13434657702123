import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import {MainModule} from './main/main.module';
import {UserGuard} from './user.guard';
import {CoreModule} from './core/core.module';
import {ConfigService} from './core/services/config.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
    ErrorStateMatcher,
    MatAutocompleteModule, MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatSliderModule,
    MAT_DATE_LOCALE,
    ShowOnDirtyErrorStateMatcher
} from '@angular/material';
import {NgxSmartModalModule, NgxSmartModalService} from 'ngx-smart-modal';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import {LoggedInGuard} from './logged-in.guard';
import {NotFoundComponent} from './home/notFound.component';
import {HomeComponent} from './home/home.component';
import {UserModule} from './user/user.module';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {StorageCalculatorComponent} from './user/pages/storage-calculator/storage-calculator.component';
import {SharedModule} from './shared/shared.module';

import {WordpressLoginGuard} from './wordpress-login.guard';
import {registerLocaleData} from '@angular/common';
import localeSv from '@angular/common/locales/sv';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

export function TranslationLoader(translationService: TranslateService) {
  return () => translationService.use(translationService.currentLang).toPromise();
}
@NgModule({
  declarations: [
    HomeComponent,
    AppComponent,
    StorageCalculatorComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    NgbModule,
    AppRoutingModule,
    RouterModule,
    MainModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatSliderModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    UserModule,
    SharedModule,
    FlexLayoutModule.withConfig({addFlexToParent: false}),
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    ToastrModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    NgxIntlTelInputModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    TranslateService,
    NgxSmartModalService,
    {provide: APP_INITIALIZER, useFactory: TranslationLoader, deps: [TranslateService], multi: true},
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}, UserGuard, LoggedInGuard, WordpressLoginGuard, ConfigService,
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {provide: LOCALE_ID, useValue: 'sv-SE'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeSv, 'sv');
  }
}
