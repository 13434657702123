<div class="password-change">
  <img class="password-change_image" src="assets/img/account/password-change.svg">
  <p class="password-change_title">{{'ACCOUNT.CREATE_PASSWORD' | translate}}</p>
  <form class="password-change_form" [formGroup]="changePasswordForm">
    <div class="password-change_input-wrap">
      <span *ngIf="!recoverPassword"
            class="password-change_input-text">{{'ACCOUNT.CURRENT_PASSWORD_TEXT' | translate}}</span>
      <input
        *ngIf="!recoverPassword"
        type="password"
        name="password"
        [class]="(changePasswordForm.controls.oldPassword.invalid && changePasswordForm.controls.oldPassword.touched) ? 'password-change_input-invalid' : 'password-change_input'"
        formControlName="oldPassword"
        [placeholder]="'ACCOUNT.OLD_PASSWORD' | translate"
      >
      <div *ngIf="changePasswordForm.hasError('wrongPassword')"
           class="password-change_input-invalid-text my-mat-hint">{{passwordError}}</div>
      <span class="password-change_input-text">{{'ACCOUNT.NEW_PASSWORD_TEXT' | translate}}</span>
      <input
        type="password"
        name="password"
        [class]="(changePasswordForm.controls.password.invalid && changePasswordForm.controls.password.touched) ? 'password-change_input-invalid' : 'password-change_input'"
        formControlName="password"
        [placeholder]="'COMMON.PASSWORD' | translate"
      >
      <span class="password-change_input-text">{{'ACCOUNT.RETYPE_NEW_PASSWORD' | translate}}</span>
      <input
        type="password"
        name="password"
        [class]="(changePasswordForm.controls.repeatPassword.invalid && changePasswordForm.controls.repeatPassword.touched || changePasswordForm.hasError('notSamePassword')) ? 'password-change_input-invalid' : 'password-change_input'"
        formControlName="repeatPassword"
        [placeholder]="'ACCOUNT.REPEAT_PASSWORD' | translate"
      >
      <div *ngIf="changePasswordForm.hasError('notSamePassword')"
           class="password-change_input-invalid-text my-mat-hint">
        {{'ACCOUNT.PASSWORD_NOT_SAME' | translate}}
      </div>
    </div>
    <button class="password-change_button" [disabled]="shouldBeDisabled()"
            (click)="changePassword()">{{'COMMON.SAVE' | translate}}</button>
  </form>
</div>
