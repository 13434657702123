<div class="app-wrapper">
  <div class="apartment-wrap">
    <div class="apartment-title">
      {{ 'SEARCH.CALCULATE_APARTMENT' | translate }}
    </div>
    <img src="assets/img/search/storage_calculator_logo.png">
    <div class="button-wrapper d-flex flex-column">
      <input *ngIf="!calculationView" [ngClass]="{'invalid-output' : inputInvalid}" type="text" [placeholder]="'SEARCH.ENTER_AP_SIZE' | translate"
             [(ngModel)]="apartmentSize" (keydown.enter)="calculateValue()">
      <p class="mat-error" *ngIf="inputInvalid"> {{'COMMON.INPUT_INVALID'| translate}}</p>
      <button *ngIf="!calculationView" class="apartment-calculator-button" (click)="calculateValue()"
              [disabled]="apartmentSize === ''">{{ 'SEARCH.CALCULATE' | translate }}</button>
      <p *ngIf="calculationView"
         class="calculation-text">{{ 'SEARCH.CALCULATION_RESPONSE' | translate }} <strong>{{ calculationValue }}m²</strong></p>
      <div class="buttons-back d-flex flex-row align-items-center justify-content-start">
        <button *ngIf="calculationView" class="apartment-book-button"
                (click)="goToBook()">{{ 'COMMON.BOOK_NOW' | translate }}</button>
        <button *ngIf="calculationView" class="apartment-calculator-button"
                (click)=" apartmentSize = ''; calculationView = false;">{{ 'COMMON.BACK' | translate }}</button>
      </div>
    </div>
  </div>
</div>
