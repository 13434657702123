<div class="book-wrap">
  <div *ngIf="loaded; else loading" class="account-unit row">
    <p class="full-title col-md-12">{{ 'ACCOUNT.PROFILE' | translate }}</p>
    <div class="col-md-4 col-sm-12 account-details-wrap">
      <div class="account-info col-md-12">
        <div *ngIf="user.companyName" class="account-box">
          <p class="label">{{ 'ACCOUNT.COMPANY_NAME' | translate }}</p>
          <p class="info">{{ user.companyName }}</p>
        </div>
        <div *ngIf="user.firstName && user.lastName && !user.isBusinessUser" class="account-box">
          <p class="label">{{ 'ACCOUNT.NAME' | translate }}</p>
          <p class="info">{{ user.firstName}} {{user.lastName}}</p>
        </div>
        <div *ngIf="user.email" class="account-box">
          <p class="label">{{ 'ACCOUNT.EMAIL' | translate }}</p>
          <p class="info" [matTooltip]="user.email" [matTooltipPosition]="'above'"
             [matTooltipDisabled]="user.email.length <= sliceCharacters">{{ truncateEmail(user.email) }}</p>
        </div>
        <div *ngIf="user.phoneNumber" class="account-box">
          <p class="label">{{ 'ACCOUNT.PHONE' | translate }}</p>
          <p class="info">{{ user.phoneNumber }}</p>
        </div>
        <div *ngIf="user.street" class="account-box">
          <p class="label">{{ 'ACCOUNT.STREET' | translate }}</p>
          <p class="info">{{ user.street }}</p>
        </div>
        <div *ngIf="user.zipCode" class="account-box">
          <p class="label">{{ 'ACCOUNT.ZIP_CODE' | translate }}</p>
          <p class="info">{{ user.zipCode }}</p>
        </div>
        <div *ngIf="user.city" class="account-box">
          <p class="label">{{ 'ACCOUNT.CITY' | translate }}</p>
          <p class="info">{{ user.city }}</p>
        </div>
        <div *ngIf="user.organizationNumber" class="account-box">
          <p class="label">{{ 'ACCOUNT.ORGANIZATION_NUMBER' | translate }}</p>
          <p class="info">{{ user.organizationNumber }}</p>
        </div>
        <div *ngIf="user.companyName" class="account-box password-box">
          <div class="flex-grow-1">
            <p class="label">{{ 'COMMON.PASSWORD' | translate }}</p>
            <p class="info">********</p>
          </div>
          <button class="password-box_button" (click)="passwordChangeModal.open(); passwordChangeModalOpened = true;">
            {{ 'ACCOUNT.CHANGE_PASSWORD' | translate }}
          </button>
        </div>
        <div class="account-box">
          <button (click)="openPersonalInfoChangeModal()">
            {{ 'ACCOUNT.UPDATE_PERSONAL_INFORMATION' | translate }}
          </button>
        </div>

      </div>
      <div *ngIf="cardDetails; else noCardDetails">
        <div class="account-info unit-info col-md-12" style="padding-top: 10px;">
          <p class="label">{{ 'ACCOUNT.PAYMENT_CARD' | translate }}</p>
          <div class="storage-all-wrap storage-name-wrapper d-flex align-items-center">
            <img src="assets/img/account/card.png" width="64px">
            <div class="storage-name-info d-flex flex-column ml10">
              <p class="storage-name">{{ 'ACCOUNT.CARD_ENDING' | translate : {cardNumber: cardNumber} }}</p>
              <p class="storage-name">{{ 'ACCOUNT.EXPIRES' | translate : {expiryDate: expiryDate} }}</p>
            </div>
          </div>
        </div>
        <div class="unit-info button-wrapper w-100 d-flex justify-content-end">
          <button class="change-card" (click)="changeCard();">
            {{ 'ACCOUNT.CHANGE_CARD' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="unit-info col-md-8 col-sm-12">
      <div *ngIf="units && units.length > 0; else noUnits" class="units-wrapper">
        <div [hidden]="detailedView" class="w-100 storage-all-wrap" *ngFor="let storage of units; let i = index;">
          <div *ngIf="storage.scheduledMoveOut !== null"
               class="move-out-label">{{ ('BOOK.STORAGE_USAGE' | translate) + ' ' + (storage.scheduledMoveOut | date: 'yyyy-MM-dd') }}</div>
          <div class="storage w-100 d-flex justify-content-between align-items-center" (click)="setCurrentStorage(i)">
            <div class="storage-name-wrapper d-flex align-items-center">
              <img src="assets/img/account/storage_unit.svg">
              <div class="storage-name-info d-flex flex-column ml10">
                <p *ngIf="storage && storage.storageNumber"
                   class="storage-name orange">{{ 'ACCOUNT.STORAGE_NUMBER' | translate }}:
                  <strong>{{  storage.storageNumber }}</strong></p>
                <p class="storage-name">{{ storage.location.name }}</p>
                <p *ngIf="storage?.unitName"
                   class="storage-name">{{storage.unitName}}</p>
                <p
                  class="storage-other-info">{{ inFuture(storage.bookDate) ? ('ACCOUNT.RENTED_FUTURE' | translate) : ('ACCOUNT.RENTED' | translate) }} {{ storage.bookDate | date: 'yyyy-MM-dd' }}</p>
              </div>
            </div>
            <div class="storage-info d-flex flex-column">
              <p
                class="storage-size">{{ storage.unitTypeName === 'Locker' ? storage.size + ' &#13221; Box' : storage.size + ' &#13217;' }}</p>
              <p class="storage-other-info">{{ storage.netAmount | number : '1.0-0' | noComma }}
                kr/{{ 'SEARCH.MONTH' | translate }}</p>
              <p
                class="storage-other-info">{{ 'ACCOUNT.INSURED' | translate }} {{ storage.insurance.coverage | number : '1.0-0' | noComma }}
                kr</p>
            </div>
          </div>
          <div class="button-wrapper w-100 d-flex justify-content-end">
            <button [disabled]="storage.scheduledMoveOut !== null" class="cancel-subscription"
                    (click)="cancelModal.open(); setCancellationStorage(i)">
              {{ 'ACCOUNT.CANCEL_STORAGE' | translate }}
            </button>
          </div>
        </div>
        <div class="w-100 d-flex flex-column" *ngIf="detailedView">
          <div class="back-wrap d-flex align-items-center" (click)="resetCurrentStorage()">
            <img src="assets/img/account/arrow_back.png">
            <p class="go-back">{{ 'ACCOUNT.BACK_OVERVIEW' | translate }}</p>
          </div>
          <div class="w-100 detailed-view row">
            <div class="detailed-info col-md-7 col-sm-12 d-flex flex-column">
              <div class="storage-detailed-info" *ngIf="!editMode">
                <i class="fa fa-pencil" (click)="editMode = true;"></i>
                <p *ngIf="currentStorage.unitName"
                   class="storage-name">{{ currentStorage.unitName }}</p>
                <p
                  class="storage-other-info">{{ inFuture(currentStorage.bookDate) ? ('ACCOUNT.RENTED_FUTURE' | translate) : ('ACCOUNT.RENTED' | translate) }} {{ currentStorage.bookDate | date: "d LLLL yyyy"  | lowercase | translate}}</p>
              </div>
              <div class="storage-edit-info" *ngIf="editMode">
                <div class="input-box">
                  <p class="label-text">{{ 'ACCOUNT.STORAGE_NAME_DEFAULT' | translate }} #12345)</p>
                  <input type="text" [(ngModel)]="currentName" maxlength="40">
                </div>
                <div class="buttons w-100 d-flex align-items-center justify-content-end">
                  <button class="cancel" (click)="cancel()">{{ 'COMMON.CANCEL' | translate }}</button>
                  <button class="save" (click)="save()">{{ 'COMMON.SAVE' | translate }}</button>
                </div>
              </div>
            </div>
            <div class="detailed-box col-md-5 col-sm-12 d-flex flex-column">
              <div class="box-wrapper">
                <div class="box d-flex flex-column">
                  <img [src]="currentStorage.imageUrl || 'assets/img/book/image_1.png'">
                  <p
                    class="title">{{ currentStorage.unitTypeName === 'Locker' ? currentStorage.size + ' &#13221; Box' : currentStorage.size + ' &#13217;' }}</p>
                  <p class="text">{{ 'ACCOUNT.RENTAL' | translate }}
                    : {{ (currentStorage.netAmount - currentStorage.insurance.monthlyPrice) | number : '1.0-0' | noComma}}
                    kr/{{ 'SEARCH.MONTH' | translate }}</p>
                  <p class="text">{{ 'ACCOUNT.INSURANCE_LEVEL' | translate }}:
                    {{currentStorage.insurance.monthlyPrice}} kr/{{ 'SEARCH.MONTH' | translate }}
                    <br> {{ currentStorage.insurance.planName }}</p>
                  <p class="text">{{ 'ACCOUNT.TOTAL' | translate }}
                    : {{currentStorage.netAmount | number : '1.0-0' | noComma}}
                    kr/{{ 'SEARCH.MONTH' | translate }} {{ 'ACCOUNT.TAX_INCLUDED' | translate | lowercase }}</p>
                </div>
                <div class="button-wrapper w-100 d-flex justify-content-end">
                  <button class="cancel-subscription full" [disabled]="currentStorage.scheduledMoveOut !== null"
                          (click)="cancelModal.open(); setCancellationStorage(currentIndex)">
                    {{ 'ACCOUNT.CANCEL_STORAGE' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ngx-smart-modal #cancelModal identifier="cancelModal" [customClass]="'basic-modal'" [dismissable]="false">
  <div *ngIf="cancelStorage" class="close-modal-wrapper d-flex flex-column">
    <img src="assets/img/account/cancel_subscription.png">
    <p class="title-cancel">{{ 'ACCOUNT.CANCEL_STORAGE' | translate }}</p>
    <p class="subtitle-cancel">{{ 'ACCOUNT.STORAGE_ID' | translate }} {{cancelStorage.storageNumber }}.</p>
    <p class="text-cancel">{{ 'ACCOUNT.VALID_INFO' | translate:{
      dateDay: cancelStorage.dateMonth | date:'d',
      dateMonth: getMonth(cancelStorage.dateMonth | date:'LLLL') | lowercase,
      dateYear: cancelStorage.dateMonth | date:'yyyy'
    } }}</p>
    <br/>
    <p
      class="text-cancel warning">{{ ('ACCOUNT.CHARGED_UNTIL' | translate : {
      dateDay: cancelStorage.dateMonth | date:'d',
      dateMonth: getMonth(cancelStorage.dateMonth | date:'LLLL') | lowercase,
      dateYear: cancelStorage.dateMonth | date:'yyyy'
    })}} {{ 'ACCOUNT.EMAIL_SENT' | translate }}</p>
    <!-- <p class="text-cancel warning">{{ 'ACCOUNT.EMAIL_SENT' | translate }}</p> -->
    <br/>
    <p class="text-cancel">{{ 'ACCOUNT.SURE' | translate }}</p>
    <button class="continue-button"
            (click)="moveOut(); cancelModal.close();">{{ 'COMMON.CONTINUE' | translate }}</button>
    <button class="cancel-button" (click)="cancelModal.close();">{{ 'COMMON.CANCEL' | translate }}</button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal #cardChangeModal identifier="cardChangeModal" [customClass]="'basic-modal'" [dismissable]="false">
  <img class="close-modal" src="assets/img/home/close_grey.svg" (click)="cardChangeModal.close()">
  <app-loading *ngIf="paymentLoading"></app-loading>
  <p *ngIf="!paymentLoading" class="payment-card-information">{{ 'ACCOUNT.PAYMENT_CARD_INFROMATION' | translate }}</p>
  <div *ngIf="!paymentLoading" class="payment-cards d-flex justify-content-around align-items-center">
    <img src="assets/img/book/visa.png">
    <img src="assets/img/book/master.png">
  </div>
  <div *ngIf="!paymentLoading" class="iframe-wrapper">
    <iframe
      #iframe
      style="border: none;"
      scrolling="no"
      width="100%"
      height="370"
      [src]="paymentFormLink | safe"
      (load)="loadDone()"
    ></iframe>
  </div>
</ngx-smart-modal>


<ng-template #noUnits>
  <div class="wrapper-no-units d-flex flex-column justify-content-center align-items-center w-100">
    <p class="no-units-title">{{ user.isBusinessUser ? ('ACCOUNT.NO_UNITS' | translate) : ('ACCOUNT.NO_UNITS_PRIVATE' | translate) }}</p>
    <img class="no-units-picture" src="assets/img/home/steps_illustration.png">
  </div>
</ng-template>

<ng-template #noCardDetails>
  <div class="account-info no-card-info" >
    <div class="account-box">
      <p class="info align-self-center">{{ user.isBusinessUser ? ('ACCOUNT.CARD_DETAILS_NOT_AVAILABLE' | translate) : ('ACCOUNT.CARD_NOT_REGISTERED' | translate) }}</p>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

<ngx-smart-modal #passwordChangeModal identifier="passwordChangeModal" [customClass]="'basic-modal'"
                 [dismissable]="false">
  <img class="close-modal" src="assets/img/home/close_grey.svg"
       (click)="passwordChangeModal.close(); passwordChangeModalOpened = false;">
  <app-password-change
    [modalOpened]="passwordChangeModalOpened"
    [recoverPassword]=false
    (passwordChangeFormConfirmed)="changePassword($event)"
  ></app-password-change>
</ngx-smart-modal>

<ngx-smart-modal #personalInfoChangeModal identifier="personalInfoChangeModal" [customClass]="'basic-modal'"
                 [dismissable]="false">
  <img class="close-modal" src="assets/img/home/close_grey.svg"
       (click)="closePersonalInfoChangeModal()">
  <app-personal-information-change #personalInfoChangeModalForm [personalInformation]="user"
                                   (changePersonalInformationFormConfirmed)="updatePersonalInformation($event)">

  </app-personal-information-change>
</ngx-smart-modal>
