<div class="box-wrapper" [class.wp-width]="!showAllUnits" [class.more]="unit.expanded">
  <div class="box d-flex flex-column" [class.more]="unit.expanded">
    <div class="box-image d-flex flex-column">
      <div class="box-image-wrapper">
        <img loading="lazy" [src]="unit.imageUrl || 'assets/img/book/image_1.png'" alt="unit image">
        <app-badge *ngIf="unit.discount"
                   [title]="unit.discount.discountType === 'MIX' ? unit.discount.firstMonthPrice + ' kr'
                   : (unit.discount.discountType === 'PERCENTAGE' ? unit.discount.value + '%' : unit.discount.amount + ' kr')"

                   [text]="(unit.discount.totalMonths === 1) ? ('BOOK.DISCOUNT_ONE_MONTH' | translate) :
                  unit.discount.discountType === 'MIX' ? ('BOOK.DISCOUNT_ONE_MONTH_MIXED' | translate)
                      : 'BOOK.DISCOUNT_RANGE_TWO_MONTHS' | translate : {nrOfMonths: unit.discount.totalMonths}"
                   [subtitle]="unit.discount.discountType === 'MIX' ? unit.discount.secondMonthPercentage + ' %' : null"
                   [subtext]="unit.discount.discountType === 'MIX' ? ('BOOK.FOLLOWING_MONTH' | translate) : null"
        ></app-badge>
      </div>
      <div class="box-title" [class.wp-box-title]="!showAllUnits">
        <div class="box-info">
          <p
            class="main">{{ unit.unitTypeName === 'Locker' ? unit.unitSize + ' &#13221; Box' : unit.unitSize + ' &#13217;' }}</p>
          <p *ngIf="unit.discount && unit.discount.discountType !== 'MIX'"
             class="small">{{unit.discount.price | number : '1.0-0' | noComma}}
            kr/{{'SEARCH.MONTH' | translate}}</p>
          <p *ngIf="unit.discount && unit.discount.discountType !== 'MIX'" class="small">
            <s>{{ unit.netAmount | number : '1.0-0' | noComma}}
              kr/{{ 'SEARCH.MONTH' | translate }}</s></p>
          <p *ngIf="!unit.discount || unit.discount.discountType === 'MIX'"
             class="small">{{ unit.netAmount | number : '1.0-0' | noComma}}
            kr/{{ 'SEARCH.MONTH' | translate }}</p>
        </div>
        <div *ngIf="unit.additionalDescription || unit.discount" class="more-menu">
          <div class="menu-items" (click)="expandCollapseUnit()">
            <p>{{ unit.expanded ? ('COMMON.LESS' | translate) : ('COMMON.MORE' | translate)}}</p>
            <img loading="lazy" [class.more]="unit.expanded" src="assets/img/search/more.png" alt="more">
          </div>
        </div>
      </div>
      <div *ngIf="unit.expanded" class="more-text">
        <ul class="more-text_list">
          <li *ngFor="let description of unit.additionalDescription" class="more-text_list-item">{{description}}</li>
        </ul>
      </div>
    </div>
    <button class="box-button" (click)="redirectToBook.emit(unit)">{{ 'COMMON.BOOK_NOW' | translate }}</button>
  </div>
</div>
