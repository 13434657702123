import {Component, ElementRef, HostListener, OnInit, ViewChild, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {filter, tap} from 'rxjs/operators';
import {NgxSmartModalComponent} from 'ngx-smart-modal';

import truncate from 'lodash/truncate';

import {BookingService} from '../../../core/services/booking.service';
import {AccountCardService} from '../../../core/services/account-card.service';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {CardDetails} from '../../models/card-details';
import {ObservableService} from '../../../core/services/observable.service';
import {Observable} from 'rxjs';
import {User} from '../../models/user';
import {UpdateUser} from '../../models/update-user';
import {PersonalInformationChangeComponent} from '../personal-information-change/personal-information-change.component';

@Component({
  selector: 'app-user-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  @ViewChild('cardChangeModal', null) cardChangeModal: NgxSmartModalComponent;
  @ViewChild('passwordChangeModal', null) passwordChangeModal: NgxSmartModalComponent;
  @ViewChild('personalInfoChangeModal', null) personalInfoChangeModal: NgxSmartModalComponent;
  @ViewChild('personalInfoChangeModalForm', null) personalInfoChangeModalForm: PersonalInformationChangeComponent;
  @ViewChild('iframe', {static: false}) iframe: ElementRef;

  userId: string;
  user: User;
  units: any;
  currentStorage: any;
  cancelStorage: { dateMonth: string, storageId: string, bookingId: number, total: number, keyUnitName: string, storageNumber: number };
  cardDetails: CardDetails;
  cardNumber: string;
  expiryDate: string;
  endDate: string;
  currentName: string;
  paymentFormLink: string;

  paymentLoading = true;
  detailedView = false;
  editMode = false;
  currentIndex: number;
  loaded = false;
  sliceCharacters: number;
  sliceCharactersName: number;
  todayTime = new Date().getTime();
  public passwordChangeModalOpened = false;
  public currentlang$: Observable<string>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.getWidth();
  }

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private bookingService: BookingService,
    private accountCardService: AccountCardService,
    private toastrService: ToastrService,
    private renderer: Renderer2,
    private observableService: ObservableService
  ) {
    this.renderer.listen('window', 'message', this.onMessage.bind(this));
  }

  ngOnInit() {
    this.currentlang$ = this.observableService.currentLanguage$;
    this.getWidth();
    this.getMyInfo(true);
  }

  onMessage(event): void {

    if ((event.origin.indexOf('localhost') > -1 || event.origin.indexOf('8storage') > -1 ||
      event.origin.indexOf('6storage') > -1) && !window.location.href.includes('book')) {
      const data = event.data;
      if (data.func === 'ReceiveIframeResponse' || data.paymentStatus) {
        const response = data.paymentStatus ? data : JSON.parse(data.message);

        if (response.paymentStatus === 'Success' || response.paymentStatus === 'Ready') {
          this.activateCard(response.id);
        }
      }
    }
  }

  getWidth() {
    if (window.innerWidth > 1300) {
      this.sliceCharacters = 30;
      this.sliceCharactersName = 30;
    } else if (window.innerWidth <= 1300 && window.innerWidth > 1200) {
      this.sliceCharacters = 25;
      this.sliceCharactersName = 25;
    } else if (window.innerWidth <= 1200 && window.innerWidth > 1100) {
      this.sliceCharacters = 25;
      this.sliceCharactersName = 25;
    } else if (window.innerWidth <= 1100 && window.innerWidth > 866) {
      this.sliceCharacters = 18;
      this.sliceCharactersName = 20;
    } else if (window.innerWidth <= 866 && window.innerWidth > 766) {
      this.sliceCharacters = 18;
      this.sliceCharactersName = 16;
    } else if (window.innerWidth <= 860 && window.innerWidth >= 600) {
      this.sliceCharacters = 20;
      this.sliceCharactersName = 12;
    } else if (window.innerWidth <= 629 && window.innerWidth > 400) {
      this.sliceCharacters = 25;
      this.sliceCharactersName = 25;
    } else if (window.innerWidth <= 400 && window.innerWidth > 250) {
      this.sliceCharacters = 20;
      this.sliceCharactersName = 20;
    } else {
      this.sliceCharacters = 40;
      this.sliceCharactersName = 40;
    }
  }

  getMyInfo(isInit: boolean) {
    this.loaded = false;
    this.authenticationService.getMyInfo().pipe(
      tap(userInfo => {
        this.user = userInfo.user;
        this.userId = userInfo.user.id;
        if (isInit) {
          this.getAccountCardDetails();
        }
      })
    ).subscribe(userInfo => {
      this.handleUserInfoResponse(userInfo.units);
    }, err => {
      this.toastrService.error(
        this.translateService.instant('COMMON.ERROR_MESSAGE'),
        this.translateService.instant('COMMON.ERROR'),
        {timeOut: 10000});
      setTimeout(() => {
        return this.router.navigate(['/hyra-forrad'], {relativeTo: this.activatedRoute});
      }, 3000);
    });
  }

  setCurrentStorage(index) {
    this.currentStorage = this.units[index];
    this.detailedView = true;
    this.currentName = this.currentStorage.unitName;
    this.currentIndex = index;
  }

  resetCurrentStorage() {
    this.units[this.currentIndex] = Object.assign({}, this.currentStorage);
    this.editMode = false;
    this.currentName = this.currentStorage.unitName;
    this.currentStorage = null;
    this.detailedView = false;
  }

  cancel() {
    this.editMode = false;
    this.currentName = this.currentStorage.unitName;
  }

  moveOut() {
    this.bookingService.moveOut(this.cancelStorage.bookingId).subscribe(() => {
      this.getMyInfo(false);
      if (this.detailedView) {
        this.resetCurrentStorage();
      }
    }, () => {
      return;
    });
  }

  setCancellationStorage(index) {
    this.bookingService.moveOutCalc(this.units[index].bookingId).subscribe((moveOutCalc: { moveOutDate: string, paid: boolean }) => {
      const total = moveOutCalc.paid ? 0 : this.units[index].netAmount;
      const storageId = this.units[index].storageUnitId;
      const bookingId = this.units[index].bookingId;
      const keyUnitName = this.units[index].keyUnitName;
      const storageNumber = this.units[index].storageNumber ? this.units[index].storageNumber : null;
      this.cancelStorage = {
        dateMonth: moveOutCalc.moveOutDate, storageId, bookingId, total, keyUnitName, storageNumber
      };
    });
  }

  getMonth(month: string): string {
    return this.translateService.instant(month);
  }

  save() {
    this.bookingService.addName(this.currentName, this.currentStorage.bookingId).subscribe(res => {
      this.currentStorage.unitName = this.currentName;
      this.editMode = false;
    }, () => {
      this.editMode = false;
      this.currentName = this.currentStorage.unitName;
    });
  }

  inFuture(bookDate: Date) {
    return new Date(bookDate).getTime() > new Date().getTime();
  }

  truncateEmail(email) {
    return truncate(email, {length: this.sliceCharacters});
  }

  getAccountCardDetails() {
    this.accountCardService.getAccountCardDetails(this.userId).subscribe(res => {
      this.handleCardDetails(res);
    }, error => {
      if (error.error.message !== 'Resource not found!') {
        this.toastrService.error(this.translateService.instant('COMMON.SOMETHING_WENT_WRONG'), '', {timeOut: 10000});
      }
    });
  }

  changeCard() {
    this.cardChangeModal.open();
    const language = this.translateService.currentLang === 'sv' ? 'sv-SE' : 'en-US';
    this.accountCardService.addAccountCard(this.userId, language)
      .subscribe((response) => {
        this.paymentFormLink = response.result;
        this.paymentLoading = false;
      });

  }

  activateCard(cardId: string) {
    if (this.userId && cardId) {
      this.accountCardService.activateAccountCard(this.userId, cardId)
        .subscribe((response) => {
          this.cardChangeModal.close();
          this.handleCardDetails(response);
        }, () => {
          this.toastrService.error(this.translateService.instant('COMMON.SOMETHING_WENT_WRONG'), '', {timeOut: 10000});
        });
    }
  }

  loadDone() {
    setTimeout(() => {
      this.paymentLoading = false;
    }, 4000);
  }

  public changePassword(passwordModel: { oldPassword?: string; password: string; repeatPassword: string }) {
    if (passwordModel.oldPassword === passwordModel.password) {
      this.toastrService.error(this.translateService.instant('COMMON.SAME_PASSWORD_ERROR'), '', {timeOut: 10000});
    } else {
      this.authenticationService.changePassword(this.user.email, passwordModel.oldPassword, passwordModel.password)
        .pipe(
          filter(response => response.isOk)
        )
        .subscribe(() => {
          this.toastrService.info(this.translateService.instant('COMMON.PASSWORD_CHANGED'));
          this.passwordChangeModalOpened = false;
          this.passwordChangeModal.close();
        }, error => {
          this.toastrService.error(this.translateService.instant('COMMON.CORRECT_PASSWORD'), '', {timeOut: 10000});
        });
    }
  }

  private handleUserInfoResponse(units: any): void {
    units.forEach(u => {
      if (u.scheduledMoveOut !== null) {
        u.scheduledMoveOut = new Date(u.scheduledMoveOut);
      }
    });
    // should not display site and shared units to web app, until proper implementation is available.
    this.units = units.filter(u => !u.scheduledMoveOut &&
      (!u.digitalKeyAccessType || (u.digitalKeyAccessType && u.digitalKeyAccessType !== 'site' && u.digitalKeyAccessType !== 'share')));

    let canceledUnits = units.filter(u => u.scheduledMoveOut &&
      (new Date(u.scheduledMoveOut).getTime() >= new Date().getTime()) &&
      (!u.digitalKeyAccessType ||
        (u.digitalKeyAccessType && u.digitalKeyAccessType !== 'site' && u.digitalKeyAccessType !== 'share')));
    canceledUnits = canceledUnits.sort((a, b) => {
      return new Date(b.scheduledMoveOut).getTime() - new Date(a.scheduledMoveOut).getTime();
    });
    this.units = this.units.sort((a, b) => {
      return a.location.name.localeCompare(b.location.name);
    });
    this.units.push(...canceledUnits);
    this.loaded = true;
  }

  private handleCardDetails(res: CardDetails) {
    this.cardDetails = res;
    if (this.cardDetails) {
      this.cardNumber = this.cardDetails.cardNumber.slice(-4);

      const month = new Date(new Date(this.cardDetails.expiryDate)).getMonth() + 1;
      const cardExpiryMonth = month < 10
        ? `0${month}`
        : `${month}`;
      const cardExpiryYear = new Date(new Date(this.cardDetails.expiryDate)).getFullYear().toString().slice(-2);

      this.expiryDate = `${cardExpiryMonth}/${cardExpiryYear}`;
    }
  }

  public updatePersonalInformation(personalInformation: UpdateUser): void {
    this.personalInfoChangeModalForm.disableButton();
    if (!personalInformation.lastName || personalInformation.lastName === '') {
      personalInformation.lastName = 'business user';
    }

    this.authenticationService
      .updateMyInfo(personalInformation)
      .subscribe(() => {
        this.personalInfoChangeModalForm.enableButton();
        this.getMyInfo(false);
        this.toastrService.info(this.translateService.instant('ACCOUNT.SAVE_PERSONAL_INFO_SUCCESSFULLY'));
        this.closePersonalInfoChangeModal();
      }, () => {
        this.personalInfoChangeModalForm.enableButton();
        this.toastrService.error(this.translateService.instant('COMMON.SOMETHING_WENT_WRONG'));
      });
  }

  public openPersonalInfoChangeModal(): void {
    this.personalInfoChangeModalForm.populateForm(this.user);
    this.personalInfoChangeModal.open();
  }

  public closePersonalInfoChangeModal(): void {
    this.personalInfoChangeModal.close();
    this.personalInfoChangeModalForm.resetForm();
  }
}
