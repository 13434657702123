<div class="personal-information-change">
  <p class="personal-information-change_title">{{'ACCOUNT.UPDATE_PERSONAL_INFORMATION' | translate}}</p>
  <form class="personal-information-change_form" [formGroup]="changePersonalInformationForm"
        (ngSubmit)="onSubmit()" novalidate>
    <div class="personal-information-change_input-wrap">
      <span class="personal-information-change_input-text">
        {{ personalInformation?.isBusinessUser ? ('ACCOUNT.COMPANY_NAME' | translate) : ('COMMON.FIRST_NAME' | translate) }}
      </span>
      <input
        type="text"
        name="firstName"
        [class]="(changePersonalInformationForm.controls.firstName.invalid && changePersonalInformationForm.controls.firstName.touched) ? 'personal-information-change_input-invalid' : 'personal-information-change_input'"
        formControlName="firstName"
        [placeholder]="'COMMON.FIRST_NAME' | translate"
      >
      <div
        *ngIf="changePersonalInformationForm.controls.firstName.touched && changePersonalInformationForm.controls.firstName.hasError('required')"
        class="personal-information-change_input-invalid-text">
        {{'COMMON.REQUIRED_FIELD' | translate}}
      </div>

      <span *ngIf="!personalInformation?.isBusinessUser"
            class="personal-information-change_input-text">{{'COMMON.LAST_NAME' | translate}}</span>
      <input
        *ngIf="!personalInformation?.isBusinessUser"
        type="text"
        name="lastName"
        [class]="(changePersonalInformationForm.controls.lastName.invalid && changePersonalInformationForm.controls.lastName.touched) ? 'personal-information-change_input-invalid' : 'personal-information-change_input'"
        formControlName="lastName"
        [placeholder]="'COMMON.LAST_NAME' | translate"
      >
      <div
        *ngIf="changePersonalInformationForm.controls.lastName.touched && changePersonalInformationForm.controls.lastName.hasError('required')"
        class="personal-information-change_input-invalid-text">
        {{'COMMON.REQUIRED_FIELD' | translate}}
      </div>

      <span class="personal-information-change_input-text">{{'ACCOUNT.EMAIL' | translate}}</span>
      <input
        type="text"
        name="email"
        [class]="(changePersonalInformationForm.controls.email.invalid && changePersonalInformationForm.controls.email.touched) ? 'personal-information-change_input-invalid' : 'personal-information-change_input'"
        formControlName="email"
        [placeholder]="'ACCOUNT.EMAIL' | translate"
      >
      <div
        *ngIf="changePersonalInformationForm.controls.email.touched && changePersonalInformationForm.controls.email.hasError('required')"
        class="personal-information-change_input-invalid-text">
        {{'COMMON.REQUIRED_FIELD' | translate}}
      </div>

      <span class="personal-information-change_input-text">{{'ACCOUNT.PHONE' | translate}}</span>
      <input
        type="text"
        name="phoneNumber"
        [class]="(changePersonalInformationForm.controls.phoneNumber.invalid && changePersonalInformationForm.controls.phoneNumber.touched) ? 'personal-information-change_input-invalid' : 'personal-information-change_input'"
        formControlName="phoneNumber"
        [placeholder]="'ACCOUNT.PHONE' | translate"
      >
      <div
        *ngIf="changePersonalInformationForm.controls.phoneNumber.touched && changePersonalInformationForm.controls.phoneNumber.hasError('required')"
        class="personal-information-change_input-invalid-text">
        {{'COMMON.REQUIRED_FIELD' | translate}}
      </div>

      <span class="personal-information-change_input-text">{{'ACCOUNT.STREET_ADRESS' | translate}}</span>
      <input
        type="text"
        name="addressLine1"
        [class]="(changePersonalInformationForm.controls.addressLine1.invalid && changePersonalInformationForm.controls.addressLine1.touched) ? 'personal-information-change_input-invalid' : 'personal-information-change_input'"
        formControlName="addressLine1"
        [placeholder]="'ACCOUNT.STREET_ADRESS' | translate"
      >
      <div
        *ngIf="changePersonalInformationForm.controls.addressLine1.touched && changePersonalInformationForm.controls.addressLine1.hasError('required')"
        class="personal-information-change_input-invalid-text">
        {{'COMMON.REQUIRED_FIELD' | translate}}
      </div>

      <span class="personal-information-change_input-text">{{'ACCOUNT.ZIP_CODE' | translate}}</span>
      <input
        type="text"
        name="postalCode"
        [class]="(changePersonalInformationForm.controls.postalCode.invalid && changePersonalInformationForm.controls.postalCode.touched) ? 'personal-information-change_input-invalid' : 'personal-information-change_input'"
        formControlName="postalCode"
        [placeholder]="'ACCOUNT.ZIP_CODE' | translate"
      >
      <div
        *ngIf="changePersonalInformationForm.controls.postalCode.touched && changePersonalInformationForm.controls.postalCode.hasError('required')"
        class="personal-information-change_input-invalid-text">
        {{'COMMON.REQUIRED_FIELD' | translate}}
      </div>

      <span class="personal-information-change_input-text">{{'ACCOUNT.CITY' | translate}}</span>
      <input
        type="text"
        name="city"
        [class]="(changePersonalInformationForm.controls.city.invalid && changePersonalInformationForm.controls.city.touched) ? 'personal-information-change_input-invalid' : 'personal-information-change_input'"
        formControlName="city"
        [placeholder]="'ACCOUNT.CITY' | translate"
      >
      <div
        *ngIf="changePersonalInformationForm.controls.city.touched && changePersonalInformationForm.controls.city.hasError('required')"
        class="personal-information-change_input-invalid-text">
        {{'COMMON.REQUIRED_FIELD' | translate}}
      </div>
    </div>
    <button class="personal-information-change_button" type="submit"
            [disabled]="changePersonalInformationForm.invalid || actionExecuting">{{'COMMON.SAVE' | translate}}</button>
  </form>
</div>
