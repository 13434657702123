<div class="position-relative">
<div class="app-header_background-wrapper"></div>
  <div class="app-header">
    <div class="app-header_text-section">
      <div>
        <h1 class="app-header_title" [innerHTML]="config?.title | translate"></h1>
        <p class="app-header_text" [innerHTML]="config?.description | translate"></p>
      </div>

    </div>
    <div class="app-header_image-wrapper">
      <div class="app-header_image" [ngStyle]="{ 'background-image': config?.imagePath | asUrl }"></div>
    </div>
  </div>
</div>
