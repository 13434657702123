import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';
import {RestService} from './rest.service';
import {ConfigService} from './config.service';
import {SessionService} from './session.service';
import * as jwt_decode from 'jwt-decode';
import {tap} from 'rxjs/operators';
import {UpdateUser} from '../../user/models/update-user';

export type BankIdOptions = {
  ipAddress?: string,
  personalNumber?: string,
  isBankIdSignIn?: boolean,
  orderReference?: string,
  isBusinessUser?: boolean,
  organizationNumber?: string,
  polling?: boolean
};

export type CheckOrderStatusOptions = {
  orderRef: string,
  qrStartToken: string,
  qrStartSecret: boolean,
  orderTime: string
};

export type LoginOptions = {
  isNewUser: boolean,
  personalNumber?: string,
  firstName?: string,
  lastName?: string,
  email: string,
  phoneNumber?: string,
  password: string,
  isBusinessUser?: boolean,
  organizationNumber?: string,
  city?: string,
  companyName?: string,
};

export type StandardLoginOptions = {
  username: string,
  password: string,
  organizationNumber?: string
};

@Injectable({providedIn: 'root'})
export class AuthenticationService extends RestService {
  basePath = 'account';
  apiPath = '';
  version = '';

  loggedIn$ = new Subject<boolean>();

  constructor(http: HttpClient,
              configService: ConfigService) {
    super(http, configService);
    this.loggedIn$.next(this.isLoggedIn);
  }

  get isLoggedIn(): boolean {
    return !!SessionService.token;
  }

  get sessionToken() {
    return SessionService.token;
  }

  get userId() {
    return SessionService.userId;
  }

  getMyInfo(): Observable<any> {
    return this.http.get();
  }

  updateMyInfo(personalInformation: UpdateUser): Observable<any> {
    return this.http.put(personalInformation);
  }

  getMyInvoices(): Observable<any> {
    return this.http.get(this.userId + '/invoices');
  }

  public changePassword(email: string, oldPassword: string, password: string): Observable<any> {
    return this.http.post({
      username: email,
      password: oldPassword,
      newPassword: password
    }, 'change-password');
  }

  get decodedAccessToken(): any {
    try {
      return jwt_decode(this.sessionToken);
    } catch (Error) {
      return null;
    }
  }

  bankId(options: BankIdOptions) {
    const body = {
      ...options,
    };
    return this.http.post<any>(body, 'bankid');
  }

  checkOrderStatus(options: CheckOrderStatusOptions) {
    const body = {
      ...options,
    };
    return this.http.post<any>(body, 'order-status');
  }

  createBusinessCustomerOrLogin(options: LoginOptions) {
    const body = {
      ...options
    };

    return this.http.post<any>(body, 'create-business-user-and-login');
  }

  standardLogin(options: StandardLoginOptions) {
    const body = {
      ...options,
    };

    return this.http.post<any>(body, 'standard-login');
  }

  public companyLogin(options: StandardLoginOptions): Observable<any> {
    return this.http.post<any>(options, 'company-login');
  }

  login(options: LoginOptions): Observable<any> {
    return this.http.post<any>(options, 'authorize')
      .pipe(tap(
        (response: any) => {
          if (response.isSuccess) {
            this.tokenSideAffects(response.user.token);
          }
        }
      ));
  }

  autoAuth(personalNumber?: string): Observable<any> {
    return this.http.post<any>({personalNumber}, 'auto-auth');
  }

  initiateAuthWithAnimatedQrCode(isSign?: boolean, unit?: string): Observable<any> {
    return this.http.post<any>({isSign: isSign, unit: unit}, 'auth-qr');
  }

  autoVerify(email: string, phoneNumber: string, unit: string/*, personalNumber?: string*/): Observable<any> {
    const body = {
      isSign: true,
      email,
      phoneNumber,
      unit/*,
      personalNumber*/
    };
    return this.http.post<any>(body, 'auto-auth');
  }

  logout() {
    SessionService.deleteSession();
    localStorage.removeItem('_expiredTime');

    this.loggedIn$.next(false);
  }

  contactSupport(customerEmail: string, customerMessage: string): Observable<any> {
    const params = new HttpParams().set('customerEmail', customerEmail).set('customerMessage', customerMessage);
    return this.http.post({}, 'contact-support', {params});
  }

  checkIfVerified(email: string): Observable<any> {
    return this.http.post({email: email}, 'user-email');
  }

  resetPassword(email: string): Observable<any> {
    return this.http.post({resetOptionType: 1, emailOrPhoneNumber: email}, 'forgot-password');
  }


  setPassword(email, password: string, confirmPassword: string, token: string): Observable<any> {
    return this.http.post({email, password, confirmPassword, token}, 'set-password');
  }

  confirmPassword(email: string, password: string): Observable<any> {
    return this.http.put({email, password}, 'user/password/update');
  }

  register(user): Observable<any> {
    return this.http.post(user, 'register');
  }

  tokenSideAffects(token) {
    SessionService.token = token;
    this.userIdSideAffects(this.decodedAccessToken.unique_name);
    this.loggedIn$.next(true);
  }

  userIdSideAffects(id) {
    SessionService.userId = id;
  }

  passwordRecover(email): Observable<any> {
    return this.http.post({email, link: 'link'}, 'password-recovery');
  }

  createPassword(linkId: string, password: string): Observable<any> {
    return this.http.post({id: linkId, password}, 'create-password');
  }
}
