import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Base class for reactive components. Use it if you have to manage subscriptions manually as it provides common reactive patterns.
 */
@Directive({ selector: '[reactiveComponent]' })
export class ReactiveComponent implements OnDestroy {

  /**
   * Emits to notify that subscribers should unsubscribe, eg. if a component is destroyed or parts of a component are hidden.
   */
  protected unsubscribe$ = new Subject<void>();

  /**
   * When an angular component is destroyed, notify subscribers and complete
   */
  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
