import {Component, EventEmitter, Input, Output} from '@angular/core';

import {StorageUnit} from '../../../core/models/storage-unit';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.html',
  styleUrls: [ './unit.scss' ]
})
export class UnitComponent {
  @Input() public unit: StorageUnit;
  @Input() public showAllUnits: boolean;

  @Output() public redirectToBook = new EventEmitter<StorageUnit>();
  @Output() public collapseAllOtherUnits = new EventEmitter<void>();


  public expandCollapseUnit(): void {
    if (!this.showAllUnits) {
      this.collapseAllOtherUnits.emit();
    }

    this.unit.expanded = !this.unit.expanded;
  }
}
