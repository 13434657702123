import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {StorageUnitService} from '../../../core/services/storage-unit.service';
import {ObservableService} from '../../../core/services/observable.service';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {HelsingborgConfig, LocationConfig, Locations, LundConfig} from 'src/app/core/models/locations.model';
import {Title} from '@angular/platform-browser';
@Component({
  selector: 'app-user-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  public isLoaded = false;
  public locationAddress = 'Mörsaregatan 23';
  public locationCity = 'Helsingborg';
  eLocations = Locations;
  buildings = [];
  subscription: Subscription;
  calculationView = false;
  apartmentSize: string;
  calculationValue: string;
  currentMoreIndex: number;
  currentLocationName = '';
  currentLocationConfig: LocationConfig = LundConfig;
  public inputInvalid = false;

  @ViewChild('videoPlayer', null) videoplayer: ElementRef;

  constructor(
    private storageUnitService: StorageUnitService,
    private route: ActivatedRoute,
    private router: Router,
    private observableService: ObservableService,
    private authService: AuthenticationService,
    private titleService: Title
  ) {
    this.observableService.scrollAnchor$.subscribe(() => {
      document.querySelector('#unitList').scrollIntoView();
    });
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let selectedLocation = params.get('location');
      if (selectedLocation) {
        selectedLocation = selectedLocation.charAt(0).toUpperCase() + selectedLocation.slice(1);
        sessionStorage.setItem('se-location', selectedLocation);
        const buildingId = this.getBuildingIdByBuildingName(selectedLocation);
        sessionStorage.setItem('buildingId', buildingId);
      }

      if (selectedLocation && !this._checkIfLocationAvailable(selectedLocation)) {
        this.router.navigate(['not-found']);
        return;
      }
      if (!selectedLocation) {
        const locationFromSession = sessionStorage.getItem('se-location');
        this.currentLocationName = locationFromSession || Locations.HELSINGBORG.toLowerCase();
      } else {
        this.currentLocationName = selectedLocation;
      }
      this.observableService.currentLocationName$.next(this.currentLocationName);
      this.titleService.setTitle(
        `Hyra förråd i ${this.currentLocationName} för magasinering | Sesam Self Storage`
      );
      if (this.currentLocationName === Locations.LUND) {
        this.currentLocationConfig = LundConfig;
      } else if (this.currentLocationName === Locations.HELSINGBORG) {
        this.currentLocationConfig = HelsingborgConfig;
      }
      this.router.navigate(['hyra-forrad', this.currentLocationName.toLowerCase()], {});
    });
  }

  getBuildingIdByBuildingName(name) {
    switch (name.toLowerCase()) {
      case 'lund':
        return '9e8f0af4-e8db-4fdc-b34c-aab839327c7f';
      case  'helsingborg':
        return '3160488e-d4dd-47ec-b005-787248489387';

      default:
        return null;
    }
  }

  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  redirectToCalculator() {
    return this.router.navigate([
      this.authService.isLoggedIn
        ? 'user/storage-calculator'
        : 'storage-calculator',
    ]);
  }

  calculateValue() {
    const size = parseInt(this.apartmentSize, 0);
    if (isNumeric(size)) {
      this.calculationView = true;
      this.inputInvalid = false;
      if (size < 15) {
        this.calculationValue = '1-1.5';
      } else if (size > 14 && size < 22) {
        this.calculationValue = '1.5-2';
      } else if (size >= 22 && size < 31) {
        this.calculationValue = '2.25-3.5';
      } else if (size >= 31 && size < 46) {
        this.calculationValue = '3.5-4.5';
      } else if (size >= 46 && size < 61) {
        this.calculationValue = '4.5-6';
      } else if (size >= 61 && size < 91) {
        this.calculationValue = '6-9';
      } else if (size >= 91 && size < 121) {
        this.calculationValue = '9-12';
      } else if (size >= 121 && size < 150) {
        this.calculationValue = '12-15';
      } else if (size >= 150 && size < 250) {
        this.calculationValue = '15-30';
      } else {
        this.calculationValue = '30+';
      }
    } else {
      this.inputInvalid = true;
    }
  }

  private _checkIfLocationAvailable(selectedLocation) {
    const availableLocations = Object.values(Locations);
    return availableLocations.some((al) => al === selectedLocation);
  }
}

function isNumeric(num) {
  return !isNaN(num);
}
