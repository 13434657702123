export enum KEYS {
  AUTH_HEADER = 'Authorization',
  USER_ID = 'user-id',
}

export class SessionService {

  constructor() {
  }

  static get token(): string {
    return localStorage.getItem(KEYS.AUTH_HEADER);
  }

  static set token(value: string) {
    localStorage.setItem(KEYS.AUTH_HEADER, value);
  }

  static get userId(): string {
    return localStorage.getItem(KEYS.USER_ID);
  }

  static set userId(value: string) {
    localStorage.setItem(KEYS.USER_ID, value);
  }

  static get sessionExpiration(): number {
    return 10800000;
  }

  static deleteSession(): void {
    Object.keys(KEYS).forEach(key => localStorage.removeItem(KEYS[key]));
  }

}
