<div *ngIf="!isExternal">
  <div [ngClass]="setStickyClass ? 'sticky-nav' : 'non-sticky'" class="sticky">
  <nav [ngClass]="setStickyClass ? 'sticky-nav' : 'non-sticky'" class="navbar navbar-expand-md navbar-dark">
   <div class="d-flex w-100 align-items-center">
     <img class="navbar-toggle" loading="lazy" height="24px" [src]="'assets/img/home/hamburger-menu.png'" alt="unit image" (click)="toggleSidebar()">
  <a class="navbar-home-brand" href="https://sesamselfstorage.se/home" (click)="selectedPage = 1"><img loading="lazy" width="90" height="60" class="logo" src="assets/img/home/sesam-logo.svg" alt="sesam logo"></a>
<!--  <div class="btn-group languages">-->
<!--    <img class="language-image" [src]="currentLanguage.imageSmall" data-toggle="dropdown" aria-haspopup="true"-->
<!--         aria-expanded="false">-->
<!--    <div class="dropdown-menu language">-->
<!--      <a class="dropdown-item" *ngFor="let language of languages"-->
<!--         (click)="changeLanguage(language.value)">{{ language.display }}</a>-->
<!--    </div>-->
<!--  </div>-->
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link link" (click)="selectedPage = 1"
           href="https://sesamselfstorage.se/magasinering">{{ 'HEADER.STORAGE' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link link" (click)="selectedPage = 5"
           href="https://sesamselfstorage.se/kontakt">{{ 'HEADER.CONTACT' | translate }}</a>
      </li>
    </ul>
  </div>
     <form class="form-inline mt-md-0">
       <ul class="navbar-nav mr-auto">
         <li *ngIf="!isLoggedIn" class="nav-item align-self-center" id="loginButton">
           <a class="nav-link"
              (click)="loginModal.open(); currentView = 1; modalOpened = true; loginFormClear = false;">{{ 'HEADER.SIGN_IN' | translate }}</a>
         </li>
         <li *ngIf="isLoggedIn" class="nav-item dropdown" id="loginAvatar">
           <a class="nav-link" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false"><img src="assets/img/home/avatar.png"></a>
           <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
             <a (click)="goToProfile();" class="dropdown-item" href="javascript:void(0)">
               {{ 'HEADER.ACCOUNT' | translate }}
             </a>
             <a (click)="goToInvoices();" class="dropdown-item" href="javascript:void(0)">
               {{ 'HEADER.INVOICES' | translate }}
             </a>
             <a (click)="logout()" class="dropdown-item" href="javascript:void(0)">
               <i class="fa fa-sign-out"></i> {{ 'HEADER.LOGOUT' | translate }}
             </a>
           </div>
         </li>
         <li class="nav-item" *ngIf="locations.length > 1 && !bookingProcess">
           <div class="btn-group column-display">
             <button type="button" class="location-button" data-toggle="dropdown" style="display: flex;justify-content: center;align-items: center;">
               <span> {{'HEADER.RENT' | translate}} </span>
               <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25" class="expand_more_svg">
                <path d="M480 711 240 471l43-43 197 198 197-197 43 43-240 239Z"/>
              </svg>
             </button>
             <div class="current-location-chip"><span>{{currentBuilding}}</span> <img src="assets/img/navbar/place.svg" alt=""></div>
             <div class="dropdown-menu">
               <a class="dropdown-item" *ngFor="let location of locations"
                  (click)="selectBuilding(location)">{{ location.locationName }}</a>
             </div>
           </div>
         </li>
         <li class="nav-item" *ngIf="locations.length === 1 && !bookingProcess">
           <div class="btn-group">
             <button type="button" class="location-button" (click)="selectBuilding(locations[0])">
               {{ rentStorageText | translate }}
             </button>
           </div>
         </li>
         <li class="nav-item" *ngIf="locations.length === 0 && !bookingProcess">
           <div class="spinner">
             <div class="rect1"></div>
             <div class="rect2"></div>
             <div class="rect3"></div>
             <div class="rect4"></div>
             <div class="rect5"></div>
           </div>
         </li>
         <!--        <li class="nav-item">-->
         <!--          <div class="btn-group">-->
         <!--            <img class="language-image" [src]="currentLanguage.imageSmall" data-toggle="dropdown" aria-haspopup="true"-->
         <!--                 aria-expanded="false">-->
         <!--            <div class="dropdown-menu">-->
         <!--              <a class="dropdown-item" *ngFor="let language of languages"-->
         <!--                 (click)="changeLanguage(language.value)">{{ language.display }}</a>-->
         <!--            </div>-->
         <!--          </div>-->
         <!--        </li>-->

       </ul>
     </form>

   </div>
</nav>
</div>


<app-sidebar [locations]="locations" [currentBuilding]="currentBuilding"
             (selectBuilding)="selectBuilding($event); sidebarOpened = false;"
             [selectedPage]="selectedPage"
             [bookingProcess]="bookingProcess"
             (toggleSidebar)="toggleSidebar()"
             (login)="loginModal.open(); currentView = 1; modalOpened = true; sidebarOpened = false;"
             [opened]="sidebarOpened" (signOut)="logout();  sidebarOpened = false;"></app-sidebar>
</div>


<ngx-smart-modal #loginModal identifier="loginModal" [customClass]="'basic-modal'" [dismissable]="false">
  <img class="close-modal" src="assets/img/home/close_grey.svg" (click)="closeLoginModal()">
  <app-login [modalOpened]="modalOpened" [loading]="loading" [loginFormClear]="loginFormClear" [errorMessage]="errorMessage" [passwordRecoveryView]="false" (closeModal)="closeLoginModal()"></app-login>
</ngx-smart-modal>

<ng-template #loadingLocation>
</ng-template>
