import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDividerModule} from '@angular/material/divider';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { SafePipe } from './pipes/safe.pipe';
import { DateSuffix } from './pipes/date-suffix.pipe';
import {HeaderComponent} from './header/header.component';
import {SharedModule} from '../shared/shared.module';
import {NoCommaPipe} from './pipes/no-comma.pipe';
import { BackgroundUrlPipe } from './pipes/img-url.pipe';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    TranslateModule,
    NgxSmartModalModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatExpansionModule,
    MatDividerModule,
    MatMenuModule,
    MatIconModule,
    SharedModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    SafePipe,
    BackgroundUrlPipe,
    NoCommaPipe,
    DateSuffix,
    InfiniteScrollComponent,
    LoginComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    SafePipe,
    BackgroundUrlPipe,
    DateSuffix,
    InfiniteScrollComponent,
    LoginComponent,
    NoCommaPipe,
  ],
})
export class MainModule {}
