import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {Routes, RouterModule} from '@angular/router';
import {UserGuard} from './user.guard';
import {LoggedInGuard} from './logged-in.guard';
import {NotFoundComponent} from './home/notFound.component';
import {HomeComponent} from './home/home.component';
import {SearchComponent} from './user/pages/search/search.component';
import {CalculatorComponent} from './user/pages/calculator/calculator.component';
import {RentComponent} from './user/pages/rent/rent.component';
import {ContactComponent} from './user/pages/contact/contact.component';
import {StorageCalculatorComponent} from './user/pages/storage-calculator/storage-calculator.component';
import {ExtractedUnitsComponent} from './user/pages/extracted-units/extracted-units.component';
import {NavbarComponent} from './main/navbar/navbar.component';
import {ResetPasswordComponent} from './user/pages/reset-password/reset-password.component';
import {WordpressLoginGuard} from './wordpress-login.guard';

const routes: Routes = [
  {path: 'user', loadChildren: './user/user.module#UserModule', canActivate: [UserGuard]},
  {path: 'login', component: NavbarComponent, canActivate: [WordpressLoginGuard]},

  // Eager loaded
  {
    path: '', component: HomeComponent, canActivate: [LoggedInGuard],
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'hyra-forrad'},
      {path: 'hyra-forrad', component: SearchComponent},
      {path: 'hyra-forrad/:location', component: SearchComponent},
      {path: 'storage-calculator', component: CalculatorComponent},
      {path: 'book/unit-info/:id', component: RentComponent},
      {path: 'contact', component: ContactComponent}
    ]
  },
  {path: 'extracted-units', component: ExtractedUnitsComponent},
  {path: 'reset-password/:guid', component: ResetPasswordComponent},
  {path: 'simple-storage-calculator', component: StorageCalculatorComponent},
  {path: 'simple-storage-calculator/:lang', component: StorageCalculatorComponent},
  {path: '**', redirectTo: '/not-found'},
  {path: 'not-found', component: NotFoundComponent},
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
