<div class="footer-info">
  <div class="more-info-wrapper row flex-column flex-md-row">
    <div class="col-lg-12">
      <div class="boxes row">
        <div class="box">
          <p class="title">{{ "FOOTER.RENT_STORAGE" | translate }}</p>
          <a
            class="subtitle"
            (click)="scrollToTop()"
            [routerLink]="['/hyra-forrad', 'Helsingborg']"
          >Hyr förråd i Helsingborg</a
          >
          <a
            class="subtitle"
            (click)="scrollToTop()"
            [routerLink]="['/hyra-forrad', 'Lund']"
          >Hyr förråd i Lund</a
          >

          <a class="subtitle" href="https://sesamselfstorage.se/magasinering">{{
              "FOOTER.STORAGE" | translate
            }}</a>
          <a class="subtitle" href="https://sesamselfstorage.se/foretag/">{{
              "FOOTER.FOR_COMPANIES" | translate
            }}</a>
          <a
            *ngIf="!isLoggedIn"
            class="subtitle"
            href="https://app.sesamselfstorage.se/login"
          >{{ "FOOTER.MY_SESAM" | translate }}</a
          >
          <a
            *ngIf="isLoggedIn"
            class="subtitle"
            href="https://app.sesamselfstorage.se/user/account"
          >{{ "FOOTER.MY_SESAM" | translate }}</a
          >
          <a class="subtitle" href="https://freetrailer.com/se/">{{
              "FOOTER.RENT_TRAILER" | translate
            }}</a>
        </div>
        <div class="box">
          <p class="title">{{ "FOOTER.CONTACT" | translate }}</p>
          <a class="subtitle" href="https://sesamselfstorage.se/kontakt/">{{
              "FOOTER.CONTACT_US" | translate
            }}</a>
          <a class="subtitle" [href]="faqUrl">{{ "FOOTER.FAQ" | translate }}</a>
        </div>

        <div class="box">
          <p class="title">{{ "FOOTER.ABOUT_US" | translate }}</p>
          <a class="subtitle" href="https://sesamselfstorage.se/om-oss/">{{
              "FOOTER.ABOUT_US" | translate
            }}</a>
          <a class="subtitle" [href]="agreementUrl" target="_blank">{{
              "FOOTER.BUY_TERMS" | translate
            }}</a>
          <a
            class="subtitle"
            href="https://sesamselfstorage.se/cookiepolicy/ "
          >{{ "FOOTER.COOKIE" | translate }}</a
          >
          <a class="subtitle" [href]="integrityPolicyUrl">{{
              "FOOTER.INTEGRITY" | translate
            }}</a>
          <!-- OneTrust Cookies Settings button start -->
          <button id="ot-sdk-btn" class="ot-sdk-show-settings">
            Cookie-inställningar
          </button>
          <!-- OneTrust Cookies Settings button end -->
        </div>
      </div>
    </div>

    <div class="payment d-flex align-items-center">
      <div class="col-md-8 payment_logo-type-wrapper">
        <img
          class="payment_logo-type"
          loading="lazy"
          width="561px"
          height="27px"
          src="assets/img/home/payment-type-1.webp"
          alt="payment-type"
        />
      </div>
      <div class="d-flex align-items-center sm-mobile-logo">
        <img
          class="payment_sesam-logo"
          loading="lazy"
          width="130"
          height="84"
          src="assets/img/home/sesam-logo.svg"
          alt="footer-logo"
        />
      </div>
    </div>
  </div>
</div>
<footer class="footer">
  <div class="container-fluid">
    <div class="sm-icons">
      <a target="_blank" href="https://www.facebook.com/sesamselfstorage/"
      ><img
        loading="lazy"
        alt="fb-logo"
        class="social-network-logo"
        width="30px"
        height="30px"
        src="assets/img/icons/facebook_ico.svg"
      /></a>
      <a target="_blank" href="https://www.instagram.com/sesamselfstorage.se/"
      ><img
        loading="lazy"
        width="30px"
        height="30px"
        class="social-network-logo"
        src="assets/img/icons/instagram_ico.svg"
        alt="instagram-logo"
      /></a>
      <a
        target="_blank"
        href="https://www.linkedin.com/company/sesam-self-storage/"
      ><img
        loading="lazy"
        alt="fb-logo"
        width="30px"
        height="30px"
        src="assets/img/icons/linkedin_ico.svg"
      /></a>
    </div>
    <div class="footer_text-wrapper">
      <span *ngIf="!resizeEvent"> | </span> <br *ngIf="resizeEvent"/>
      <a class="link" href="mailto:support@sesamselfstorage.se">support@sesamselfstorage.se</a>
      <span *ngIf="!resizeEvent"> | </span> <br *ngIf="resizeEvent"/>
      <a class="link" href="tel:042-44 88 000">042-44 88 000</a>
      <span *ngIf="!resizeEvent"> | </span> <br *ngIf="resizeEvent"/>
      <br *ngIf="resizeEvent"/>
      <span class="info">©{{getYear()}} - {{ "FOOTER.MESSAGE" | translate }}</span>
    </div>
  </div>
</footer>
