import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {Language} from '../models/language';
import {DateAdapter} from '@angular/material/core';
const imagePath = '/assets/img/flags/';
const LANGUAGE_FIELD = 'language';

@Injectable()
export class LanguageService {
  languageChange$: Subject<Language> = new Subject<Language>();

  languages: Language[] = [
    {
      display: 'Svenska',
      value: 'sv',
      imageSmall: imagePath + 'sw.png',
      imageLarge: imagePath + 'sw.png'
    },
    {
      display: 'English',
      value: 'en',
      imageSmall: imagePath + 'uk.png',
      imageLarge: imagePath + 'uk.png'
    }
  ];

  currentLang: Language;

  constructor(private translate: TranslateService, private dateAdapter: DateAdapter<Date>) {
  }

  get allLanguages(): Language[] {
    return this.languages;
  }

  get currentLanguage(): Language {
    return this.currentLang;
  }

  setDefaultLanguage() {
    const defaultLanguage = localStorage.getItem(LANGUAGE_FIELD) || 'sv';
    this.setLanguage(defaultLanguage);
  }

  setLanguage(lang: string) {
    this.currentLang = this.languages.find(l => l.value === lang) || this.languages[0];

    localStorage.setItem(LANGUAGE_FIELD, this.currentLang.value);

    this.dateAdapter.setLocale(this.currentLang.value);

    this.translate.use(this.currentLang.value);
  }
}
