import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject, combineLatest} from 'rxjs';
import {StorageUnitService} from './storage-unit.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ObservableService {
  public currentLocation = new Subject<{ locationId: string, buildingId: string, city?: string, address?: string }>();
  public $scrollTop = new Subject<any>();
  public scrollAnchor$ = new Subject<boolean>();
  public currentLocationValue: any = {};
  public locationBuildings$ = new BehaviorSubject<any>(false);
  public currentLanguage$ = new BehaviorSubject<string>('sv');
  public currentLocationName$ = new BehaviorSubject<string>(null);

  constructor(private storageUnitService: StorageUnitService, private translateService: TranslateService, private route: ActivatedRoute) {
    this.currentLanguage$.next(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((langEvent: LangChangeEvent) => {
      this.currentLanguage$.next(langEvent.lang);
    });

    combineLatest([this.locationBuildings$, this.currentLocationName$]).pipe(map(([location, currentLocationName]: [any, any]) => {
      if (!location.length || !currentLocationName) { return; }
      if (location.length > 0) {
        this.currentLocationValue = {
          locationId: location.find(
            (l: any) => l.locationName === currentLocationName
          ).locationId,
          buildingId: location.find(
            (l: any) => l.locationName === currentLocationName
          ).buildingId,
        };
      } else {
        this.currentLocationValue = { locationId: '', buildingId: '' };
      }
    })).subscribe();

    this.storageUnitService
      .getLocations()
      .pipe(
        tap((locations) =>
          this.locationBuildings$.next(locations.locationBuildings)
        )
      )
      .subscribe();
  }

  setCurrentLocation(locationId: string, buildingId: string, city: string, address: string) {
    this.currentLocationValue = {locationId, buildingId, city, address};
    this.currentLocation.next({locationId, buildingId, city, address});
  }

  scrollTop() {
    this.$scrollTop.next(true);
  }

  scrollToAnchor() {
    this.scrollAnchor$.next(true);
  }

}
