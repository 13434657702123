import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TagManagerService  {

    constructor() {}

    registerEvent(event: string, properties?: any): void {
        const gtmTag = {
            event,
            ...properties
        };

        window['dataLayer'].push(gtmTag);
    }
}
