import { Injectable } from '@angular/core';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

interface PricingData {
  insurance_price: number;
  next_payment: number;
  regular_price: number;
  this_payment: number;
}

interface UnitDetails {
  unit_id: string;
  unit_number: string;
  unit_size: string;
  unit_type_name: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private userType: 'B2B' | 'B2C' = 'B2C'; // temporarily set dev as initial value
  private paymentMethod: 'invoice' | 'card' = 'card';
  public prices: PricingData;
  public unit: UnitDetails;
  private pricesSubject = new BehaviorSubject<PricingData | null>(null);
  private unitSubject = new BehaviorSubject<UnitDetails | null>(null);
  public prices$ = this.pricesSubject.asObservable();
  public unit$ = this.unitSubject.asObservable();

  constructor() {
    combineLatest([this.prices$, this.unit$])
      .pipe(filter(([prices, unit]) => prices !== null && unit !== null))
      .subscribe(([prices, unit]) => {
        this.sendProductViewEvent(prices, unit);
      });
  }

  private enrichEventParams(params: {}): {} {
    const enrichedParams = {
      ...params,
      platform: 'web',
      user_type: this.userType,
      payment_method: this.paymentMethod,
    };

    return enrichedParams;
  }

  setUserType(isB2B: boolean): void {
    this.userType = isB2B ? 'B2B' : 'B2C';
  }

  setPaymentMethod(paymentMethod: 'card' | 'invoice'): void {
    this.paymentMethod = paymentMethod;
  }

  setPrices(prices: PricingData): void {
    this.pricesSubject.next(prices);
  }

  setUnitSpecs(storage: UnitDetails): void {
    this.unitSubject.next(storage);
  }

  public event(eventName: string, params: {}): void {
    window['dataLayer'].push({
      event: eventName,
      ...this.enrichEventParams(params),
    });
  }

  public sendPageViewEvent(url: string) {
    window['dataLayer'].push({
      event: 'pageView',
      page: {
        url: url,
      },
    });
  }

  public sendProductViewEvent(prices: PricingData, unit: UnitDetails) {
    window['dataLayer'].push({
      event: 'view_product',
      ecommerce: {
        value: prices.this_payment,
        currency: 'SEK',
        items: [
          {
            item_name: `${unit.unit_size} ${unit.unit_type_name}`,
            item_id: unit.unit_id,
            item_category: unit.unit_type_name,
            item_variant: unit.unit_size,
            price: prices.regular_price,
            quantity: 1,
          },
        ],
      },
    });
  }

  public sendTestEvent(): void {
    this.event('test_event', {
      test: 'test',
      event_label: 'test_label',
      test_value: 1,
    });
  }
}
