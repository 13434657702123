import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../core/services/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  @ViewChild('mainElement', null) mainElement: ElementRef;
  sidebarOpened = false;
  setStickyClass: boolean;

  constructor(private authService: AuthenticationService, private router: Router) {}

  ngOnInit() {
    if (!this.authService.isLoggedIn) {
      return this.router.navigate(['hyra-forrad']);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    this.setStickyClass = e.target && e.target.scrollTop > 10;
  }


  toggleSidebar(event) {
    this.sidebarOpened = event;
  }

  onActivate(event) {
    this.mainElement.nativeElement.scrollTop = 0;
  }
}
