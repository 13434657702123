import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { RestService } from './rest.service';
import { Observable } from 'rxjs';
import { MoveInModel } from '../../user/models/move-in-model';
import { PriceDetails } from '../../user/models/price-details';
import { RegisterAndSignOptionsModel } from '../../user/models/register-and-sign-options-model';

export interface SignDateOptions {
  unitId: string;
  moveInDate: string;
  insuranceId: string;
  email: string;
  phoneNumber: string;
  orderReference?: string;
  personalNumber?: string;
  platform?: string;
  isBusinessUser?: boolean;
  organizationNumber?: string;
  paymentMethod?: number;
  isNewUser: boolean;
  firstName: string;
  lastName: string;
  city?: string;
  companyName?: string;
  password?: string;
  language?: string;
}

export interface MoveInOptions {
  bookingId: any;
  isPaymentSuccess: boolean;
  paymentTransactionResponse?: {
    clientId: string,
    last4Digit: string,
    ccExpireDate: string,
    paymentStatus: string,
    transactionId: string,
    currencyType: string,
    email: string,
    paymentCardType: string,
    cardHoldername: string,
    amountCharged: string,
    gatewayCustomerId: string,
    paymentMethodId: string
  };
}

@Injectable()
export class BookingService extends RestService {
  basePath = 'booking';
  apiPath = '';
  version = '';

  constructor(http: HttpClient,
    configService: ConfigService) {
    super(http, configService);
  }

  getUnitInfo(unitId: string): Observable<any> {
    const params = new HttpParams().set('id', unitId);
    return this.http.get('unit-id-by-model-id', { params });
  }

  signDate(signDateOptions: SignDateOptions): Observable<any> {
    return this.http.post(signDateOptions, 'sign-date');
  }

  businessUserSignDate(signDateOptions: RegisterAndSignOptionsModel): Observable<any> {
    return this.http.post(signDateOptions, 'business-user-sign-date');
  }

  moveIn(moveInOptions: MoveInOptions): Observable<MoveInModel> {
    return this.http.post(moveInOptions, 'move-in');
  }

  addName(unitName, bookingId) {
    const body = {
      unitName,
      bookingId
    };
    return this.http.post(body, 'name');
  }

  moveOut(bookingId: number): Observable<any> {
    return this.http.post({ bookingId }, 'move-out');
  }

  moveOutCalc(bookingId: number): Observable<any> {
    return this.http.post({ bookingId }, 'move-out/calculate');
  }

  // tslint:disable-next-line:max-line-length
  calculatePrices(unitId, insuranceId, moveInDate, isBusinessUser): Observable<{ toPayNow: PriceDetails, nextPayment: PriceDetails, regularMonthly: PriceDetails }> {
    return this.http.post({ unitId, insuranceId, moveInDate, isBusinessUser }, 'lease-calc');
  }

  // logBankCardEvent(cardEvent: any): Observable<any> {
  //  return this.http.post({cardEvent}, 'log-bank-card-event');
  // }

  getPaymentStatus(sixStorageTransactionId: string): Observable<any> {
    const params = new HttpParams().set('transactionId', sixStorageTransactionId);
    return this.http.get('payment-status', { params });
  }
}
