<div *ngIf="!confirmation && !passwordRecoveryView; else confirmationModal" class="login-wrapper"
     [ngClass]="{'with-bg': startPhase}">
  <img *ngIf="sesamAccountLogin" class="previous-button" (click)="startPhaseView()"
       src="assets/img/account/arrow_back.png">
  <form *ngIf="!(waitingToAuthBankId | async); else loadingView" class="login-form" [formGroup]="loginForm" #f="ngForm">
    <p class="scan-title" *ngIf="startPhase">{{ 'CONFIRMATION.WELCOME_SESAM' | translate }}</p>
    <img *ngIf="sesamAccountLogin" src="assets/img/home/sesam-logo.svg">
    <img *ngIf="!sesamAccountLogin && startPhase" class="vagn-img" src="assets/img/man-med-vagn.webp">
    <img *ngIf="!sesamAccountLogin && !startPhase" src="assets/img/bank_id_logo.webp">
    <p *ngIf="desktopView && !startPhase && !signInOptionsView && !loginFailed"
       class="scan-text">{{ 'HEADER.SCAN_CODE' | translate }}</p>
    <p *ngIf="desktopView && !startPhase && !signInOptionsView && loginFailed"
       class="scan-text">{{ 'HEADER.SCAN_FAILED' | translate }}</p>
    <div *ngIf="desktopView && qrCodeData && !startPhase && !signInOptionsView" class="qr-code-wrapper">
      <qrcode [qrdata]="qrCodeData" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
    <div *ngIf="desktopView && !startPhase && !signInOptionsView && loginFailed">
      <button (click)="initBankIDAnimatedQRAuthentication()" style="background-color:#235971; margin: 5px; color: white"
              class="ot-bnr-save-handler">{{ 'BOOK.TRY_AGAIN' | translate }}
      </button>
      <button (click)="closeLoginModal()" style="background-color:white; margin: 5px; color: #235971"
              class="ot-bnr-save-handler">{{ 'COMMON.CANCEL' | translate }}
      </button>
    </div>


    <p *ngIf="!desktopView && !qrCodeData && !startPhase && !signInOptionsView"
       class="scan-text mobile">{{ 'HEADER.SCAN_CODE_MOBILE' | translate }}</p>

    <div class="sign-options-wrapper" *ngIf="signInOptionsView && !startPhase">
      <p
        class="options-title">{{ sesamAccountLogin ? ('HEADER.BUSINESS_CUSTOMER' | translate) : 'HEADER.BANKID_OTHER_DEVICES' | translate }}</p>
      <div *ngIf="!sesamAccountLogin; else accountLogin">
      </div>

      <ng-template #accountLogin>
        <div class="input-field">
          <p class="confirmation-title" style="margin-top: 20px;">{{ 'CONFIRMATION.EMAIL' | translate }}</p>
          <input
            type="text"
            [class]="(loginForm.controls.email.invalid && loginForm.controls.email.touched) || loginForm.hasError('notSameEmail') || loginForm.controls.email.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
            name="email"
            formControlName="email"
            [placeholder]="'COMMON.EMAIL_PLACEHOLDER' | translate"
          >
        </div>

        <div class="input-field">
          <p class="confirmation-title">{{ 'COMMON.PASSWORD' | translate }}:</p>
          <div class="d-flex">
            <input
              [type]="fieldTextType ? 'text' : 'password'"
              [class]="(loginForm.controls.password.invalid && loginForm.controls.password.touched) || loginForm.controls.password.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
              name="password"
              formControlName="password"
              [placeholder]="'COMMON.PASSWORD' | translate"
            >
            <div (click)="fieldTextType = !fieldTextType" class="input-group-append show-password-icon">
              <span class="input-group-text">
                <i class="fa fa-eye-slash"></i>
              </span>
            </div>
          </div>
          <p
            *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched && !loginForm.controls.password.hasError('server')"
            class="confirmation-error">{{ 'BOOK.INVALID_CHARACTER_NUMBER' | translate }} <strong>8</strong>.</p>
          <p *ngIf="loginForm.controls.email.hasError('server') && loginForm.controls.password.hasError('server')"
             class="confirmation-error">{{ 'COMMON.USER_WRONG_PASSWORD' | translate }}</p>
          <div (click)="setPasswordRecoveryPage()" class="forgot-password">{{ 'COMMON.FORGOT_PASSWORD' | translate }}
          </div>
        </div>

        <button (click)="loginSesamAccount()" [disabled]='!loginForm.valid || actionExecuting'
                class="sign-button">{{ 'COMMON.LOGIN' | translate }}
        </button>
        <div (click)="openRegistrationDialog()" class="forgot-password s-forgot-pw">
          <span class="s-text-underline">
            {{ 'COMMON.NEW_ACCOUNT_LOGIN' | translate }}
          </span>
          <span class="font-bold">
            {{ 'COMMON.NEW_ACCOUNT' | translate }}
          </span>
        </div>
      </ng-template>
    </div>
    <p style="color: #e60000; text-align: center; margin-bottom: 1rem;" *ngIf="errorMessage !== ''">
      <strong>Error: {{ errorMessage }}</strong></p>
  </form>
</div>
<div class="s-login-btns-wrapper" *ngIf="startPhase">
  <button class="sign-options" (click)="loginWithQR()">
    <img src="assets/img/bankid_teal.svg" alt="icon">
    <span>
    <span class="font-bold">
      {{ 'COMMON.LOGIN' | translate | uppercase }} {{ 'COMMON.LOGIN_PRIVATKUND' | translate | uppercase }}
    </span>
  </span>
  </button>
  <button class="sign-options" (click)="signInSesamAccount()">
  <span>
    <span class="font-bold">
      {{ 'COMMON.LOGIN' | translate | uppercase }} {{ 'COMMON.LOGIN_FORETAGSKUND' | translate | uppercase }}
    </span>
  </span>
  </button>
</div>
<ng-template #loadingView>
  <app-loading [text]="'HEADER.BANK_ID_INFO'"></app-loading>
</ng-template>

<ng-template #confirmationModal>
  <ng-container *ngIf="!passwordRecoveryView; else passwordResetView">
    <img class="previous-button" (click)="closeRegistrationDialog()" src="assets/img/account/arrow_back.png">
    <div class="login-wrapper">
      <form class="login-form" [formGroup]="form" #f="ngForm">
        <img style="margin-top: -10px; margin-bottom: 40px;" src="assets/img/home/sesam-logo.svg">
        <div class="sign-options-wrapper">
          <p class="code-title">{{ 'CONFIRMATION.WELCOME_SESAM' | translate }}
            <br/>{{ 'CONFIRMATION.PROVIDE_INFORMATIONS' | translate }}</p>

          <div class="input-field">
            <p class="confirmation-title">{{ 'CONFIRMATION.EMAIL' | translate }}</p>
            <input
              type="text"
              [class]="(form.controls.email.invalid && form.controls.email.touched) || form.hasError('notSameEmail') || form.controls.email.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
              name="email"
              formControlName="email"
              [placeholder]="'COMMON.EMAIL_PLACEHOLDER' | translate"
            >
          </div>

          <div class="input-field">
            <p class="confirmation-title">{{ 'CONFIRMATION.VERIFY_EMAIL' | translate }}</p>
            <input
              type="text"
              [class]="(form.controls.emailConfirm.invalid && form.controls.emailConfirm.touched) || form.hasError('notSameEmail') || form.controls.email.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
              name="emailConfirm"
              formControlName="emailConfirm"
              [placeholder]="'COMMON.EMAIL_PLACEHOLDER' | translate"
            >
            <p *ngIf="form.hasError('notSameEmail')"
               class="confirmation-error">{{ 'CONFIRMATION.NOT_SAME_EMAIL' | translate }}</p>
            <p *ngIf="form.controls.email.hasError('server')"
               class="confirmation-error">{{ 'CONFIRMATION.EMAIL_USED' | translate }}</p>
            <p *ngIf="form.controls.email.hasError('userExist')"
               class="confirmation-error">{{ 'CONFIRMATION.SSN_EXIST' | translate }}</p>
          </div>
          <div *ngIf="shouldCompanyBeRegistered" class="input-field">
            <p class="confirmation-title">{{ 'COMMON.PASSWORD' | translate }}:</p>
            <div class="d-flex">
              <input
                [type]="fieldTextType1 ? 'text' : 'password'"
                [class]="(form.controls.password.invalid && form.controls.password.touched) || form.controls.password.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
                name="password"
                formControlName="password"
                [placeholder]="'COMMON.PASSWORD' | translate"
              >
              <div (click)="fieldTextType1 = !fieldTextType1" class="input-group-append show-password-icon">
              <span class="input-group-text">
                <i class="fa fa-eye-slash"></i>
              </span>
              </div>
            </div>
            <p
              *ngIf="form.controls.password.invalid && form.controls.password.touched && !form.controls.password.hasError('server')"
              class="confirmation-error">{{ 'COMMON.PASSWORD_IS_NOT_IN_VALID_FORMAT' | translate }}</p>
            <p *ngIf="form.controls.email.hasError('server') && form.controls.password.hasError('server')"
               class="confirmation-error">{{ 'COMMON.USER_WRONG_PASSWORD' | translate }}</p>
          </div>
          <div *ngIf="shouldCompanyBeRegistered" class="input-field">
            <p class="confirmation-title">{{ 'COMMON.PASSWORD_VERIFY' | translate }}:</p>
            <div class="d-flex">
              <input
                [type]="fieldTextType2 ? 'text' : 'password'"
                [class]="(form.controls.passwordVerify.invalid && form.controls.passwordVerify.touched) || form.controls.passwordVerify.hasError('server') ? 'error-confirmation-input' : 'confirmation-input'"
                name="passwordVerify"
                formControlName="passwordVerify"
                [placeholder]="'COMMON.PASSWORD_VERIFY' | translate"
              >
              <div (click)="fieldTextType2 = !fieldTextType2" class="input-group-append show-password-icon">
              <span class="input-group-text">
                <i class="fa fa-eye-slash"></i>
              </span>
              </div>
            </div>
            <p *ngIf="form.hasError('notSamePassword')"
               class="confirmation-error">{{ 'COMMON.SAME_PASSWORD' | translate }}</p>
            <p
              *ngIf="form.controls.passwordVerify.invalid && form.controls.passwordVerify.touched && !form.controls.passwordVerify.hasError('server')"
              class="confirmation-error">{{ 'COMMON.PASSWORD_IS_NOT_IN_VALID_FORMAT' | translate }}</p>
            <p *ngIf="form.controls.email.hasError('server') && form.controls.password.hasError('server')"
               class="confirmation-error">{{ 'COMMON.USER_WRONG_PASSWORD' | translate }}</p>
          </div>
          <div class="line-break"></div>
          <div *ngIf="registerCompany && shouldCompanyBeRegistered" class="input-field d-flex">
            <div class="name-field">
              <p class="confirmation-title">{{ 'COMMON.FIRST_NAME' | translate }}</p>
              <input
                type="text"
                [class]="(form.controls.firstName.invalid && form.controls.firstName.touched) ? 'error-confirmation-name-input' : 'confirmation-input name-field'"
                name="firstName"
                formControlName="firstName"
                [placeholder]="'COMMON.FIRST_NAME' | translate"
              >
            </div>

            <div class="last-name-field">
              <p class="confirmation-title ml10">{{ 'COMMON.LAST_NAME' | translate }}</p>
              <input
                type="text"
                [class]="(form.controls.lastName.invalid && form.controls.lastName.touched) ? 'error-confirmation-name-input' : 'confirmation-input last-name-field'"
                name="lastName"
                formControlName="lastName"
                [placeholder]="'COMMON.LAST_NAME' | translate"
              >
            </div>
          </div>

          <div class="input-field">
            <p class="confirmation-title">{{ 'CONFIRMATION.PHONE_NUMBER' | translate }}</p>
            <ngx-intl-tel-input
              [cssClass]="(form.controls.phoneNumber.invalid && form.controls.phoneNumber.touched) || form.hasError('notSamePhoneNumber') || form.controls.phoneNumber.hasError('server') || form.hasError('notValidLength') ? 'error-phone-number' : 'phone-number'"
              [onlyCountries]="onlyCountries"
              [enableAutoCountrySelect]="true"
              [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.Sweden"
              [maxLength]="'15'"
              [customPlaceholder]="'701 23 45 67'"
              [phoneValidation]="false"
              [numberFormat]="PhoneNumberFormat.International"
              [separateDialCode]="true"
              name="phoneNumber"
              formControlName="phoneNumber">
            </ngx-intl-tel-input>
          </div>

          <div class="input-field">
            <p class="confirmation-title">{{ 'CONFIRMATION.VERIFY_PHONE_NUMBER' | translate }}</p>
            <ngx-intl-tel-input
              [cssClass]="(form.controls.phoneNumberConfirm.invalid && form.controls.phoneNumberConfirm.touched) || form.hasError('notSamePhoneNumber') || form.controls.phoneNumber.hasError('server') || form.hasError('notValidLength') ? 'error-phone-number' : 'phone-number'"
              [onlyCountries]="onlyCountries"
              [enableAutoCountrySelect]="true"
              [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.Sweden"
              [customPlaceholder]="'701 23 45 67'"
              [maxLength]="'15'"
              [phoneValidation]="false"
              [numberFormat]="PhoneNumberFormat.International"
              [separateDialCode]="true"
              name="phoneNumberConfirm"
              formControlName="phoneNumberConfirm">
            </ngx-intl-tel-input>
            <p *ngIf="form.hasError('notSamePhoneNumber')"
               class="confirmation-error">{{ 'CONFIRMATION.NOT_SAME_PHONE_NUMBER' | translate }}</p>
            <p *ngIf="form.controls.phoneNumber.hasError('server')"
               class="confirmation-error">{{ 'CONFIRMATION.PHONE_NUMBER_USED' | translate }}</p>
          </div>
          <div *ngIf="shouldCompanyBeRegistered" class="line-break"></div>

          <div *ngIf="registerCompany && shouldCompanyBeRegistered" class="input-field">
            <p class="confirmation-title">{{ 'CONFIRMATION.ORGANIZATION_NUMBER' | translate }}</p>
            <input type="text"
                   [class]="(form.controls.organizationNumber.invalid && form.controls.organizationNumber.touched) || form.controls.organizationNumber.hasError('server')  ? 'error-confirmation-input' : 'confirmation-input'"
                   name="organizationNumber"
                   formControlName="organizationNumber"
                   placeholder="{{ 'CONFIRMATION.ORGANIZATION_NUMBER' | translate }}">
            <p *ngIf="form.controls.organizationNumber.hasError('server') || form.controls.organizationNumber.invalid"
               class="confirmation-error">{{ 'CONFIRMATION.ORGANIZATION_NUMBER_NOT_VALID' | translate }}</p>
          </div>

          <p style="color: #e60000; text-align: center; margin-bottom: 1rem;" *ngIf="errorMessage !== ''">
            <strong>Error: {{ errorMessage | translate }}</strong></p>

          <button class="sign-button"
                  (click)="shouldCompanyBeRegistered ? registerBusinessUser() : confirmAuthentication()"
                  style="margin-top: 20px; margin-bottom: 0px;"
                  [disabled]='!form.valid || actionExecuting'>{{ 'CONFIRMATION.CONFIRM' | translate }}
          </button>
        </div>
      </form>
    </div>
  </ng-container>
</ng-template>

<ng-template #passwordResetView>
  <img class="previous-button" (click)="passwordRecoveryView = false" src="assets/img/account/arrow_back.png">
  <div class="login-wrapper">
    <form *ngIf="!loading" class="login-form" [formGroup]="loginForm" #f="ngForm">
      <img style="margin-bottom: 40px;" src="assets/img/home/sesam-logo.svg">
      <div class="sign-options-wrapper">
        <p class="code-title">{{ 'ACCOUNT.PASSWORD_RECOVERY_TEXT' | translate }}</p>
        <div class="input-field">
          <input
            type="text"
            [class]="(loginForm.controls.email.invalid && loginForm.controls.email.touched) ? 'error-confirmation-input' : 'confirmation-input'"
            name="email"
            formControlName="email"
            [placeholder]="'COMMON.EMAIL_PLACEHOLDER' | translate">
        </div>
      </div>
      <button (click)="passwordRecover()" [disabled]="!loginForm.controls.email.valid" class="login-button"
              style="margin-top: 20px; margin-bottom: 0px;">{{ 'ACCOUNT.RESET_PASSWORD' | translate }}
      </button>
    </form>
    <app-loading *ngIf="loading" [text]="'COMMON.SENDING_EMAIL'"></app-loading>
  </div>
</ng-template>
