import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import { ExternalLinkService } from 'src/app/core/services/external-link.service';
import {AuthenticationService} from '../../core/services/authentication.service';
import { ObservableService } from 'src/app/core/services/observable.service';
import { ExternalLink } from 'src/app/user/models/external-link';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  public resizeEvent: boolean;
  urls: ExternalLink[] = [];
  agreementUrl: string;
  integrityPolicyUrl: string;
  faqUrl: string;

  // tslint:disable-next-line:max-line-length
  constructor(private externalLinkService: ExternalLinkService, private observableService: ObservableService, private authService: AuthenticationService) {
    this.resizeEvent = window.innerWidth <= 767;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event): void {
    this.resizeEvent = event.target && event.target.innerWidth <= 830;
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  scrollToTop() {
    this.observableService.scrollTop();
  }
  ngOnInit() {
    this.externalLinkService.agreementUrl$.subscribe(url => this.agreementUrl = url);
    this.externalLinkService.integrityPolicyUrl$.subscribe(url => this.integrityPolicyUrl = url);
    this.externalLinkService.faqUrl$.subscribe(url => this.faqUrl = url);
  }

  public getYear() {
    return new Date().getFullYear();
  }
}
