<div class="calculator-wrap">

  <div [hidden]="!loaded" class="storage-calculator">
    <iframe style="border: 0px #ffffff none;" (load)="loadedIframe()"
            src="https://6storage.com/space-calculator?ClientId=a5427114-5d33-4728-a0c5-a9cf3d06ef07"
            name="Space Calculator" width="100%" height="925" frameborder="0" marginwidth="0px"
            marginheight="0px"></iframe>
  </div>

  <div *ngIf="!loaded" class="loading-view">
    <app-loading></app-loading>
  </div>
</div>


