import {Component, Input, ViewEncapsulation} from '@angular/core';
import { LocationConfig } from 'src/app/core/models/locations.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
  currentServer = window.location.origin;
  @Input() config: LocationConfig;
  constructor() {
  }
}
