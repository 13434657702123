import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { RestService } from './rest.service';
import { BehaviorSubject } from 'rxjs';
import { ExternalLink } from 'src/app/user/models/external-link';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class ExternalLinkService extends RestService {
  basePath = 'externallink';
  apiPath = '';
  version = '';
  urls$ = new BehaviorSubject<any>({ urls: [{ key: '', link: '' }] });
  agreementUrl$ = new BehaviorSubject<string>('');
  integrityPolicyUrl$ = new BehaviorSubject<string>('');
  faqUrl$ = new BehaviorSubject<string>('');
  constructor(http: HttpClient, configService: ConfigService) {
    super(http, configService);
  }

  getExternalLinks(): void {
    this.http
      .get()
      .pipe(
        distinctUntilChanged(),
        map((data: any) => {
          this.urls$.next(data.urls);

          this.agreementUrl$.next(
            data.urls.find((u) => u.key === 'AGREEMENT').link
          );
          this.integrityPolicyUrl$.next(
            data.urls.find((u) => u.key === 'INTEGRITY_POLICY').link
          );
          this.faqUrl$.next(data.urls.find((u) => u.key === 'FAQ').link);
        })
      )
      .subscribe();
  }
}
