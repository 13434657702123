import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import get from 'lodash/get';

@Injectable()
export class ConfigService {
  config: any;
  baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  get baseUrlParam() {
    return this.baseUrl;
  }

  get(path) {
    return path ? get(this.config, path) : this.config;
  }
}
