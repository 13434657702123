import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {RestService} from './rest.service';
import {Observable} from 'rxjs';

@Injectable()
export class IpService extends RestService {
  basePath = '';
  apiPath = '';
  version = '';

  constructor(http: HttpClient,
              configService: ConfigService) {
    super(http, configService);
  }

  getMyIp(): Observable<any> {
    return this.http.get('my-ip');
  }
}
