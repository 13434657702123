import {Component} from '@angular/core';

@Component({
  templateUrl: './notFound.component.html'
})
export class NotFoundComponent {
  constructor() {
    window.location.href = 'https://sesamselfstorage.se/not-found';
  }
}
