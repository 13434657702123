import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {BadgeComponent} from './badge/badge.component';
import {LoadingComponent} from './loading/loading.component';

@NgModule({
    imports: [
        TranslateModule,
        CommonModule
    ],
  exports: [
    LoadingComponent, BadgeComponent
  ],
  declarations: [LoadingComponent, BadgeComponent]
})
export class SharedModule {}
