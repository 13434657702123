import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LanguageService} from '../../../core/services/language.service';


@Component({
  selector: 'app-storage-calculator',
  templateUrl: './storage-calculator.component.html',
  styleUrls: ['./storage-calculator.component.scss']
})
export class StorageCalculatorComponent implements OnInit {
  calculationView = false;
  apartmentSize: string;
  calculationValue: string;
  currentMoreIndex: number;
  public inputInvalid = false;

  constructor(private route: ActivatedRoute, private languageService: LanguageService) {
    // this.route.paramMap.subscribe(params => {
    //   const language = params.get('lang');
    //   if (language) {
    //     this.languageService.setLanguage(language);
    //   } else {
    //     this.languageService.setLanguage('en');
    //   }
    // });
  }

  ngOnInit() {
  }

  goToBook() {
    const currentURL = document.referrer;
    window.open('https://app.sesamselfstorage.se/hyra-forrad=' + currentURL);
  }

  calculateValue() {
    const size = parseInt(this.apartmentSize, 0);
    if (isNumeric(size)) {
      this.calculationView = true;
      this.inputInvalid = false;
      if (size < 15) {
        this.calculationValue = '1-1.5';
      } else if (size > 14 && size < 22) {
        this.calculationValue = '1.5-2';
      } else if (size >= 22 && size < 31) {
        this.calculationValue = '2.25-3.5';
      } else if (size >= 31 && size < 46) {
        this.calculationValue = '3.5-4.5';
      } else if (size >= 46 && size < 61) {
        this.calculationValue = '4.5-6';
      } else if (size >= 61 && size < 91) {
        this.calculationValue = '6-9';
      } else if (size >= 91 && size < 121) {
        this.calculationValue = '9-12';
      } else if (size >= 121 && size < 150) {
        this.calculationValue = '12-15';
      } else if (size >= 150 && size < 250) {
        this.calculationValue = '15-30';
      } else {
        this.calculationValue = '30+';
      }
    } else {
      this.inputInvalid = true;
    }
  }
}

function isNumeric(num) {
  return !isNaN(num);
}

