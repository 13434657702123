import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, interval, Observable, of, Subscription} from 'rxjs';
import {
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';

import get from 'lodash/get';

import {
  BookingService,
  MoveInOptions,
  SignDateOptions,
} from '../../../core/services/booking.service';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {StorageUnitService} from '../../../core/services/storage-unit.service';
import {UnitInfo} from '../../../core/models/unit-info';
import {MoveInModel} from '../../models/move-in-model';
import {HttpErrorResponse} from '@angular/common/http';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {PriceDetails} from '../../models/price-details';
import {RegisterAndSignOptionsModel} from '../../models/register-and-sign-options-model';
import {SessionService} from '../../../core/services/session.service';
import {ExternalLinkService} from 'src/app/core/services/external-link.service';
import {ExternalLink} from '../../models/external-link';
import {ObservableService} from 'src/app/core/services/observable.service';
import {AnalyticsService} from 'src/app/core/services/analytics.service';

enum PaymentMethod {
  Card = 'Card',
  Invoice = 'Invoice',
}

@Component({
  selector: 'app-user-rent',
  templateUrl: './rent.component.html',
  styleUrls: ['./rent.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RentComponent implements OnInit, OnDestroy {
  @ViewChild('verificationModal', null)
  verificationModal: NgxSmartModalComponent;
  @ViewChild('companyVerificationModal', null)
  companyVerificationModal: NgxSmartModalComponent;
  @ViewChild('creditCheckModal', null) creditCheckModal: NgxSmartModalComponent;
  @ViewChild('confirmationModal', null)
  confirmationModal: NgxSmartModalComponent;
  @ViewChild('iframe', {static: false}) iframe: ElementRef;

  public fieldTextType1: boolean;
  public fieldTextType2: boolean;
  unitId: string;
  unit: UnitInfo;
  dateFrom: string;
  paymentFormLink: string;
  bookingId: string;
  unitName: string;
  loggedInSubscription: Subscription;
  creditValidCompany: boolean;
  signUnitResponse: any;
  signDate: string;
  personalNumber: string;
  orderReference: string;
  loginOptions: any;
  actionExecuting = false;
  city: string;
  companyName: string;
  user: any;
  maxDate: Date;
  minDate: Date;
  paymentTransactionResponse: any;
  email = '';
  phoneNumber = '';
  currentStep = 1;
  termsConditionsChecked = false;
  integrityPolicyChecked = false;
  withdrawalPolicyChecked = false;
  modalOpened = false;
  paymentLoading = true;
  paymentFinalizing = false;
  loaded = false;
  more = false;
  currentInsuranceId = '';
  registerCompany = false;
  success = false;
  signOptions = false;
  PaymentMethod = PaymentMethod;
  paymentMethod = PaymentMethod.Card;
  companyVerifying = false;
  creditChecking = false;
  confirmation = false;
  sesamAccount = false;
  verificationFormClear = false;
  CountryISO = CountryISO;
  onlyCountries = [
    CountryISO.Sweden,
    CountryISO.Norway,
    CountryISO.Denmark,
    CountryISO.Finland,
  ];
  PhoneNumberFormat = PhoneNumberFormat;
  form = new FormGroup({
    date: new FormControl('', [Validators.required]),
    terms: new FormControl('', [Validators.required]),
    integrityPolicy: new FormControl('', [Validators.required]),
    withdrawalPolicy: new FormControl('', [Validators.required]),
    insurance: new FormControl('', [Validators.required]),
    organizationNumber: new FormControl('', []),
    paymentMethod: new FormControl('', []),
    registerCompany: new FormControl('', []),
  });
  confirmationForm = new FormGroup(
    {
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/\S+@\S+\.\S+/),
      ]),
      emailConfirm: new FormControl('', [
        Validators.required,
        Validators.pattern(/\S+@\S+\.\S+/),
      ]),
      phoneNumber: new FormControl('', [Validators.required]),
      phoneNumberConfirm: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,12}$/),
      ]),
      passwordVerify: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,12}$/),
      ]),
      organizationNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([0-9]{6}-[0-9]{4})$|^([0-9]{10})$/),
      ]),
    },
    {
      validators: [this.checkEmail, this.checkPhoneNumber, this.checkPassword],
    }
  );
  public authenticationSpinner = false;
  public unitNumber: string;
  public leaseId: string;
  public authStarted: boolean;
  public priceDetails$: Observable<{
    toPayNow: PriceDetails;
    nextPayment: PriceDetails;
    regularMonthly: PriceDetails;
  }>;
  public bankIdSubscription: Subscription;
  public shouldCompanyBeRegistered = false;
  public urls: ExternalLink[] = [];
  private sixStorageTransactionId: string;
  private pollingPaymentStatus:
    | 'SUCCESS'
    | 'FAILED'
    | 'NOT_INITIALIZED'
    | 'IN_PROGRESS';
  private paymentFormStatus: string;
  private timeInterval: Subscription;
  agreementUrl: string;
  integrityPolicyUrl: string;
  locationName: string;

  checkEmail(group: FormGroup) {
    const email = group.get('email').value;
    const emailConfirm = group.get('emailConfirm').value;

    if (email === '' || emailConfirm === '') {
      return null;
    }

    return email === emailConfirm ? null : {notSameEmail: true};
  }

  checkPhoneNumber(group: FormGroup) {
    const phoneNumber = group.get('phoneNumber').value;
    const phoneNumberConfirm = group.get('phoneNumberConfirm').value;

    const phoneNumberE164Format = get(phoneNumber, 'e164Number');
    const phoneNumberConfirmE164Format = get(phoneNumberConfirm, 'e164Number');

    if (!phoneNumberE164Format || !phoneNumberConfirmE164Format) {
      return null;
    }

    if (phoneNumberE164Format === phoneNumberConfirmE164Format) {
      return phoneNumberE164Format.length > 10 &&
      phoneNumberE164Format.length < 14
        ? null
        : {notValidLength: true};
    } else {
      return {
        notSamePhoneNumber: true,
      };
    }
  }

  checkPassword(group: FormGroup) {
    const password = group.get('password').value;
    const passwordVerify = group.get('passwordVerify').value;

    if (password === '' || passwordVerify === '') {
      return null;
    }

    return password === passwordVerify ? null : {notSamePassword: true};
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bookingService: BookingService,
    private authService: AuthenticationService,
    private toastrService: ToastrService,
    private storageUnitService: StorageUnitService,
    private translateService: TranslateService,
    private renderer: Renderer2,
    private externalLinkService: ExternalLinkService,
    private observableService: ObservableService,
    public analytics: AnalyticsService
  ) {
    this.renderer.listen('window', 'message', this.onMessage.bind(this));
    this.loggedInSubscription = this.authService.loggedIn$.subscribe(
      (loggedIn) => {
        if (loggedIn) {
          this.getDataWithUserInfo(false);
        }
      }
    );
  }

  ngOnInit() {
    this.unitId = this.route.snapshot.paramMap.get('id');
    if (this.unitId) {
      if (this.authService.isLoggedIn) {
        this.getDataWithUserInfo(true);
      } else {
        this.getUnit();
      }
    } else {
      return this.router.navigate(['search']);
    }

    this.observableService.currentLocationName$.subscribe(
      (name) => (this.locationName = name)
    );

    this.externalLinkService.agreementUrl$.subscribe(
      (url) => (this.agreementUrl = url)
    );
    this.externalLinkService.integrityPolicyUrl$.subscribe(
      (url) => (this.integrityPolicyUrl = url)
    );
  }

  ngOnDestroy() {
    localStorage.removeItem('bookId');
    this.loggedInSubscription.unsubscribe();
    if (this.timeInterval) {
      this.timeInterval.unsubscribe();
    }
  }

  loadDone() {
    setTimeout(() => {
      this.paymentLoading = false;
    }, 4000);
  }

  onMessage(event): void {
    if (
      event.origin.indexOf('localhost') > -1 ||
      event.origin.indexOf('8storage') > -1 ||
      event.origin.indexOf('6storage') > -1
    ) {
      const data = event.data;
      if (data.func === 'ReceiveIframeResponse' || data.paymentStatus) {
        const response = data.paymentStatus ? data : JSON.parse(data.message);

        this.paymentFormStatus = response.paymentStatus;

        if ((response.paymentStatus === 'Success' || response.paymentStatus === 'Ready') && this.pollingPaymentStatus !== 'SUCCESS') {
          this.paymentTransactionResponse = response;
          this.successPayment('card');
        } else {
          // GA4 this.tagManagerService.registerEvent('card_payment_failed');
          this.errorPayment(this.translateService.instant('BOOK.PAYMENT_FAIL'));
        }
      }
    }
  }

  getDataWithUserInfo(getUnit: boolean) {
    this.authService.getMyInfo().subscribe((res) => {
      const user = get(res, 'user');
      this.user = user;

      if (user) {
        const isBusinessUser = user.isBusinessUser;

        if (isBusinessUser) {
          this.registerCompany = true;
          this.form.controls['registerCompany'].disable();
        } else {
          this.registerCompany = false;
          this.form.controls['registerCompany'].disable();
        }
      } else {
        this.registerCompany = false;
        this.form.controls['registerCompany'].enable();
      }

      if (getUnit) {
        return this.getUnit();
      }
    });
  }

  getUnit() {
    this.storageUnitService.getUnitById(this.unitId).subscribe((res) => {
      this.unit = res;
      this.analytics.setUnitSpecs({
        unit_id: res.unitId,
        unit_type_name: res.unitTypeName,
        unit_size: res.size + ' m2',
        unit_number: res.unitNumber,
      });

      if (this.unit && this.unit.insurances) {
        this.unit.insurances.sort((a, b) => {
          return a.coverage - b.coverage;
        });
        this.currentInsuranceId = this.unit.insurances[0].id;
      } else {
        this.unit.insurances = [];
      }

      this.maxDate = new Date(this.unit.maxDateTime);

      this.minDate = new Date(this.unit.minDateTime);
      this.dateFrom = this.unit.minDateTime;

      this.calculatePrices();
      this.loaded = true;
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private successPayment(paymentMethod: 'card' | 'invoice') {
    this.paymentFinalizing = true;
    if (
      this.bookingId &&
      this.bookingId.toString() === localStorage.getItem('bookId')
    ) {
      // GA4 this.tagManagerService.registerEvent(paymentMethod + '_payment_successful');
      this.analytics.event('checkout_purchase_successful', {
        checkout_step: this.currentStep,
      });

      const moveInOptions: MoveInOptions = {
        bookingId: this.bookingId,
        isPaymentSuccess: true,
        paymentTransactionResponse: this.paymentTransactionResponse,
      };

      this.bookingService.moveIn(moveInOptions).subscribe(
        (res: MoveInModel) => {
          this.success = true;
          this.currentStep = 3;
          this.paymentFinalizing = false;
          this.unitNumber = res.unitNumber;
          this.leaseId = res.bookingId;
          /* GA4 this.tagManagerService.registerEvent(
            paymentMethod + "_booking_completed"
          );*/
          this.analytics.event('checkout_booking_completed', {
            checkout_step: this.currentStep,
          });
        },
        () => {
          // GA4 this.tagManagerService.registerEvent(paymentMethod + '_booking_failed');
        }
      );
    }
  }

  private errorPayment(errorMessage: string) {
    // GA4: this.tagManagerService.registerEvent("card_payment_failed");
    const moveInOptions: MoveInOptions = {
      bookingId: this.bookingId,
      isPaymentSuccess: false,
    };

    this.bookingService.moveIn(moveInOptions).subscribe(
      () => {
      },
      () => {
        this.success = false;
        this.currentStep = 3;
        // GA4: this.tagManagerService.registerEvent("card_booking_failed");
        this.toastrService.error(errorMessage);
      }
    );
  }

  addBookingName() {
    this.bookingService
      .addName(this.unitName, this.leaseId)
      .subscribe(() => this.nextPage());
  }

  nextPage() {
    if (this.authService.isLoggedIn) {
      // Regular router.navigate does not navigate to user/account page on first time for some reason
      // This hack force navigation :D
      return this.router
        .navigate([''])
        .then(() => this.router.navigate(['/user']))
        .then(() => this.router.navigate(['/user/account']));
    } else {
      return this.router.navigate(['user/hyra-forrad']);
    }
  }

  cancel() {
    return this.authService.isLoggedIn
      ? this.router.navigate(['user/hyra-forrad', this.locationName])
      : this.router.navigate(['hyra-forrad', this.locationName]);
  }

  public async signUnitWithAnimatedQRBankId(user) {
    this.loginOptions = user;

    const date: Date = new Date(this.dateFrom);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    this.signDate = new Date(date.getTime() - userTimezoneOffset).toISOString();

    this.authStarted = false;

    this.verificationModal.close();

    if (this.loginOptions.isNewUser) {
      this.confirmationForm.reset();
      this.confirmationModal.open();
      return;
    }

    await this.signing();
  }

  async confirm() {
    this.phoneNumber = this.confirmationForm.value.phoneNumber.e164Number;
    this.email = this.confirmationForm.get('email').value;
    this.confirmation = true;
    this.personalNumber = this.loginOptions.personalNumber;

    if (this.confirmationForm.valid) {
      this.signing();
    }
  }

  private async signing() {
    this.actionExecuting = true;
    const noInsuranceId = get(this.unit, 'insurances.0.id');
    const signDateOptions: SignDateOptions = {
      unitId: this.unit.unitId,
      moveInDate: this.signDate,
      insuranceId: this.currentInsuranceId || noInsuranceId,
      email: this.email || this.loginOptions.email,
      password: get(this.loginOptions, 'password'),
      phoneNumber: this.phoneNumber || this.loginOptions.phoneNumber,
      paymentMethod: this.paymentMethod === PaymentMethod.Card ? 0 : 1,
      organizationNumber: null,
      isBusinessUser: false,
      personalNumber: this.personalNumber,
      isNewUser: this.loginOptions ? this.loginOptions.isNewUser : true,
      firstName: this.loginOptions ? this.loginOptions.firstName : '',
      lastName: this.loginOptions ? this.loginOptions.lastName : '',
      city: this.city,
      companyName: this.companyName,
      language: this.translateService.currentLang === 'sv' ? 'sv-SE' : 'en-US',
    };

    this.bookingService.signDate(signDateOptions).subscribe(
      async (res) => {
        this.actionExecuting = false;
        this.signUnitResponse = res;
        this.priceDetails$ = of(res.pricingDetails);

        if (this.confirmation) {
          this.confirmationModal.close();
          this.confirmationForm.reset();
        }

        await this.finishSigning(res);
      },
      (errorResponse) => {
        this.actionExecuting = false;
        this.modalOpened = false;

        if (this.confirmation) {
          const errorMessage =
            errorResponse.error.ErrorMessage || errorResponse.error[0];
          this.handleErrorMessage(errorMessage);
        } else {
          this.verificationModal.close();
          if (
            errorResponse.error.ErrorMessage.includes(
              'This unit is not available in PMS'
            )
          ) {
            this.modalOpened = false;
            this.verificationModal.close();
            this.currentStep = 4;
          }
        }
      }
    );
  }

  private handleErrorMessage(errorMessage: string): void {
    switch (errorMessage) {
      case 'EMAIL_USED_IN_SYSTEM':
        this.confirmationForm.get('email').setErrors({
          server: {message: errorMessage},
        });
        this.raiseError('COMMON.EMAIL_USED_IN_SYSTEM');
        break;
      case 'PERSONAL_NUMBER_USED_IN_SYSTEM':
        this.raiseError('COMMON.PERSONAL_NUMBER_USED_IN_SYSTEM');
        break;
      case 'CREDIT_CHECK_FAILED_MESSAGE':
        this.raiseError('BOOK.CREDIT_CHECK_FAILED_MESSAGE');
        break;
      case 'INVALID_USERNAME_OR_PASSWORD':
        this.raiseError('COMMON.USER_OR_PASSWORD_WRONG');
        break;
      case 'USER_DOESNT_EXIST_IN_THE_SYSTEM':
        this.raiseError('COMMON.USER_OR_PASSWORD_WRONG');
        break;
      case 'ORG_NUMBER_USED_IN_SYSTEM':
        this.raiseError('COMMON.ORG_NUMBER_USED_IN_SYSTEM');
        break;
      case 'PHONE_NUMBER_USED_IN_SYSTEM':
        this.confirmationForm.get('phoneNumber').setErrors({
          server: {message: errorMessage},
        });
        this.raiseError('COMMON.PHONE_NUMBER_USED_IN_SYSTEM');
        break;
      case 'PASSWORD_IS_NOT_IN_VALID_FORMAT':
        this.confirmationForm.get('password').setErrors({
          server: {message: errorMessage},
        });
        this.confirmationForm.get('passwordVerify').setErrors({
          server: {message: errorMessage},
        });
        this.raiseError('COMMON.PASSWORD_IS_NOT_IN_VALID_FORMAT');
        break;

        default:
        this.raiseError('COMMON.SOMETHING_WENT_WRONG');
        break;
    }
  }

  private raiseError(messageKey: string) {
    this.toastrService.error(this.translateService.instant(messageKey), '', {
      timeOut: 10000,
    });
  }

  private async finishSigning(response) {
    /* GA4 this.tagManagerService.registerEvent(
      this.registerCompany
        ? "sign_agreement_finished_b2b"
        : "sign_agreement_finished_regular",
      { bookingId: response.bookingId }
    );*/
    this.modalOpened = false;
    this.verificationModal.close();
    this.companyVerificationModal.close();
    this.currentStep = 2;

    this.bookingId = response.bookingId;
    localStorage.setItem('bookId', this.bookingId.toString());

    if (!this.authService.isLoggedIn && response.token) {
      this.authService.tokenSideAffects(response.token);
      localStorage.setItem(
        '_expiredTime',
        (Date.now() + SessionService.sessionExpiration).toString()
      );
    }

    if (
      !this.registerCompany ||
      (this.registerCompany && this.paymentMethod === PaymentMethod.Card)
    ) {
      this.sixStorageTransactionId = response.sixStorageTransactionID;
      this.paymentFormLink = response.paymentForm;

      this.startPaymentPolling();
    }
  }

  async verifyCompany() {
    this.analytics.event('checkout_booking_started', {
      checkout_step: this.currentStep,
    });
    // GA 4this.tagManagerService.registerEvent("sign_agreement_started_b2b");
    if (this.form.valid) {
      this.sesamAccount = true;
      this.modalOpened = true;
      this.verificationFormClear = false;
      this.signOptions = true;
      this.verificationModal.open();
    } else {
      this.validateAllFormFields(this.form);
      document.querySelector('#unitList').scrollIntoView();
    }
  }

  validateForm(signOptions: boolean) {
    this.analytics.event('checkout_booking_started', {
      checkout_step: this.currentStep,
    });
    // GA4 this.tagManagerService.registerEvent("sign_agreement_started_regular");
    this.removeBusinessValidators();
    if (this.form.valid) {
      this.modalOpened = true;
      this.signOptions = signOptions;
      this.verificationFormClear = false;
      this.sesamAccount = false;
      this.verificationModal.open();
    } else {
      this.validateAllFormFields(this.form);
      document.querySelector('#unitList').scrollIntoView();
    }
  }

  private handleSignUnitResponse(): void {
    if (this.loginOptions && (!this.user || this.loginOptions.email === this.user.email)) {
      if (this.loginOptions.isNewUser) {
        this.verificationModal.close();
        this.confirmationForm.reset();
        this.confirmationModal.open();
      } else {
        this.signing();
      }
    } else {
      this.handleErrorSignWithSameUser('COMMON.USER_NOT_SAME');
    }
  }

  private handleErrorSignWithSameUser(message: string): void {
    this.toastrService.error(
      this.translateService.instant(message),
      this.translateService.instant('COMMON.ERROR'),
      {timeOut: 10000}
    );
    this.verificationModal.close();
    this.modalOpened = false;
    this.verificationFormClear = true;
  }

  checkBankIdOnfocus($event: { orderReference: string }) {
    const date: Date = new Date(this.dateFrom);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;

    this.signDate = new Date(date.getTime() - userTimezoneOffset).toISOString();
    this.authService
      .bankId({
        orderReference: $event.orderReference,
        polling: false,
      })
      .subscribe(
        (loginOptions) => {
          this.loginOptions = loginOptions;
          this.authStarted = false;
          if (loginOptions.isNewUser) {
            this.confirmation = true;
          }
          this.handleSignUnitResponse();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 500) {
            this.toastrService.error(
              this.translateService.instant('COMMON.SOMETHING_WENT_WRONG')
            );
            this.authStarted = false;
          }
        }
      );
  }

  private calculatePrices() {
    const dateValue$ = this.form
      .get('date')
      .valueChanges.pipe(startWith(this.dateFrom), distinctUntilChanged());
    const insuranceValue$ = this.form
      .get('insurance')
      .valueChanges.pipe(
        startWith(this.currentInsuranceId),
        distinctUntilChanged()
      );
    const isCompanyValue$ = this.form
      .get('registerCompany')
      .valueChanges.pipe(startWith(false), distinctUntilChanged());

    this.priceDetails$ = combineLatest([
      dateValue$,
      insuranceValue$,
      isCompanyValue$,
    ]).pipe(
      filter(([date, insurance]) => {
        return !!date && !!insurance && this.currentStep !== 2;
      }),
      map(([date, insurance, isCompany]) => {
        const dateForSign: Date = new Date(date);
        const userTimezoneOffset = dateForSign.getTimezoneOffset() * 60000;

        const formattedDate = new Date(
          dateForSign.getTime() - userTimezoneOffset
        ).toISOString();
        return this.user && this.user.isBusinessUser
          ? [formattedDate, insurance, true]
          : [formattedDate, insurance, isCompany];
      }),
      switchMap(([date, insurance, isCompany]) =>
        this.bookingService.calculatePrices(
          this.unitId,
          insurance,
          date,
          !!isCompany
        )
      )
    );
  }

  public backToFirstStep() {
    this.currentStep = 1;
    this.sesamAccount = false;
    this.loginOptions = null;
    this.resetPaymentFormStatus();
    this.calculatePrices();
  }

  public registerCompanyAndSign(login: { email: string; password: string }) {
    const date: Date = new Date(this.dateFrom);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;

    this.email = !!login
      ? login.email
      : this.confirmationForm.get('email').value;

    this.signDate = new Date(date.getTime() - userTimezoneOffset).toISOString();

    this.companyVerifying = true;
    this.companyVerificationModal.open();

    const registerAndSignOptions: RegisterAndSignOptionsModel = {
      unitId: this.unitId,
      moveInDate: this.signDate,
      insuranceId: this.currentInsuranceId,
      platform: 'WEB',
      paymentWithInvoice: this.paymentMethod === PaymentMethod.Invoice,
      language: this.translateService.currentLang === 'sv' ? 'sv-SE' : 'en-US',
      isNewUser: !login,
      firstName: !!login ? null : this.confirmationForm.get('firstName').value,
      lastName: !!login ? null : this.confirmationForm.get('lastName').value,
      email: !!login ? login.email : this.confirmationForm.get('email').value,
      password: !!login
        ? login.password
        : this.confirmationForm.get('password').value,
      phoneNumber: !!login
        ? null
        : this.confirmationForm.get('phoneNumber').value.e164Number,
      organizationNumber: !!login
        ? null
        : this.confirmationForm.get('organizationNumber').value,
    };

    this.actionExecuting = true;
    this.bookingService.businessUserSignDate(registerAndSignOptions).subscribe(
      (response) => {
        this.actionExecuting = false;
        this.companyVerifying = false;
        this.companyVerificationModal.close();

        this.confirmationModal.close();
        this.confirmationForm.reset();

        if (this.paymentMethod === PaymentMethod.Invoice) {
          this.bookingId = response.bookingId;
          localStorage.setItem('bookId', this.bookingId.toString());
          // GA4: this.tagManagerService.registerEvent("sign_agreement_finished_b2b");
          this.handleInvoiceResponse(response);
        } else {
          this.signUnitResponse = response;
          this.priceDetails$ = of(response.pricingDetails);

          this.finishSigning(response);
        }
      },
      (error) => {
        this.actionExecuting = false;
        this.companyVerifying = false;
        this.companyVerificationModal.close();

        this.handleErrorMessage(error.error[0]);
      }
    );
  }

  public openRegistrationForm(): void {
    this.shouldCompanyBeRegistered = true;
    this.registerCompany = true;
    this.shouldCompanyBeRegistered = true;
    this.confirmationModal.open();

    this.setBusinessValidators();
  }

  private removeBusinessValidators(): void {
    this.confirmationForm.get('firstName').clearValidators();
    this.confirmationForm.get('firstName').updateValueAndValidity();

    this.confirmationForm.get('lastName').clearValidators();
    this.confirmationForm.get('lastName').updateValueAndValidity();

    this.confirmationForm.get('firstName').clearValidators();
    this.confirmationForm.get('firstName').updateValueAndValidity();

    this.confirmationForm.get('password').clearValidators();
    this.confirmationForm.get('password').updateValueAndValidity();

    this.confirmationForm.get('passwordVerify').clearValidators();
    this.confirmationForm.get('passwordVerify').updateValueAndValidity();

    this.confirmationForm.get('organizationNumber').clearValidators();
    this.confirmationForm.get('organizationNumber').updateValueAndValidity();
  }

  private setBusinessValidators(): void {
    this.confirmationForm.get('firstName').setValidators([Validators.required]);
    this.confirmationForm.get('firstName').updateValueAndValidity();

    this.confirmationForm.get('lastName').setValidators([Validators.required]);
    this.confirmationForm.get('lastName').updateValueAndValidity();

    this.confirmationForm.get('firstName').setValidators([Validators.required]);
    this.confirmationForm.get('firstName').updateValueAndValidity();

    this.confirmationForm.get('password').setValidators([Validators.required]);
    this.confirmationForm.get('password').updateValueAndValidity();

    this.confirmationForm
      .get('passwordVerify')
      .setValidators([Validators.required]);
    this.confirmationForm.get('passwordVerify').updateValueAndValidity();

    this.confirmationForm
      .get('organizationNumber')
      .setValidators([
        Validators.required,
        Validators.pattern(/^([0-9]{6}-[0-9]{4})$|^([0-9]{10})$/),
      ]);
    this.confirmationForm.get('organizationNumber').updateValueAndValidity();
  }

  private async handleInvoiceResponse(response: any) {
    if (!this.authService.isLoggedIn && response.token) {
      this.authService.tokenSideAffects(response.token);
      localStorage.setItem(
        '_expiredTime',
        (Date.now() + SessionService.sessionExpiration).toString()
      );
    }

    if (response.creditCheckFailed === true) {
      this.creditValidCompany = false;
      this.currentStep = 4;
      this.handleErrorMessage('CREDIT_CHECK_FAILED_MESSAGE');
    } else {
      this.currentStep = 2;

      this.creditValidCompany = true;
      this.successPayment('invoice');
    }
    this.modalOpened = false;
    this.verificationModal.close();
    this.companyVerificationModal.close();
  }

  private startPaymentPolling() {
    this.timeInterval = interval(1000)
      .pipe(
        startWith(0),
        filter(
          () =>
            // tslint:disable-next-line:max-line-length
            this.paymentFormStatus !== 'Success' &&
            (!this.pollingPaymentStatus ||
              this.pollingPaymentStatus === 'NOT_INITIALIZED' ||
              this.pollingPaymentStatus === 'IN_PROGRESS')
        ),
        switchMap(() =>
          this.bookingService.getPaymentStatus(this.sixStorageTransactionId)
        ),
        tap((res) => (this.pollingPaymentStatus = res.status))
      )
      .subscribe(
        (res) => {
          this.paymentTransactionResponse = res.paymentTransactionResponse;
          if (res.status === 'SUCCESS' && this.paymentFormStatus !== 'Success') {
            this.successPayment('card');
          } else if (res.status === 'FAILED') {
            this.errorPayment(
              this.translateService.instant('BOOK.PAYMENT_FAIL')
            );
          }
          this.pollingPaymentStatus = res.status;
        },
        (error) => this.errorPayment(error)
      );
  }

  private resetPaymentFormStatus(): void {
    this.paymentFormStatus = null;
    this.pollingPaymentStatus = null;
    this.timeInterval.unsubscribe();
  }

  // Analytic service: Handle user_type
  onUserTypeCheckboxChange(isB2B: boolean): void {
    this.analytics.setUserType(isB2B);
  }

  onPaymentMethodChange(payment_method: 'card' | 'invoice') {
    this.analytics.setPaymentMethod(payment_method);
  }
}
