import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { ObservableService } from '../../../core/services/observable.service';
import { StorageUnitService } from '../../../core/services/storage-unit.service';
import { BookingService } from '../../../core/services/booking.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { StorageUnit } from '../../../core/models/storage-unit';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-user-extracted-units',
  templateUrl: './extracted-units.component.html',
  styleUrls: ['./extracted-units.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExtractedUnitsComponent implements OnInit, OnDestroy {
  @Input() public showAllUnits: boolean;
  @Output() public isLoaded = new EventEmitter<boolean>();
  @Output() public locationAddress = new EventEmitter<string>();
  @Output() public locationCity = new EventEmitter<string>();

  public unitsBySizeGroups: { type: string; typedUnits: StorageUnit[] }[] = [
    { type: 'small', typedUnits: [] },
    { type: 'medium', typedUnits: [] },
    { type: 'large', typedUnits: [] },
    { type: 'external', typedUnits: [] },
  ];

  public allUnits: StorageUnit[] = [];

  public loaded: boolean;

  private locationId: string;
  private buildingId: string;
  private subscription: Subscription;

  public resizeEvent: boolean;

  constructor(
    private observableService: ObservableService,
    private storageUnitService: StorageUnitService,
    private bookingService: BookingService,
    private router: Router,
    private authService: AuthenticationService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {
    this.locationCity.next('Helsingborg');
    this.resizeEvent = window.innerWidth <= 505;
    this.locationAddress.next('Halsovagen 20');
    this.subscription = this.observableService.currentLocation.subscribe(
      (location) => {
        this.locationId = location.locationId;
        this.buildingId = location.buildingId;
        this.locationCity.next(location.city || 'Helsingborg');
        this.locationAddress.next(location.address || 'Halsovagen 20');
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event): void {
    this.resizeEvent = event.target && event.target.innerWidth <= 505;
  }

  public ngOnInit(): void {
    this.authenticateUser();
  }

  public authenticateUser(): void {
    combineLatest([
      this.observableService.locationBuildings$,
      this.observableService.currentLocationName$,
    ])
      .pipe(
        distinctUntilChanged(([prevLocations, prevLocationName], [currentLocations, currentLocationName]) => {
          return prevLocationName === currentLocationName && prevLocations.length === currentLocations.length;
        }),
        map(([location, currentLocationName]: [any, any]) => {
          if (location) {
            this.locationId =
              this.observableService.currentLocationValue.locationId;
            this.buildingId =
              this.observableService.currentLocationValue.buildingId;
            sessionStorage.setItem('buildingId', this.buildingId);
            if (!this.locationId || !this.buildingId) {
              this.storageUnitService.getLocations().subscribe((l) => {
                if (l.locationBuildings.length > 0) {
                  const locationCity =
                    l.locationBuildings[l.locationBuildings.length - 1].city ||
                    'Helsingborg';
                  const locationAdress =
                    l.locationBuildings[l.locationBuildings.length - 1]
                      .address || 'Halsovagen 20';
                  this.locationId =
                    l.locationBuildings[
                      l.locationBuildings.length - 1
                    ].locationId;
                  this.buildingId =
                    l.locationBuildings[
                      l.locationBuildings.length - 1
                    ].buildingId;
                  this.locationCity.next(locationCity);
                  this.locationAddress.next(
                    l.locationBuildings[l.locationBuildings.length - 1]
                      .address || 'Halsovagen 20'
                  );
                  this.observableService.setCurrentLocation(
                    this.locationId,
                    this.buildingId,
                    locationCity,
                    locationAdress
                  );
                  this.storageUnitService
                    .searchUnits(this.locationId, this.buildingId)
                    .subscribe((res) => {
                      this.fillUnitsBySizeGroup(res);

                      this.loaded = true;
                      this.isLoaded.next(true);
                    });
                } else {
                  return this.router.navigate(['hyra-forrad']);
                }
              });
            } else {
              this.loaded = false;
              this.isLoaded.next(false);
              this.storageUnitService
                .searchUnits(this.locationId, this.buildingId)
                .subscribe((res) => {
                  this.fillUnitsBySizeGroup(res);
                  this.isLoaded.next(true);
                  this.loaded = true;
                });
            }
          }
        })
      )
      .subscribe();
  }

  public redirectToBook(unit: StorageUnit): void {
    this.bookingService.getUnitInfo(unit.id).subscribe(
      (res) => {
        const url = this.authService.isLoggedIn
          ? 'user/book/unit-info/' + res.unitId
          : '/book/unit-info/' + res.unitId;
        if (this.showAllUnits) {
          return this.router.navigate([url]);
        } else {
          window.top.location.href = 'https://app.sesamselfstorage.se/' + url;
        }
      },
      (errorResponse) => {
        this.toastrService.error(
          this.translateService.instant('BOOK.OCCUPIED'),
          this.translateService.instant('COMMON.ERROR'),
          { timeOut: 10000 }
        );
      }
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public collapseAllOtherUnits(): void {
    this.allUnits.forEach((u) => (u.expanded = false));
  }

  private fillUnitsBySizeGroup(res: any) {
    this.allUnits = res.storageUnits;

    this.unitsBySizeGroups = [
      { type: 'small', typedUnits: [] },
      { type: 'medium', typedUnits: [] },
      { type: 'large', typedUnits: [] },
      { type: 'external', typedUnits: [] },
    ];
    res.storageUnits.forEach((unit: StorageUnit) => {
      unit.sizeGroup === 'Small'
        ? this.unitsBySizeGroups[0].typedUnits.push(unit)
        : unit.sizeGroup === 'Medium'
        ? this.unitsBySizeGroups[1].typedUnits.push(unit)
        : unit.sizeGroup === 'Large'
        ? this.unitsBySizeGroups[2].typedUnits.push(unit)
        : this.unitsBySizeGroups[3].typedUnits.push(unit);
    });

    if (!this.showAllUnits) {
      this.allUnits = [];
      this.setUnitsForMarketingPage();
    }
  }

  private setUnitsForMarketingPage(): void {
    if (this.unitsBySizeGroups[0].typedUnits.length > 0) {
      this.allUnits.push(this.unitsBySizeGroups[0].typedUnits[0]);
    }
    if (this.unitsBySizeGroups[1].typedUnits.length > 0) {
      this.allUnits.push(this.unitsBySizeGroups[1].typedUnits[0]);
    }
    if (this.unitsBySizeGroups[2].typedUnits.length > 0) {
      this.allUnits.push(this.unitsBySizeGroups[2].typedUnits[0]);
    }
    if (this.unitsBySizeGroups[3].typedUnits.length > 0) {
      this.allUnits.push(this.unitsBySizeGroups[3].typedUnits[0]);
    }
    this.unitsBySizeGroups.splice(0, 3);
  }
}
