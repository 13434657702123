<div class="sidebar" [class.opened]="opened">
  <img
    class="close-sidebar"
    src="assets/img/home/close_black.svg"
    (click)="toggleSidebar.emit(false)"
  />

  <a class="logo" href="https://sesamselfstorage.se/home"
    ><img
      loading="lazy"
      width="90"
      height="60"
      src="assets/img/home/sesam-logo.svg"
      alt="sesam logo"
  /></a>

  <div class="sidebar-menu">
    <div
      *ngIf="locations.length > 0 && !bookingProcess"
      class="menu"
      (click)="emitSelectBuilding(eLocations.HELSINGBORG)"
    >
      <p [class.sidebar-menu_active]="selectedPage === eLocations.HELSINGBORG">
        {{ "HEADER.TEXT_HEADER1" | translate }}
      </p>
    </div>
    <div class="menu" (click)="emitSelectBuilding(eLocations.LUND)">
      <p [class.sidebar-menu_active]="selectedPage === eLocations.LUND">{{ "HEADER.TEXT_HEADER_LUND" | translate }}</p>
    </div>
    <div class="menu" (click)="navigateTo('how-it-works')">
      <p>{{ "HEADER.HOME" | translate }}</p>
    </div>
    <div class="menu" (click)="navigateTo('companies')">
      <p>{{ "HEADER.COMPANIES" | translate }}</p>
    </div>
    <div class="menu" (click)="navigateTo('about')">
      <p>{{ "FOOTER.ABOUT_US" | translate }}</p>
    </div>
    <div class="menu" (click)="navigateTo('tips')">
      <p>{{ "HEADER.TIPS" | translate }}</p>
    </div>
    <div class="menu" (click)="navigateTo('contact')">
      <p>{{ "HEADER.CONTACT" | translate }}</p>
    </div>
    <div class="menu" (click)="navigateTo('download')">
      <p>{{ "HEADER.DOWNLOAD" | translate }}</p>
    </div>
    <div *ngIf="!loggedIn" class="menu" (click)="login.emit()">
      <p>{{ "FOOTER.MY_SESAM" | translate }}</p>
    </div>
    <div *ngIf="loggedIn" class="menu" (click)="navigateTo('account')">
      <p [class.sidebar-menu_active]="selectedPage === 6">
        {{ "HEADER.ACCOUNT" | translate }}
      </p>
    </div>
    <div *ngIf="loggedIn" class="menu" (click)="navigateTo('invoices')">
      <p [class.sidebar-menu_active]="selectedPage === 7">
        {{ "HEADER.INVOICES" | translate }}
      </p>
    </div>
    <div *ngIf="loggedIn" class="menu" (click)="signOut.emit()">
      <p>{{ "HEADER.LOGOUT" | translate }}</p>
    </div>
  </div>
</div>
