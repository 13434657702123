import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigService} from './config.service';
import {RestService} from './rest.service';
import {Observable} from 'rxjs';

@Injectable()
export class StorageUnitService extends RestService {
  basePath = 'storage-unit';
  apiPath = '';
  version = '';

  constructor(http: HttpClient,
              configService: ConfigService) {
    super(http, configService);
  }

  getStorageTypes(): Observable<any> {
    return this.http.get('storage-types');
  }
  getBuildings(): Observable<any> {
    return this.http.get('buildings');
  }
  getUnitById(unitId: string): Observable<any> {
    return this.http.get(unitId);
  }
  getLocations(): Observable<any> {
    return this.http.get('location-buildings');
  }
  searchUnits(locationId, buildingId): Observable<any> {
    const params = new HttpParams().set('locationId', locationId).set('buildingId', buildingId);
    return this.http.get('units', {params});
  }
}
